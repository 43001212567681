import { useState, useEffect, useCallback, useMemo } from "react";
import { listSummaryVFS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month', 'quarter', 'year'];
const Summary = ({ loading, onRefresh, data, fetchData }) => {
  const [isLoading, setLoading] = useState(loading); 
  const [groupByType, setGroupByType] = useState('week');
  
  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };


  useEffect(() => {
    console.log("Dados brutos:", data);
  }, [data]);


  function getWeekNumber(date) {
    // Copy date so don't modify original
    date = new Date(date);
    
    // Set to nearest Saturday: current date - current day number + 6
    date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 6);
    
    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    
    // Calculate full weeks to nearest Saturday
    var weekNo = Math.ceil(((date - yearStart) / 86400000) / 7);
    
    // Return array of year and week number
    return [date.getUTCFullYear(), weekNo];
}

const getMonthNumber = (date) => {
  // Copy date so don't modify original
  date = new Date(date);

  // Get the year
  const year = date.getUTCFullYear();

  // Get month number (0-11), then add 1 to get (1-12)
  const monthNo = date.getUTCMonth() + 1;

  // Return array of year and month number
  return [year, monthNo];
};

const getQuarterNumber = (date) => {
  // Copy date so don't modify original
  date = new Date(date);

  // Get the year
  const year = date.getUTCFullYear();

  // Get month number (0-11), then calculate quarter (1-4)
  const month = date.getUTCMonth();
  const quarterNo = Math.floor(month / 3) + 1;

  // Return array of year and quarter number
  return [quarterNo];
};


const groupData = useMemo(() => {
  // Ordena os dados por 'ship_day' antes de agrupá-los
  const sortedData = [...data].sort((a, b) => new Date(b.ship_day) - new Date(a.ship_day));

  return groupBy(sortedData, (item) => {
    const groupKey = new Date(item.ship_day);
    switch (groupByType) {
      case 'week':
        //const weekNumber = getWeekNumber(id);
        const weekNumber = getWeekNumber(groupKey).toString().padStart(2, '0');
        return weekNumber;
      case 'month':
        const monthNumber = getMonthNumber(groupKey).toString().padStart(2, '0');
      return monthNumber;
      case 'quarter':
        const quarterNumber = getQuarterNumber(groupKey).toString().padStart(2, '0');
      return quarterNumber;
      case 'year':
        return `(${groupKey.getFullYear()})`;
      default:
        return groupKey.toISOString().split('T')[0];
    }      
  });
}, [data, groupByType]);

const aggregatedData = useMemo(() => {
  const aggregated = [];

  for (const key in groupData) {
    if (groupData.hasOwnProperty(key)) {
      const items = groupData[key];
      
      // Agrupando por warehouse_id dentro de cada groupKey
      const warehouseGroups = groupBy(items, item => item.warehouse_id);
      
      for (const warehouseId in warehouseGroups) {
        const warehouseItems = warehouseGroups[warehouseId];
        const aggregatedItem = { 
          groupKey: key,
          warehouse_id: warehouseId
        };

        for (const item of warehouseItems) {
          for (const column in item) {
            if (column !== 'id' && column !== 'ship_day' && column !== 'warehouse_id' &&
                (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column] || 0);
            }
          }
        }

        // Calculando campos adicionais
        const calculatePercentage = (numerator, denominator) => 
          numerator && denominator ? (numerator / denominator * 100).toFixed(1) + '%' : '0%';

        const calculateRatio = (numerator, denominator) =>
          numerator && denominator ? (numerator / denominator).toFixed(2) : '0';

        aggregatedItem.miss = calculatePercentage(aggregatedItem.ce_miss, aggregatedItem.total_units);
        aggregatedItem.cancel = calculatePercentage(aggregatedItem.cancel_qtd, aggregatedItem.total_units);
        aggregatedItem.xcv9 = calculatePercentage(aggregatedItem.total_units, aggregatedItem.units_xcv9);
        aggregatedItem.net = calculatePercentage(aggregatedItem.total_units, aggregatedItem.units_net);
        aggregatedItem.ups = calculateRatio(aggregatedItem.total_units, aggregatedItem.total_shipments);
        aggregatedItem.fr = calculatePercentage(aggregatedItem.po_qty_received, aggregatedItem.po_qty_submitted);
        aggregatedItem.returns = calculatePercentage(aggregatedItem.return_qtd, aggregatedItem.total_units);
        
        aggregatedItem.vlt_2 = aggregatedItem.avg_vlt_sum && aggregatedItem.received_units 
          ? (aggregatedItem.avg_vlt_sum / aggregatedItem.received_units).toFixed(2)
          : (aggregatedItem.vlt || '0');

        if (aggregatedItem.gas_tank_vol && aggregatedItem.total_units) {
          const gasVolValues = Array.isArray(aggregatedItem.gas_tank_vol) 
            ? aggregatedItem.gas_tank_vol.filter(vol => vol !== null) 
            : [aggregatedItem.gas_tank_vol];
          const gasVolCount = gasVolValues.length;
          const avgGasVol = gasVolCount > 0 
            ? gasVolValues.reduce((total, volume) => total + volume, 0) / gasVolCount 
            : 0;
          aggregatedItem.gasTankVolValue = ((avgGasVol / 199) * 100).toFixed(2) + '%';
        } else {
          aggregatedItem.gasTankVolValue = '0%';
        }

        // Definindo o id como uma combinação de groupKey e warehouse_id
        aggregatedItem.id = `${key}_${warehouseId}`;

        aggregated.push(aggregatedItem);
      }
    }
  }

  return aggregated;
}, [groupData]);
  

  const inbound = [
    { field: 'groupKey', headerName: 'Date', flex: 2 },
    { field: 'warehouse_id', headerName: 'Date', flex: 2 },
    { field: 'received_units', headerName: 'Received Units', flex: 2 },
    { field: 'po_qty_open', headerName: 'Open PO', flex: 2 },
    //{ field: 'po_qty_received', headerName: 'PO Qtd Received ', flex: 2 },
    //{ field: 'po_qty_submitted', headerName: 'Submitted PO', flex: 2 },
    { field: 'fr', headerName: 'Fill Rate', flex: 2 },
    { field: 'vlt_2', headerName: 'VLT', flex: 2 },
    //{ field: 'turns', headerName: 'Turns VF', flex: 2 },
  ]

  const outbound = [
    { field: 'groupKey', headerName: 'Date', flex: 2 },
    { field: 'warehouse_id', headerName: 'Date', flex: 2 },
              { field: 'total_units', headerName: 'Total Units', flex: 2 },
              { field: 'ups', headerName: 'UPS', flex: 2 },
              { field: 'net', headerName: 'Share Network', flex: 2 },
              { field: 'xcv9', headerName: 'Share XCV9', flex: 2 },
              { field: 'cancel_qtd', headerName: 'Cancellation Units', flex: 2 },
              { field: 'cancel', headerName: 'Cancellation Rate', flex: 2 },
              { field: 'ce_miss', headerName: 'CE Miss', flex: 2 },
              { field: 'miss', headerName: 'CE Miss %', flex: 2 },


  ]

  const inventory = [
    { field: 'groupKey', headerName: 'Date', flex: 2 },
    { field: 'warehouse_id', headerName: 'Date', flex: 2 },
              { field: 'gasTankVolValue', headerName: 'Gas Tank', flex: 2 },
              { field: 'return_qtd', headerName: 'Customer Returns', flex: 2 },
              { field: 'returns', headerName: 'Return Rate', flex: 2 },


  ]
  //aggregatedData

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);  

  const aggregatedDataSorted = useMemo(() => {
    // Copie os dados agregados para evitar mutações
    const aggregatedCopy = [...aggregatedData];
  
    // Ordena os dados pelo campo 'id' em ordem decrescente
    aggregatedCopy.sort((a, b) => new Date(b.id) - new Date(a.id));
  
    return aggregatedCopy;
  }, [aggregatedData]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  const handleRefresh = async () => {
    try {
      setLoading(true);
      await fetchData(); // Call fetchData function passed from props
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  return (
      <div>
        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center' }}>
          {groupTypes.map((type) => (
            <Button key={type} variant={groupByType === type ? 'contained' : 'outlined'} onClick={() => handleGroupByChange(type)}>
              {type}
            </Button>
          ))}
        </div>
        <Grid container spacing={12} direction="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Outbound
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
            </Typography>
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '30vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={aggregatedData || []}
              columns={outbound}
              disableColumnSelector
              disableDensitySelector
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Inbound
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(7px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
            </Typography>
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={aggregatedData || []}
              columns={inbound}
              disableColumnSelector
              disableDensitySelector
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Inventory
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(7px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
            </Typography>
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={aggregatedData || []}
              columns={inventory}
              disableColumnSelector
              disableDensitySelector
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
    };
    
    export default withAuthenticator(Summary);