import { useState, useEffect, useMemo } from "react";
import { listSummaryDropOffs} from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { Typography, Button, Grid, Box } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';
import { listMissesEasyShips,listSellerDataES } from "../../../graphql/queries";
import CircularProgress from '@mui/material/CircularProgress';



Amplify.configure(awsconfig);

const groupTypes = ['day','week'];

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [defectData, setDefectsData] = useState([]); // Estado para armazenar dados de defects
  const [datar, setDatar] = useState([]);

  function getCurrentWeekNumber1() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 20);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = oneWeekAgo - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  const currentWeekNumber1 = getCurrentWeekNumber1();

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();

  const fetchDataReasons = async () => {
    try {
      setLoading(true);
      let defectItems = [];
      let sellerItems = [];
      let nextToken = null;
      const startDate = new Date('2024-09-15');
  
      do {
        const defectResponse = await API.graphql(
          graphqlOperation(listMissesEasyShips, {
            limit: 20000,
            nextToken,
            filter: {
              and: [
                {
                  week: {
                    gt: currentWeekNumber - 2,
                  },
                },
                {
                  date: {
                    gt: startDate.toISOString().slice(0, 10),
                  },
                },
              ],
            },
          })
        );
        const fetchedDefectItems = defectResponse.data.listMissesEasyShips.items;
  
        defectItems = defectItems.concat(fetchedDefectItems);
        nextToken = defectResponse.data.listMissesEasyShips.nextToken;
      } while (nextToken);
  
      nextToken = null;
      do {
        const sellerResponse = await API.graphql(graphqlOperation(listSellerDataES, {
          limit: 200000,
          nextToken: nextToken
        }));
  
        const fetchedSellerItems = sellerResponse.data.listSellerDataES.items;
        sellerItems = sellerItems.concat(fetchedSellerItems);
  
        nextToken = sellerResponse.data.listSellerDataES.nextToken;
      } while (nextToken);
  
      const mergedData = defectItems.map(defect => {
        const seller = sellerItems.find(seller => defect.mid === seller.id);
        return {
          ...defect,
          ship_method: seller ? seller.ship_method : null
        };
      });
      const filteredData = mergedData.filter(item => item.ship_method === 'Dropoff');
  
      setDatar(filteredData);
      console.log("reasons filtradas",filteredData)
      console.log("reasons merged",mergedData)
      setLoading(false);
      return filteredData; // Ensure to return the merged data
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      return []; // Return an empty array in case of error
    }
  };
  const filterData = (data, missTypeFilter) => {
    return data.filter(item => 
      item.miss_type === missTypeFilter
    );
  };
  useEffect(() => {
    try {
      fetchDataReasons();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);

  const aggregatedDataByWeekAndReason = useMemo(() => {
    const aggregated = {};
    const totalByWeekAndMissType = {};
  
    // Aggregate units by week_miss_type_reason
    for (const item of datar) {
      const week = item.week || 'Unknown';
      const miss_type = item.miss_type || 'NULL';
      const reason = item.reason || 'Unknown';
      const units = Number(item.orders) || 0;
  
      const id = `${week}_${miss_type}_${reason}`;
  
      if (!aggregated[id]) {
        aggregated[id] = {
          id,
          week,
          reason,
          miss_type,
          units: 0,
        };
      }
  
      aggregated[id].units += units;
  
      // Calculate total by week and miss_type
      if (!totalByWeekAndMissType[week]) {
        totalByWeekAndMissType[week] = {};
      }
      if (!totalByWeekAndMissType[week][miss_type]) {
        totalByWeekAndMissType[week][miss_type] = 0;
      }
      totalByWeekAndMissType[week][miss_type] += units;
    }
  
    // Convert the object to an array and calculate percentage
    const result = Object.values(aggregated).map(item => {
      const totalForWeekAndMissType = totalByWeekAndMissType[item.week][item.miss_type];
      return {
        ...item,
        units_sum: totalForWeekAndMissType,
        percentage: totalForWeekAndMissType > 0 
          ? (item.units / totalForWeekAndMissType) * 100 
          : 0
      };
    });
  
    // Sort by units in descending order
    result.sort((a, b) => b.units - a.units);
  
    // Add the order column
    result.forEach((item, index) => {
      item.order = index + 1;
    });
    console.log('Dados de entrada:', datar);
  console.log('Dados agregados:', result);

  return result;
}, [datar]);

const [filteredDataLH, setFilteredDataLH] = useState([]);
const [filteredDataMXSD, setFilteredDataMXSD] = useState([]);
const [filteredDataCancellation, setFilteredDataCancellation] = useState([]);

useEffect(() => {
  setFilteredDataLH(filterData(aggregatedDataByWeekAndReason, 'LH_BY_SELLER'));
  setFilteredDataMXSD(filterData(aggregatedDataByWeekAndReason, 'MXSD'));
  setFilteredDataCancellation(filterData(aggregatedDataByWeekAndReason, 'CANCELLATION'));
}, [aggregatedDataByWeekAndReason]);



  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      
      // Calculate the date 30 days ago
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listSummaryDropOffs, {
            limit: 20000,
            nextToken,

          })
        );
        const fetchedItems = response.data.listSummaryDropOffs.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSummaryDropOffs.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  

  useEffect(() => {
    try {
      fetchData();
      console.log("teste",data)
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);




  

  


  
  const groupData = useMemo(() => {
    // Ordena os dados por 'expected_ship_date' antes de agrupá-los
    const sortedData = [...data].sort((b, a) => new Date(b.miss_week) - new Date(a.miss_week));

    return groupBy(sortedData,(item) => {
      const date_value = new Date(item.miss_week);
          const weekNumber = date_value;
          return item.miss_week;
        
    });
  }, [data]);

  const aggregatedData = useMemo(() => {
    const uniqueData = {};
    let id = 0;
  
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        uniqueData[key] = {
          id: id++,
          miss_week: key,
          order_count_method: 0,
          general_lsr: 0,
          mexsd: 0,
          late_drop: 0,
          mexsd_re: 0,
          late_drop_re: 0,
          lsr_re_f: 0, // Add this new field
          cr: 0,
          cr_not_scheduled:0,
          order_count_evaluation: 0,



          general_lsr_perc: 0,
          mexsd_perc: 0,
          late_drop_perc: 0,
          mexsd_re_perc: 0,
          late_drop_re_perc: 0,
          lsr_re_f_perc: 0, // Add this new field
          cr_perc: 0,
          cr_n_scheduled_perc: 0,

        };
  
        for (const item of items) {
          uniqueData[key].order_count_method += item.order_count_method;
          uniqueData[key].order_count_evaluation += item.order_count_evaluation;
          uniqueData[key].general_lsr += item.general_lsr_f || 0;
          uniqueData[key].mexsd += item.mexsd_f || 0;
          uniqueData[key].late_drop += item.late_drop || 0;
          uniqueData[key].mexsd_re += item.mexsd_re_f || 0;
          uniqueData[key].late_drop_re += item.late_drop_re || 0;
          uniqueData[key].cr += item.cr || 0;
          uniqueData[key].lsr_re_f += item.lsr_re_f || 0;
          uniqueData[key].cr_not_scheduled += item.cr_not_scheduled || 0;

  
          // Check if the amazon_comments is "Incorrect Address" and add the value to the incorrect_address_sum
          
           
        }

        
  
        if (uniqueData[key].order_count_method && uniqueData[key].general_lsr) {
          uniqueData[key].general_lsr_perc = ((uniqueData[key].general_lsr) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].mexsd) {
          uniqueData[key].mexsd_perc = ((uniqueData[key].mexsd) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].late_drop) {
          uniqueData[key].late_drop_perc = ((uniqueData[key].late_drop) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].mexsd_re) {
          uniqueData[key].mexsd_re_perc = ((uniqueData[key].mexsd_re) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].late_drop_re) {
          uniqueData[key].late_drop_re_perc = ((uniqueData[key].late_drop_re) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_method && uniqueData[key].lsr_re_f) {
          uniqueData[key].lsr_re_f_perc = ((uniqueData[key].lsr_re_f) / uniqueData[key].order_count_method);
        }

        if (uniqueData[key].order_count_evaluation && uniqueData[key].cr) {
          uniqueData[key].cr_perc = ((uniqueData[key].cr) / uniqueData[key].order_count_evaluation);
        }

        if (uniqueData[key].order_count_evaluation && uniqueData[key].cr_not_scheduled) {
          uniqueData[key].cr_n_scheduled_perc = ((uniqueData[key].cr_not_scheduled) / uniqueData[key].order_count_evaluation);
        }
      }
    }
  
    return Object.values(uniqueData);
  }, [data]);

  console.log('aggregatedData:', aggregatedData);

  const sortedData1 = useMemo(() => {
    const sortedItems = [...aggregatedData].sort((a, b) => {
      const weekComparison = b.miss_week - a.miss_week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [aggregatedData]);




  const MissColumns = Array.from(new Set([
    { field: 'miss_week', headerName: 'Week', width:50,headerClassName: 'customHeader',},
    { field: 'order_count_method', type: 'number' , headerName: 'Order Count', width:100, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',},
    { field: 'general_lsr', type: 'number' , headerName: 'LDR', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'general_lsr_perc', type: 'number' , headerName: 'LDR %', width:100, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 9.25;
        
        return (
          <div
  

          >
            {formattedValue}
          </div>
        );
      },
    },
    { field: 'lsr_re_f', type: 'number' , headerName: 'LDR RE', width:100, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',},
    { field: 'lsr_re_f_perc', type: 'number' , headerName: 'LDR RE %', width:100, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 10.55;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 10.55%     ST: 4.45%
            </div>
          </div>
        );
      },
    },
    { field: 'mexsd', type: 'number' , headerName: 'MEXSD', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'mexsd_perc', type: 'number' , headerName: 'MEXSD %', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 9.3;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 9.30%
            </div>
          </div>
        );
      },
    },  
    { field: 'mexsd_re', type: 'number' , headerName: 'MEXSD RE', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'mexsd_re_perc', type: 'number' , headerName: 'MEXSD RE %', width:120, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.15;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 3.15%     ST: 1.45%
            </div>
          </div>
        );
      },
    },  
    { field: 'late_drop', type: 'number' , headerName: 'LH', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'late_drop_perc', type: 'number' , headerName: 'LH %', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 93;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
           
          </div>
        );
      },
    },  
    
    { field: 'late_drop_re', type: 'number' , headerName: 'LH RE', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'late_drop_re_perc', type: 'number' , headerName: 'LH RE %', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 7.40;
       
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
         >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 7.40%     ST: 3.00%
            </div>
          </div>
        );
      },
    },  
    { field: 'cr', type: 'number' , headerName: 'CR', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'cr_perc', type: 'number' , headerName: 'CR %', width:100, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.35;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 3.35%     ST: 3.20%
            </div>
          </div>
        );
      },
    },  
    { field: 'cr_not_scheduled', type: 'number' , headerName: 'CR Not Scheduled', width:150, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',},
    { field: 'cr_n_scheduled_perc', type: 'number' , headerName: 'CR Not Scheduled %', width:150, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 3.35;
        
        return (
          <div
            
            
          >
            {formattedValue}
          </div>
        );
      },
    },
    




    
  ]));




  
  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '400px'
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );
  const reason = [
    { field: 'week', headerName: 'Week',width: 50,headerClassName: 'customHeader', },
    { field: 'reason', type: 'number', headerName: 'Reason', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'units', type: 'number', headerName: 'Units', align: 'center', headerAlign: 'center', width: 100,headerClassName: 'customHeader', },
    { field: 'percentage', type: 'number', headerName: '%', align: 'center', headerAlign: 'center', width: 70,headerClassName: 'customHeader',
      valueFormatter: (params) => {
        const value = params.value; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      },
  
    }
  ];

  return (
    <div className="datatable" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column',  }}>

  {/* Carrier Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      General Performance
    </Typography>
    <DataGrid
      sx={{
          marginTop: '2vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40vh',
          maxHeight: '90vh',
          overflow: 'auto', 
        }}
        rows={sortedData1}
        columns={MissColumns}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          filter: {
            filterModel: {
              items: [
                { field: 'miss_week', operator: 'equals', value: currentWeekNumber.toString() }
  
              ],
  
            },
          },

      }}   
    />

  </Box>



<Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: '2vh' }}>
  {/* Carrier Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      MXSD
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataMXSD}
      columns={reason}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>

  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      SCR
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataCancellation}
      columns={reason}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>
  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Seller Miss - FDPS
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataLH}
      columns={reason}
      disableColumnSelector
      disableDensitySelector



      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [
              { field: 'week', operator: 'equals', value: currentWeekNumber.toString() }

            ],

          },
        },

      }}
      
    />
  </Box>
</Box>
      

      
    </div>
    );
};


export default withAuthenticator(Summary);