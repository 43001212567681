import { useState, useEffect, useMemo } from "react";
import { listSummaryES } from "../../../graphql/queries";
import { listMissesEasyShips } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { Typography, Grid,  Box } from '@mui/material';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import tableColumns2 from './blank_reason_columns';
import tableColumns3 from './summary_columns_owner';
import tableColumns4 from './summary_columns_reasons';
import tableColumnsTotal from './summary_general';
import { withAuthenticator } from '@aws-amplify/ui-react';
import CircularProgress from '@mui/material/CircularProgress';

Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {

  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [dataTotal, setDataTotal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datar, setDatar] = useState([]);
  
  function getOneMonthAgo() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = oneWeekAgo - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  const OneMonthAgo = getOneMonthAgo();

  function getCurrentWeekNumber1() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 20);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = oneWeekAgo - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  const currentWeekNumber1 = getCurrentWeekNumber1();

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();
  const currentYear = new Date().getFullYear(); 
  console.log(currentWeekNumber)
  async function fetchDataTotal() {
    try {
      let nextToken = null;
      const items = [];
      setLoading(true);
      const currentYear = new Date().getFullYear();
      do {
        const response = await API.graphql(graphqlOperation(listSummaryES, {
          filter: {
            and: [
              { week: { ge: currentWeekNumber -4 } }, 
            ],
          },
          limit: 1000,
          nextToken: nextToken // Set the nextToken parameter
        }));
  
        const fetchedItems = response.data.listSummaryES.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listSummaryES.nextToken;
      } while (nextToken);
  
      // Get unique values for the "week" column
      const uniqueWeeks = Array.from(new Set(items.map(item => item.week)));
  
      // Create a table with unique weeks
      const table = [];
      uniqueWeeks.forEach((week, index) => {
        const row = {
          id: `${week}`, // Generate unique id using week only
          week: week,
        };
  
        const missTypes = ['general_lsr', 'lsr_re_f', 'mexsd_re_f', 'cr', 'late_handover'];
        missTypes.forEach((missType) => {
          const units = items
            .filter(item => item.week === week)
            .reduce((sum, item) => sum + item[missType], 0);
  
          const totalUnits = items
            .filter(item => item.week === week)
            .reduce((sum, item) => sum + item.orders, 0);
  
          const percent = totalUnits > 0 ? (units / totalUnits) : 0;
  
          row[`${missType}_units`] = units;
          row[`${missType}_percent`] = percent;
          row[`orders`] = totalUnits;
  
          if (index > 0) {
            const prevWeek = uniqueWeeks[index - 1];
            const prevUnits = items
              .filter(item => item.week === prevWeek)
              .reduce((sum, item) => sum + item[missType], 0);
  
            const prevTotalUnits = items
              .filter(item => item.week === prevWeek)
              .reduce((sum, item) => sum + item.orders, 0);
  
            const prevPercent = prevTotalUnits > 0 ? (prevUnits / prevTotalUnits) : 0;
            const weekOverWeekPercent = ((percent - prevPercent) / prevPercent);
  
            row[`${missType}_wow_percent`] = weekOverWeekPercent;
          }
  
          // Adding dropoff_issur_re column
          if (missType === 'late_handover') {
            const dropoffIssurRe = items
              .filter(item => item.week === week && item.evaluation === 'Dropoff')
              .reduce((sum, item) => sum + item[missType], 0);
            row['dropoff_issur_re'] = dropoffIssurRe;
            
            const dropoffIssurRePercent = totalUnits > 0 ? (dropoffIssurRe / totalUnits) : 0;
            row['dropoff_issur_re_percent'] = dropoffIssurRePercent;
          }
          if (missType === 'late_handover') {
            const dropoffIssurRe = items
              .filter(item => item.week === week && item.evaluation !== 'Dropoff')
              .reduce((sum, item) => sum + item[missType], 0);
            row['pickup_issue_re'] = dropoffIssurRe;
            
            const dropoffIssurRePercent = totalUnits > 0 ? (dropoffIssurRe / totalUnits) : 0;
            row['pickup_issue_re_percent'] = dropoffIssurRePercent;
          }
        });
  
        table.push(row);
      });
  
      setLoading(false);
      setDataTotal(table);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



  
  
  async function fetchData() {
    try {
      let nextToken = null;
      const items = [];
  
      do {
        const response = await API.graphql(graphqlOperation(listSummaryES, {
          filter: {
            week: {
              ge: currentWeekNumber1
            }
          },
          limit: 10000,
          nextToken: nextToken // Set the nextToken parameter
        }));
  
        const fetchedItems = response.data.listSummaryES.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listSummaryES.nextToken;
      } while (nextToken);
  
      // Get unique values for the "week" column
      const uniqueWeeks = Array.from(new Set(items.map(item => item.week)));
  
      // Create a table with unique weeks and unique owners
      const table = [];
      uniqueWeeks.forEach((week) => {
        const uniqueClassification = Array.from(new Set(items.filter(item => item.week === week).map(item => item.classification)));
  
        uniqueClassification.forEach((classification, index) => {
          const row = {
            id: `${week}_${classification}_${index}`, // Generate unique id
            week: week,
            classification: classification,
          };
  
          const missTypes = ['general_lsr', 'lsr_re_f','mexsd_re_f', 'cr', 'late_handover']; 
          missTypes.forEach(missType => {
            const units = items
              .filter(item => item.week === week && item.classification === classification)
              .reduce((sum, item) => sum + item[missType], 0);
  
            const totalUnits = items
              .filter(item => item.week === week && item.classification === classification)
              .reduce((sum, item) => sum + item.orders, 0);

            const generalUnits = items
            .filter(item => item.week === week)
            .reduce((sum, item) => sum + item.orders, 0);  
  
            const percent = totalUnits > 0 ? ((units / totalUnits)) : 0;
            const sharPpercent = generalUnits > 0 ? ((totalUnits / generalUnits)) : 0;

            row[`${missType}_units`] = units;
            row[`${missType}_percent`] = percent;
            row[`orders`] = totalUnits;
            row[`shareOrders`] = sharPpercent;
          });
  
          table.push(row);
        });
      });
  
      setData(table);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function fetchData3() {
    try {
      let nextToken = null;
      const items = [];
  
      do {
        const response = await API.graphql(graphqlOperation(listSummaryES, {
          filter: {
            week: {
              ge: currentWeekNumber1
            }
          },
          limit: 10000,
          nextToken: nextToken // Set the nextToken parameter
        }));
  
        const fetchedItems = response.data.listSummaryES.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listSummaryES.nextToken;
      } while (nextToken);
  
      // Get unique values for the "week" column
      const uniqueWeeks = Array.from(new Set(items.map(item => item.week)));
  
      // Create a table with unique weeks and unique owners
      const table = [];
      uniqueWeeks.forEach((week) => {
        const uniqueOwner = Array.from(new Set(items.filter(item => item.week === week).map(item => item.owner)));
  
        uniqueOwner.forEach((owner, index) => {
          const row = {
            id: `${week}_${owner}_${index}`, // Generate unique id
            week: week,
            owner: owner,
          };
  
          const missTypes = ['general_lsr', 'lsr_re_f','mexsd_re_f', 'cr', 'late_handover']; 
          missTypes.forEach(missType => {
            const units = items
              .filter(item => item.week === week && item.owner === owner)
              .reduce((sum, item) => sum + item[missType], 0);
  
            const totalUnits = items
              .filter(item => item.week === week && item.owner === owner)
              .reduce((sum, item) => sum + item.orders, 0);
  
            const percent = totalUnits > 0 ? ((units / totalUnits)) : 0;
  
            row[`${missType}_units`] = units;
            row[`${missType}_percent`] = percent;
            row[`orders`] = totalUnits;
          });
  
          table.push(row);
        });
      });
  
      setData3(table);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const sortedDataTotal = useMemo(() => {
    const sortedItems = [...dataTotal].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
      
      // If the weeks are the same, sort by the "order" column
      return b.orders - a.orders;
    });
  
    // Continue with any additional sorting criteria if needed
  
    return sortedItems;
  }, [dataTotal]);
  const sortedData1 = useMemo(() => {
    const sortedItems = [...data].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
      
      // If the weeks are the same, sort by the "order" column
      return b.orders - a.orders;
    });
  
    // Continue with any additional sorting criteria if needed
  
    return sortedItems;
  }, [data]);
  const sortedData3 = useMemo(() => {
    const sortedItems = [...data3].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
      
      // If the weeks are the same, sort by the "order" column
      return b.orders - a.orders;
    });
  
    // Continue with any additional sorting criteria if needed
  
    return sortedItems;
  }, [data3]);
  
  useEffect(() => {

      try {
        fetchData();
        fetchData3();
        fetchDataTotal();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);



  const fetchDataReasons = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
 
      do {
        const response = await API.graphql(
          graphqlOperation(listMissesEasyShips, {
            limit: 20000,
            nextToken,
            filter: {
              and: [
                {
                  week: {
                    gt: currentWeekNumber - 2,
                  },
                },
                {
                  date: {
                    gt: startDate.toISOString().slice(0, 10),
                  },
                },
              ],
            },
          })
        );
        const fetchedItems = response.data.listMissesEasyShips.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listMissesEasyShips.nextToken;
      } while (nextToken);
  
      setDatar(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filterData = (data, missTypeFilter) => {
    return data.filter(item => 
      item.miss_type === missTypeFilter
    );
  };

  const aggregatedDataByWeekAndReason = useMemo(() => {
    const aggregated = {};
    const totalByWeekAndMissType = {};
  
    // Aggregate units by week_miss_type_reason
    for (const item of datar) {
      const week = item.week || 'Unknown';
      const miss_type = item.miss_type || 'NULL';
      const reason = item.reason || 'Unknown';
      const units = Number(item.orders) || 0;
  
      const id = `${week}_${miss_type}_${reason}`;
  
      if (!aggregated[id]) {
        aggregated[id] = {
          id,
          week,
          reason,
          miss_type,
          units: 0,
        };
      }
  
      aggregated[id].units += units;
  
      // Calculate total by week and miss_type
      if (!totalByWeekAndMissType[week]) {
        totalByWeekAndMissType[week] = {};
      }
      if (!totalByWeekAndMissType[week][miss_type]) {
        totalByWeekAndMissType[week][miss_type] = 0;
      }
      totalByWeekAndMissType[week][miss_type] += units;
    }
  
    // Convert the object to an array and calculate percentage
    const result = Object.values(aggregated).map(item => {
      const totalForWeekAndMissType = totalByWeekAndMissType[item.week][item.miss_type];
      return {
        ...item,
        units_sum: totalForWeekAndMissType,
        percentage: totalForWeekAndMissType > 0 
          ? (item.units / totalForWeekAndMissType) * 100 
          : 0
      };
    });
  
    // Sort by units in descending order
    result.sort((a, b) => b.units - a.units);
  
    // Add the order column
    result.forEach((item, index) => {
      item.order = index + 1;
    });
    console.log('Dados de entrada:', datar);
  console.log('Dados agregados:', result);

  return result;
}, [datar]);

useEffect(() => {
  try {
    fetchDataReasons();
  } catch (error) {
    console.error('Error fetching user group information:', error);
  }
}, []);

const [filteredDataLH, setFilteredDataLH] = useState([]);
const [filteredDataMXSD, setFilteredDataMXSD] = useState([]);
const [filteredDataCancellation, setFilteredDataCancellation] = useState([]);

useEffect(() => {
  setFilteredDataLH(filterData(aggregatedDataByWeekAndReason, 'LH_BY_SELLER'));
  setFilteredDataMXSD(filterData(aggregatedDataByWeekAndReason, 'MXSD'));
  setFilteredDataCancellation(filterData(aggregatedDataByWeekAndReason, 'CANCELLATION'));
}, [aggregatedDataByWeekAndReason]);




  

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  return (
    <div className="datatable" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column',  }}>

  {/* Carrier Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      General Performance
    </Typography>
    <DataGrid
      sx={{
          marginTop: '2vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40vh',
          maxHeight: '90vh',
          overflow: 'auto', 
        }}
        rows={sortedDataTotal}
        columns={tableColumnsTotal}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
            },
          },
      }}   
    />

  </Box>



<Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: '2vh' }}>
  {/* Carrier Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      MXSD
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataMXSD}
      columns={tableColumns4}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>

  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      SCR
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataCancellation}
      columns={tableColumns4}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>
  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Seller Miss - FDPS
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataLH}
      columns={tableColumns4}
      disableColumnSelector
      disableDensitySelector



      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [
              { field: 'week', operator: 'equals', value: currentWeekNumber.toString() }

            ],

          },
        },

      }}
     
    />
  </Box>
</Box>
<Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Classification
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >          
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
      <DataGrid
        sx={{
          marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
        }}
        rows={sortedData1}
        columns={tableColumns}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
            },
          },
      }}   
      />
      )}

      <Grid container spacing={2} direction="row">
        <Grid item xs={6} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Owner
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
            
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40%',
          maxHeight: '40%',
        }}
        rows={sortedData3}
        columns={tableColumns3}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector 
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
            },
          },
      }}   
      />
      )}
    </div>
    );
};


export default withAuthenticator(Summary);