import { useState, useEffect} from "react";
import { listSEStrackSRS} from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';

import awsconfig from '../../../aws-exports';
import { withAuthenticator} from '@aws-amplify/ui-react';
import SummaryContent from './srs_sf';
import Delivery from './delivery_manager_sf';
import Offenders from './offenders_sf'; 
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './table-styles.css';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabsEasyShip = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [value, setValue] = React.useState(0);

  
  // -------------- PRE WBR DATA --------------
  

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSEStrackSRS, { 
            limit: 200000, nextToken })
        );
        const fetchedItems = response.data.listSEStrackSRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSEStrackSRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    useEffect(() => {
        try {
          fetchData();
        } catch (error) {
          console.error('Error fetching user group information:', error);
        }
    }, []);

    const handleRefreshData = async () => {
      try {
        setLoading(true);
        await fetchData();
        setLoading(false);
      } catch (error) {
        console.error('Error refreshing data:', error);
      }
    };
    const tableContainerStyle = {
      height: '80vh', 
      overflow: 'auto', 
      display: 'flex',
      flexDirection: 'column',
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div className='datatable' style={{ height: '100%', width: '100%', marginTop: '5vh' }}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Self Report Survey
        </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', padding: '0 24px', marginTop: '1vh' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="General Overview" {...a11yProps(0)} />
            <Tab label="Email Interactions" {...a11yProps(1)} />
            <Tab label="Inactive SRS Sellers" {...a11yProps(2)} />
          </Tabs>
        </Box>        
        <CustomTabPanel value={value} index={1}>
          <SummaryContent
            username={username}
            userGroup={userGroup}
            loading={loading}
            onRefresh={handleRefreshData} // Pass the function to the child component
            data={data}
            fetchData={fetchData} // Pass fetchData function to the child component
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={0}>
          <Delivery
            Username={username}
            userGroup={userGroup}
            loading={loading}
            onRefresh={handleRefreshData} // Pass the function to the child component
            data={data}
            fetchData={fetchData} // Pass fetchData function to the child component
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <Offenders
            Username={username}
            userGroup={userGroup}
            loading={loading}
            onRefresh={handleRefreshData} // Pass the function to the child component
            data={data}
            fetchData={fetchData} // Pass fetchData function to the child component
          />
        </CustomTabPanel>

      </Box>
      </div>
    );
    
};


export default withAuthenticator(TabsEasyShip);