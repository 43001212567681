import { listVFOrderStatuses } from '../../../graphql/queries';
import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation  } from 'aws-amplify';

import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      const tomorrowDate = tomorrow.toISOString().slice(0, 10); // Get tomorrow's date in YYYY-MM-DD format
      const startOfTomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
      console.log('tomorrow',tomorrow)
      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFOrderStatuses, {
            limit,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: startOfTomorrow, // Filter for dates greater than tomorrow's date
              },
            },
          })
        );
        const fetchedItems = response.data.listVFOrderStatuses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFOrderStatuses.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }

  }, []);

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }

  }, []);

  const sortedData_table = useMemo(() => {
    const currentDate = new Date();
  
    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= yesterday
    );
  
    // Group the data by expected_ship_date and warehouse_code
    const groupedData_2 = filteredItems.reduce((acc, item) => {
      const key = `${item.expected_ship_date}-${item.warehouse_code}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          expected_ship_date: item.expected_ship_date,
          warehouse_code: item.warehouse_code,
          confirmed: 0,
          new: 0,
          packed: 0,
          'invoice_submitted': 0,
          processed: 0,
          totalOrders: 0,
          totalOrders_processed: 0,
        };
      }
  
      // Determine the most recent status and update the corresponding column
 
      

      if (item.status === 'INVOICE_SUBMITTED') {
        acc[key]['invoice_submitted'] += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'PACKED') {
        acc[key].packed += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'CONFIRMED') {
        acc[key].confirmed += item.quantity;
      } else if (item.status === 'NEW') {
        acc[key].new += item.quantity;
      }
  
      acc[key].totalOrders += item.quantity;
  
      return acc;
    }, {});
  
    // Convert the grouped data to an array and sort it
    const sortedItems2 = Object.values(groupedData_2).sort((b, a) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.quantity - a.quantity;
    });
  
    // Calculate the processed percentage for each group
    sortedItems2.forEach(item => {
      item.processed = item.totalOrders > 0 ? item.totalOrders_processed / item.totalOrders : 0;
    });
  
    return sortedItems2;
  }, [data]);
  
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

 

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={8} xs={12} md={8}>
      <Typography
            sx={{
              marginTop: '2vh',
              //marginLeft: center,
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Future CPT (backlog and processed orders)
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedData_table}
            columns={[
              { field: 'warehouse_code', headerName: 'Node', flex: 1 },
              { field: 'expected_ship_date', headerName: 'Expected Ship Date', flex: 1, align: 'center', headerAlign: 'center' },

              { field: 'totalOrders', headerName: 'Total Expected', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'new', headerName: 'New', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'confirmed', headerName: 'Confirmed', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'packed', headerName: 'Packed', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'invoice_submitted', headerName: 'Invoice Submitted', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'processed', headerName: 'Processed %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { pageSize: 100 },
              sortModel: [{ field: 'deviation', sort: 'asc' }],
            }}
          />
        </Grid>


    </div>
  );
}

export default PostList;