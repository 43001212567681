import { useState, useEffect, useCallback, useMemo } from "react";
import { listMisses } from "../../../graphql/queries";
import { updateMisses } from "../../../graphql/mutations";
import { onUpdateMisses } from "../../../graphql/subscriptions";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Snackbar, Alert, Chip, Grid, } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_SF_columns';
import { getISOWeek, subWeeks  } from 'date-fns'; 
import './PreWBR.css';
import { withAuthenticator,Loader, } from '@aws-amplify/ui-react';
import { AppBar, Paper } from '@mui/material';
import SummaryContent from './summary';
import Reasons from './reasons';
import BlankReasonsSF from './blankReason';
import PreWBRContent from './PreWBR_SF';
import TopOffender from './TopOffenders';
import SellerData from './SellerData_SF';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';


Amplify.configure(awsconfig);


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const TabsSellerFlex = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [value, setValue] = React.useState(0);

  // -------------- PRE WBR DATA --------------
  function getCurrentWeekNumber() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate()) ;
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = (oneWeekAgo) - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  function getLastWeekNumber() {
    const now = new Date();
    const thisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfYear = new Date(now.getFullYear(), 0, 1);
  
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillThisWeek = thisWeek - startOfYear;
    let weekNumberThisWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek);
    let weekNumberLastWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek) -1 ;
  
    // Check if the week number is negative
    if (weekNumberLastWeek <= 0) {
      weekNumberLastWeek =0;
    }
  
    return weekNumberLastWeek;
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  function TabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  const currentWeekNumber = getCurrentWeekNumber();
  const lastWeekNumber = getLastWeekNumber();
  console.log(lastWeekNumber)
  async function fetchData() {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      const currentYear = new Date().getFullYear(); 
      do {
        const response = await API.graphql(graphqlOperation(listMisses, {
          filter: {
            and: [
              { week: { ge: lastWeekNumber } }, 
              {
                date: {
                  beginsWith: `${currentYear}-`, 
                },
              },
            ],
          },
          limit: 200000,
          nextToken: nextToken 
          
        }));
  
        const fetchedItems = response.data.listMisses.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listMisses.nextToken;
      } while (nextToken);
      setData(items);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


    useEffect(() => {
        try {
          fetchData();
        } catch (error) {
          console.error('Error fetching user group information:', error);
        }
    }, []);
    
    const handleRefreshData = async () => {
      try {
        setLoading(true);
        // Trigger your data fetching logic here
        await fetchData();
        setLoading(false);
      } catch (error) {
        console.error('Error refreshing data:', error);
      }
    };
    const tableContainerStyle = {
      height: '80vh', 
      overflow: 'auto', 
      display: 'flex',
      flexDirection: 'column',
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <div className='datatable' style={{ height: '100%', width: '100%', marginTop: '5vh' }}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
         >
            SELLER FLEX - DAILY TASKS
        </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', padding: '0 24px', marginTop: '1vh' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Reasons" {...a11yProps(1)} />
            <Tab label="Pre WBR Tab" {...a11yProps(2)} />
            <Tab label="Blank Reasons Ranking" {...a11yProps(3)} />
            <Tab label="Top Offenders" {...a11yProps(4)} />
            <Tab label="Seller Data" {...a11yProps(5)} />
          </Tabs>
        </Box>        
        <CustomTabPanel value={value} index={0}>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
            <SummaryContent username={username} userGroup={userGroup} />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PreWBRContent 
            username={username} 
            userGroup={userGroup} 
            data={data} 
            loading={loading} 
            onRefresh={handleRefreshData} // Pass the function to the child component
            fetchData={fetchData} // Pass fetchData function to the child component
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <BlankReasonsSF 
            username={username} 
            userGroup={userGroup} 
            data={data} 
            loading={loading} 
            onRefresh={handleRefreshData} // Pass the function to the child component
            fetchData={fetchData} // Pass fetchData function to the child component
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <TopOffender username={username} userGroup={userGroup} data={data} loading={loading}            
            onRefresh={handleRefreshData} 
            fetchData={fetchData}  />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <SellerData username={username} userGroup={userGroup}          
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Reasons username={username} userGroup={userGroup}          
          />
        </CustomTabPanel>

      </Box>
      </div>
    );
  
  };
  
  export default TabsSellerFlex;