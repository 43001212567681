import React, {useEffect, useState} from 'react';
import { Layout } from 'antd';
import { Typography, Avatar} from '@mui/material';
import Sidebar from './Sidebar';
import Homepage from './modules/pages/Homepage/Homepage';
import SellerDataSF from './modules/pages/SellerData-Pages/SellerData_SF';
import UploadFileS3 from './modules/pages/UploadFiles/Upload-file-S3';
import SellerDataES from './modules/pages/SellerData-Pages/SellerData_ES';
import TabsSellerFlex from './modules/pages/TabsSellerFlex/Tabs';
import TabsEasyShip from './modules/pages/TabsEasyShip/Tabs';
import TabsPCP from './modules/pages/PCP/Tabs';
import SES from './modules/pages/SEStracker/Tabs';
import SESSF from './modules/pages/SEStracker/Tabs_sf';
import Capacity from './modules/pages/Capacity/Tabs_sf';
import SESPCP from './modules/pages/SEStracker/Tabs_pcp';
import CloudWatch from './modules/pages/Osc Metrics/cloudwatch';
import OscCost from './modules/pages/Osc Metrics/osc-cost';
import TabsVendorFlex from './modules/pages/VendorFlex/Tabs';
import CptManagement from './modules/pages/CptManagement/cpt_management';
import PermissionError from './error-permission';
import NotFound from './Error404';
import SfForecast from './modules/pages/Forecast/SfForecast';
import EasyShipCutOff from './modules/pages/EasyShipCutOff/main_tab';
import VendorFlexCT from './modules/pages/ControlTowerVF/main_tab';
import SellerFlexCPT from './modules/pages/EasyShipCutOff/sf_main';
import LaunchReports from './modules/pages/LaunchProgram/launch-reports';
import DropOffHeatMap from './modules/pages/DropOff/Tabs';
import {Route, Routes, BrowserRouter as Router} from "react-router-dom";
import {Amplify, Auth, Hub} from "aws-amplify";
import awsmobile from "./aws-exports";
import {Loader} from "@aws-amplify/ui-react";
Amplify.configure(awsmobile)

const { Content } = Layout;
const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userGroup, setUserGroup] = useState(null);
  const [username, setUsername] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { Content, Header } = Layout;

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => {
            setUser(userData);
            setUserGroup(userData.signInUserSession.accessToken.payload['cognito:groups']); // Corrected this line
            //setUserGroup(user.signInUserSession.accessToken.payload['cognito:groups']);
            //setUserGroup(userData.attributes['cognito:groups']);
            setLoading(false);
          });
          break;
        case 'signOut':
          setUser(null);
          setUserGroup(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          setLoading(false);
          break;
      }
    });
    getUser().then(userData => {
      const fullUsername = userData.username;
      const usernameWithoutPrefix = fullUsername.replace('midwayauth2_', '');
      setUsername(usernameWithoutPrefix);
    });
    getUser().then(userData => {
      setUser(userData);
      setUserGroup(userData.signInUserSession.accessToken.payload['cognito:groups']); // Corrected this line
      //setUserGroup(user.signInUserSession.accessToken.payload['cognito:groups']);
      //setUserGroup(userData.attributes['cognito:groups']);
    });
  }, []);
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => {
        setLoading(false);
        return userData;
      })
      .catch(() => {
        console.log('Not signed in');
        setLoading(false);
      });
  }
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  async function signOut() {
    try {
      await Auth.signOut();
      console.log('Signing Out');
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }
  function generateDynamicRoutes(userGroup) {
    const dynamicRoutes = [];

    dynamicRoutes.push({ path: '/', element: <Homepage name={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/seller-data', element: <SellerDataSF username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/seller-data-es', element: <SellerDataES username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/queries', element: <PermissionError /> });
    dynamicRoutes.push({ path: '/cpt-mgt', element: <CptManagement /> });
    dynamicRoutes.push({ path: '*', element: <NotFound /> });
    dynamicRoutes.push({ path: '/seller-flex', element: <TabsSellerFlex username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/easy-ship', element: <TabsEasyShip username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/upload-file', element: <UploadFileS3 username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/pcp', element: <TabsPCP username={username} userGroup={userGroup} /> });
    //dynamicRoutes.push({ path: '/vf', element: <TabsVendorFlex username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/sfforecast', element: <SfForecast username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/escutoff', element: <EasyShipCutOff username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/sfcutoff', element: <SellerFlexCPT username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/vfct', element: <VendorFlexCT username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/srs_es', element: <SES username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/srs_sf', element: <SESSF username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/srs_pcp', element: <SESPCP username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/cloudwatch', element: <CloudWatch username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/osc-cost', element: <OscCost username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/launch-reports', element: <LaunchReports username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/drop-off', element: <DropOffHeatMap username={username} userGroup={userGroup} /> });
    dynamicRoutes.push({ path: '/capacity', element: <Capacity username={username} userGroup={userGroup} /> });
    if (userGroup.includes('us-west-2_cuSVtTOAA_midwayAuth2')) {
    }
    if (userGroup.includes('ops_owner')) {
    }
    if (userGroup.includes('pcp_team') || userGroup.includes('admin')) {
    dynamicRoutes.push({ path: '/pcp', element: <TabsPCP username={username} userGroup={userGroup} /> });
    }
    if (userGroup.includes('external')) {
      //dynamicRoutes.push({ path: '/pre-wbr-sf', element: <PermissionError /> });
      dynamicRoutes.push({ path: '/pre-wbr-es', element: <PermissionError /> });
    }
    return dynamicRoutes;
  }


  if (loading) {
    return (
      <Loader width="5rem" height="5rem" />
    );
  }
  if (user && userGroup) {
    const Groups = ['admin', 'pocs', 'ops_owner', 'managers'];
    console.log('Username:', username);
    console.log('Group:', userGroup);
    if (Groups.some(group => userGroup.includes(group)) || userGroup.includes("us-west-2_cuSVtTOAA_midwayAuth2")) {
      const badgePicture = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${username}`;
      const dynamicRoutes = generateDynamicRoutes(userGroup);
      return (
          <Layout style={{minHeight: '100vh'}}>
            <Sidebar isOpen={sidebarOpen} toggle={toggleSidebar}/>
            <Layout style={{marginLeft: sidebarOpen ? '200px' : '0', transition: 'margin 0.3s'}}>
              <div style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Avatar src={badgePicture} size={84}/>
                <h5
                    style={{
                      fontFamily: 'Source Sans Pro',
                      fontSize: 'calc(10px + 0.5vh)',
                      paddingBottom: '15px',
                      fontWeight: 'normal',
                    }}
                >
                  {username}
                </h5>
              </div>
              <Content style={{margin: '24px 16px', padding: 24, minHeight: 280}}>
                <Routes>
                  {dynamicRoutes.map((route) => (
                      <Route key={route.path} path={route.path} element={route.element}/>
                  ))}
                </Routes>
              </Content>
            </Layout>
          </Layout>
      );
    }
  } else {
    // User is not logged in
    return (
        /*<div>
          <p>User: Not logged in</p>
          <button onClick={() => Auth.federatedSignIn({ provider: 'MidwayOIDC' })}>
            Sign In
          </button>
        </div>*/
      Auth.federatedSignIn({ provider: 'midwayAuth2' })
    );
  }
};

export default App;
