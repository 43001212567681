import { useState, useEffect, useCallback, useMemo } from "react";
import { listMQBandQBRS, listSFOwnerPerformances, listSFClassificationPerformances } from "../../../graphql/queries";
import { listMisses } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { Loader } from '@aws-amplify/ui-react';
import { Android } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';

Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [datar, setDatar] = useState([]);
  const [datao, setDatao] = useState([]);
  const [datac, setDatac] = useState([]);
  const [loading, setLoading] = useState(true);
  
  

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();


  async function fetchData() {
    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(listMQBandQBRS, { limit: 100 }));
      const items = response.data.listMQBandQBRS.items;
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const fetchDataReasons = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listMisses, {
            limit: 20000,
            nextToken,
            filter: {
              and: [
                {
                  week: {
                    gt: currentWeekNumber - 5,
                  },
                },
                {
                  date: {
                    gt: startDate.toISOString().slice(0, 10),
                  },
                },
              ],
            },
          })
        );
        const fetchedItems = response.data.listMisses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listMisses.nextToken;
      } while (nextToken);
  
      setDatar(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataOwner = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listSFOwnerPerformances, {
            limit: 20000,
            nextToken,
            filter: {
              and: [
                {
                  week: {
                    gt: currentWeekNumber - 15,
                  },
                },
              ],
            },
          })
        );
        const fetchedItems = response.data.listSFOwnerPerformances.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSFOwnerPerformances.nextToken;
      } while (nextToken);
  
      setDatao(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataClassification = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listSFClassificationPerformances, {
            limit: 20000,
            nextToken,
            filter: {
              and: [
                {
                  week: {
                    gt: currentWeekNumber - 15,
                  },
                },
              ],
            },
          })
        );
        const fetchedItems = response.data.listSFClassificationPerformances.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSFClassificationPerformances.nextToken;
      } while (nextToken);
  
      setDatac(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const sortedData2 = useMemo(() => {
    const sortedItems = [...datao].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [datao]);


  const sortedData1 = useMemo(() => {
    const sortedItems = [...data].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [data]);

  const sortedData3 = useMemo(() => {
    const sortedItems = [...datac].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [datac]);

  const filterData = (data, missTypeFilter) => {
    return data.filter(item => 
      item.miss_type === missTypeFilter
    );
  };

  const aggregatedDataByWeekAndReason = useMemo(() => {
    const aggregated = {};
    const totalByWeekAndMissType = {};
  
    // Aggregate units by week_miss_type_reason
    for (const item of datar) {
      const week = item.week || 'Unknown';
      const miss_type = item.miss_type || 'NULL';
      const reason = item.reason || 'Unknown';
      const units = Number(item.units) || 0;
  
      const id = `${week}_${miss_type}_${reason}`;
  
      if (!aggregated[id]) {
        aggregated[id] = {
          id,
          week,
          reason,
          miss_type,
          units: 0,
        };
      }
  
      aggregated[id].units += units;
  
      // Calculate total by week and miss_type
      if (!totalByWeekAndMissType[week]) {
        totalByWeekAndMissType[week] = {};
      }
      if (!totalByWeekAndMissType[week][miss_type]) {
        totalByWeekAndMissType[week][miss_type] = 0;
      }
      totalByWeekAndMissType[week][miss_type] += units;
    }
  
    // Convert the object to an array and calculate percentage
    const result = Object.values(aggregated).map(item => {
      const totalForWeekAndMissType = totalByWeekAndMissType[item.week][item.miss_type];
      return {
        ...item,
        units_sum: totalForWeekAndMissType,
        percentage: totalForWeekAndMissType > 0 
          ? (item.units / totalForWeekAndMissType) * 100 
          : 0
      };
    });
  
    // Sort by units in descending order
    result.sort((a, b) => b.units - a.units);
  
    // Add the order column
    result.forEach((item, index) => {
      item.order = index + 1;
    });
    console.log('Dados de entrada:', datar);
  console.log('Dados agregados:', result);

  return result;
}, [datar]);



  useEffect(() => {
      try {
        fetchData();
        fetchDataReasons();
        fetchDataOwner();
        fetchDataClassification();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

const [filteredDataLH, setFilteredDataLH] = useState([]);
const [filteredDataMXSD, setFilteredDataMXSD] = useState([]);
const [filteredDataCancellation, setFilteredDataCancellation] = useState([]);

useEffect(() => {
  setFilteredDataLH(filterData(aggregatedDataByWeekAndReason, 'LH_BY_SELLER'));
  setFilteredDataMXSD(filterData(aggregatedDataByWeekAndReason, 'MXSD'));
  setFilteredDataCancellation(filterData(aggregatedDataByWeekAndReason, 'CANCELLATION'));
}, [aggregatedDataByWeekAndReason]);


  const reasons = [
    { field: 'week', headerName: 'Week',width: 50,headerClassName: 'customHeader', },
    { field: 'reason', type: 'number', headerName: 'Reason', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'units', type: 'number', headerName: 'Units', align: 'center', headerAlign: 'center', width: 100,headerClassName: 'customHeader', },
    { field: 'percentage', type: 'number', headerName: '%', align: 'center', headerAlign: 'center', width: 70,headerClassName: 'customHeader',
      valueFormatter: (params) => {
        const value = params.value; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      },
  
    }
  ];

  const Classification = [
    { field: 'week', headerName: 'Week',flex: 1,headerClassName: 'customHeader', },
    { field: 'classification', type: 'number', headerName: 'Classification', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'totalunits', type: 'number', headerName: 'Shipped Units', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'share_perc', type: 'number', headerName: 'Share %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      valueFormatter: (params) => {
        const value = params.value * 100; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      }},
    { field: 'mxsd', type: 'number', headerName: 'MXSD', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'mxsd_perc', type: 'number', headerName: 'MXSD %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.38;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 0.38%
            </div>
          </div>
        );
      }},
    { field: 'scr', type: 'number', headerName: 'SCR', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'scr_perc', type: 'number', headerName: 'SCR %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.07;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 0.07%
            </div>
          </div>
        );
      },

    },

    { field: 'lh_by_seller', type: 'number', headerName: 'LH', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'lh_perc', type: 'number', headerName: 'LH %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1.20;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.20%     ST: 0.75%
            </div>
          </div>
        );
      },

    }
  ];

  const owner = [
    { field: 'week', headerName: 'Week',flex: 1,headerClassName: 'customHeader', },
    { field: 'ops_owner', type: 'number', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'totalunits', type: 'number', headerName: 'Shipped Units', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'share_perc', type: 'number', headerName: 'Share %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      valueFormatter: (params) => {
        const value = params.value * 100; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      }},
    { field: 'mxsd', type: 'number', headerName: 'MXSD', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'mxsd_perc', type: 'number', headerName: 'MXSD %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.38;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 0.38%
            </div>
          </div>
        );
      }},
    { field: 'scr', type: 'number', headerName: 'SCR', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'scr_perc', type: 'number', headerName: 'SCR %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.07;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 0.07%
            </div>
          </div>
        );
      },

    },

    { field: 'lh_by_seller', type: 'number', headerName: 'LH', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader', },
    { field: 'lh_perc', type: 'number', headerName: 'LH %', align: 'center', headerAlign: 'center', flex: 1,headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1.20;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.20%     ST: 0.75%
            </div>
          </div>
        );
      },

    }
  ];


  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>

<Grid item xs={1} textAlign="right">
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshData}
                >
                Refresh Data
              </Button>
          </Grid>
          <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    textAlign: 'center', // Adicionando alinhamento central
                    fontWeight: 'bold'
                }}
                >
                General Performance
              </Typography>
              


        <Typography
        sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
        }}
        >
        </Typography>
        {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
                </div>
            </div>
        ) : (
        <>
        <DataGrid
        autoHeight
        sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            maxHeight: '80vh', // Definindo a altura máxima da tabela
            overflow: 'auto', // Permitindo o scroll vertical quando necessário
        }}
        rows={sortedData1}
        columns={[
          {
            field: "week",
            headerName: "Week",
            headerClassName: 'customHeader',
            type: 'number',
            width:150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            },
          {
            field: "su_week",
            headerName: "Shipped Units",
            headerClassName: 'customHeader',
            type: 'number',
            width: 100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
          },
          {
            field: "wow",
            headerName: "WoW",
            headerClassName: 'customHeader',
            width:100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
              const value = params.value * 100; 
              const formattedValue = `${value.toFixed(2)}%`;
        
              if (value > 0) {
                return `+ ${formattedValue}`; 
              }
        
              return formattedValue;
            },
          },

          {
            field: "su_quarter",
            headerName: "QTD",
            headerClassName: 'customHeader',
            width:100,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => {
              const value = params.value;
              return value ? value.toLocaleString() : '';
            },
          },
          {
            field: "ft_share",
            headerName: "FT Share",
            headerClassName: 'customHeader',
            width:100,
            valueFormatter: (params) => {
              const value = params.value * 100; // Multiply by 100 to convert to percentage
              const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
              return formattedValue;
            },
            editable: false,
            headerAlign: 'center',
            align: 'center',
            
          },
           {
             field: "lsr_perc",
             headerName: "MXSD (%)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             renderCell: (params) => {
               const value = params.value * 100;
               const formattedValue = `${value.toFixed(2)}%`;
               const isPositive = value >= 0.38;
               
               return (
                 <div
                   style={{
                     color: isPositive ? 'red' : 'green',
                     fontWeight: 'bold',
                   }}
                 >
                   {formattedValue}
                   <div style={{ fontSize: '0.7em', color: 'gray' }}>
                     G: 0.38%
                   </div>
                 </div>
               );
             },
           },  
           {
             field: "lsr",
             headerName: "MXSD (Un)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             valueFormatter: (params) => {
               const value = params.value;
               return value ? value.toLocaleString() : '';
             },
           },
           {
             field: "lsr_wow",
             headerName: "MXSD (WoW)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             valueFormatter: (params) => {
               const value = params.value * 100; 
               const formattedValue = `${value.toFixed(2)}%`; 
         
               if (value > 0) {
                 return `+ ${formattedValue}`; 
               }
         
               return formattedValue;
             },
           },
           {
             field: "scr_perc",
             headerName: "SCR (%)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             renderCell: (params) => {
               const value = params.value * 100;
               const formattedValue = `${value.toFixed(2)}%`;
               const isPositive = value >= 0.07;
               
               return (
                 <div
                   style={{
                     color: isPositive ? 'red' : 'green',
                     fontWeight: 'bold',
                   }}
                 >
                   {formattedValue}
                   <div style={{ fontSize: '0.7em', color: 'gray' }}>
                     G: 0.07%
                   </div>
                 </div>
               );
             },
           },  
           {
             field: "scr",
             headerName: "SCR (Un)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
           },
           {
             field: "scr_wow",
             headerName: "SCR (WoW)",
             headerClassName: 'customHeader',
             width:100,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             valueFormatter: (params) => {
               const value = params.value * 100;
               const formattedValue = `${value.toFixed(2)}%`; 
         
               if (value > 0) {
                 return `+ ${formattedValue}`; // Add a "+" sign for positive values
               }
         
               return formattedValue;
             },
           },
           {
             field: "scr_controllable_perc",
             headerName: "SCR - Seller Controllable",
             headerClassName: 'customHeader',
             width:150,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             renderCell: (params) => {
               const value = params.value * 100;
               const formattedValue = `${value.toFixed(2)}%`;
               const isPositive = value >= 0.05;
               
               return (
                 <div
                   style={{
                     color: isPositive ? 'red' : 'green',
                     fontWeight: 'bold',
                   }}
                 >
                   {formattedValue}
                   <div style={{ fontSize: '0.7em', color: 'gray' }}>
                     G: 0.05%
                   </div>
                 </div>
               );
             },
           },  
           {
             field: "fdps_perc",
             headerName: "FDPS - Seller Miss (%)",
             headerClassName: 'customHeader',
             width:150,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             renderCell: (params) => {
               const value = params.value * 100;
               const formattedValue = `${value.toFixed(2)}%`;
               const isPositive = value >= 1.20;
               
               return (
                 <div
                   style={{
                     color: isPositive ? 'red' : 'green',
                     fontWeight: 'bold',
                   }}
                 >
                   {formattedValue}
                   <div style={{ fontSize: '0.7em', color: 'gray' }}>
                     G: 1.20%     ST: 0.75%
                   </div>
                 </div>
               );
             },
           },  
           {
             field: "fdps",
             headerName: "FDPS - Seller Miss",
             headerClassName: 'customHeader',
             width:150,
             editable: false,
             headerAlign: 'center',
             align: 'center',
           },
       
           {
             field: "fdps_wow",
             headerName: "FDPS (WoW)",
             headerClassName: 'customHeader',
             width:150,
             editable: false,
             headerAlign: 'center',
             align: 'center',
             valueFormatter: (params) => {
               const value = params.value * 100; // Multiply by 100 to convert to percentage
               const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
         
               if (value > 0) {
                 return `+ ${formattedValue}`; // Add a "+" sign for positive values
               }
         
               return formattedValue;
             },
           },
          ]}
        components={{
            Toolbar: CustomToolbar,
        }}
        slotProps={{
            toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '=', value: currentWeekNumber }],
            },
          },
      }} 
        />
              
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, marginTop: '2vh' }}>
  {/* Carrier Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      MXSD
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataMXSD}
      columns={reasons}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>

  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      SCR
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataCancellation}
      columns={reasons}
      disableColumnSelector
      disableDensitySelector
      pageSize={5}
      rowsPerPageOptions={[5]}
      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [{ field: 'week', operator: 'equals', value: currentWeekNumber.toString() }],
          },
        },
        pagination: { paginationModel: { pageSize: 5 } },
      }}
    />
  </Box>
  {/* Seller Miss Table */}
  <Box sx={{ flex: 1 }}>
    <Typography
      sx={{
        marginLeft: 3,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(15px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Seller Miss - FDPS
    </Typography>
    <DataGrid
      sx={{
        marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        height: '40vh',
        overflow: 'auto',
      }}
      rows={filteredDataLH}
      columns={reasons}
      disableColumnSelector
      disableDensitySelector



      disableSelectionOnClick
      initialState={{
        filter: {
          filterModel: {
            items: [
              { field: 'week', operator: 'equals', value: currentWeekNumber.toString() }

            ],

          },
        },

      }}
      
    />
  </Box>
</Box>
<Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Classification
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >          
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
      <DataGrid
        sx={{
          marginTop: '1vh',
        marginLeft: 2,
        marginRight: 2,
        padding: '10px 18px 18px 18px',
        backgroundColor: "#FFFFFF",
        border: '0px',
        borderRadius: '10px',
        minHeight: '40%',
        maxHeight: '40%',
        overflow: 'auto',
        }}
        rows={sortedData3}
        columns={Classification}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [
                { field: 'week', operator: 'equals', value: currentWeekNumber.toString() }
  
              ],
  
            },
          },
      }}   
      />
      )}

      <Grid container spacing={2} direction="row">
        <Grid item xs={6} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Owner
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
            
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <CircularProgress />
              </div>
            </div>
          ) : (
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40%',
          maxHeight: '40%',
        }}
        rows={sortedData2}
        columns={owner}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnSelector
        disableDensitySelector 
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [
                { field: 'week', operator: 'equals', value: currentWeekNumber.toString() }
  
              ],
  
            },
          },
      }}   
      />
      )}
          
          </>

          

          
        )

        
        
        
        
        } 
        
    </div>
    
    );
    
    
};

export default (Summary);
