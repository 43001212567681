import { Storage, Auth } from 'aws-amplify';

async function uploadToS3(file, fileName, setProgress, setError, setSuccess) {
  try {
    // Get the user's credentials
    const credentials = await Auth.currentUserCredentials();
    const user = await Auth.currentAuthenticatedUser();
    const fulusername = user.username;
    const username = fulusername.replace('midwayauth2_', '');

    const brazilDateTime = new Date().toLocaleString("en-US", {
      timeZone: "America/Sao_Paulo",
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(/[/,:\s]/g, '_');

    const fileKey = `${username}-${brazilDateTime}-${fileName}`;

    const { identityId } = await Auth.currentCredentials();
  console.log('Identity ID:', identityId);

    
    // Upload the file to S3
    await Storage.put(fileKey, file, {
      //level: 'public',
      contentType: file.type,
      bucket: 'user-upload-file-bucket16304-dev',
      region: 'us-west-2',
      metadata: { cacheControl: 'max-age=31536000' },
      progressCallback: (data) => {
        // Calculate the upload progress percentage
        const percent = Math.round((data.loaded / data.total) * 100);
        setProgress(percent);
        console.log(`Uploaded: ${data.loaded}/${data.total} (${percent}%)`);
      },
    });

    setSuccess(true);
    console.log('File uploaded successfully');
  } catch (error) {
    console.error('Error uploading file:', error);

    // Check if the error is an `AccessDenied` error
    if (error.name === 'StorageUploadError' && error.message.includes('AccessDenied')) {
      setError('You do not have permission to upload files. Please contact your administrator.');
    } else {
      setError('An error occurred while uploading the file. Please try again later.');
    }
  }
}

export { uploadToS3 };