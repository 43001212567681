import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [

      { field: 'week', headerName: 'Week',width: 50,headerClassName: 'customHeader', },
      { field: 'reason', type: 'number', headerName: 'Reason', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
      { field: 'units', type: 'number', headerName: 'Units', align: 'center', headerAlign: 'center', width: 100,headerClassName: 'customHeader', },
      { field: 'percentage', type: 'number', headerName: '%', align: 'center', headerAlign: 'center', width: 70,headerClassName: 'customHeader',
        valueFormatter: (params) => {
          const value = params.value; // Multiply by 100 to convert to percentage
          const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
          return formattedValue;
        },
    
      }
    ];
    


export default tableColumns;