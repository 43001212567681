import { useState, useEffect, useMemo,useCallback } from "react";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Chip, Snackbar, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import { listSellerDataPCP2s,listPCPPreWBRS} from "../../../graphql/queries";
import * as React from 'react';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_PCP_columns';
import './table-styles.css';
import { updatePCPPreWBR } from "../../../graphql/mutations";


Amplify.configure(awsconfig);





const Summary = ({ loading, onRefresh, username, userGroup }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  

  
  const fetchDataSD = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSellerDataPCP2s, {
          limit: 200000,
          nextToken: nextToken
        }));

        const fetchedItems = response.data.listSellerDataPCP2s.items;
        items.push(...fetchedItems);

        nextToken = response.data.listSellerDataPCP2s.nextToken;
      } while (nextToken);
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchDataFDPS = async () => {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      const today = new Date();
      const fifteenDaysAgo = new Date(today.getTime() - (15 * 24 * 60 * 60 * 1000)); // 15 days ago
  
      do {
        const response = await API.graphql(graphqlOperation(listPCPPreWBRS, {
          limit: 200000,
          nextToken: nextToken,
          filter: {
            expected_ship_date: {
              gt: fifteenDaysAgo.toISOString(),
            },
            and: [
              { miss_type: { eq: 'Seller Miss' } },
            ],
          }
        }));
  
        const fetchedItems = response.data.listPCPPreWBRS.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);
  
      return items;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const mergeData2 = (sellerData, fdpsData) => {
    const mergedData2 = fdpsData.map(fdps => {
      const seller = sellerData.find(seller => String(fdps.merchant_id) === seller.id);
  
      const missed_units = fdps.total - fdps.fdps;
  
      return {
        ...fdps,
        ops_support: seller ? seller.ops_support : null,
        seller_name: seller ? seller.seller_name : null,
        missed_units: missed_units
      };
    });
  
    return mergedData2;
  };




  useEffect(() => {
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]);

  

  useEffect(() => {
    const fetchDataAndMerge2 = async () => {
      try {
        const sellerData = await fetchDataSD();
        const sellerFDPS = await fetchDataFDPS();
        const mergedData2 = mergeData2(sellerData, sellerFDPS);
        setData2(mergedData2);
        setLoading(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };

    fetchDataAndMerge2();
  }, []);

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  

  // Get the date for 1 week ago
  const sortedData = useMemo(() => {
    const sortedItems = [...data2].sort((a, b) => {
      // Sort by expected_ship_date column in descending order
      return new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
    });
  
    return sortedItems;
  }, [data2]);

let tableColumns;


if (userGroup.includes('admin') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owner') || userGroup.includes('managers')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}



  

  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };

  const handleEditRow = useCallback(async (params) => {
    const { id,merchant_id,missed_units,actual_pick_up_date,fdps,total,reason,expected_ship_date,amazon_comments,miss_type,miss_type_seller,subreason_seller,reason_seller, _version } = params;
    const editedRow = { id,merchant_id,actual_pick_up_date,missed_units,fdps,total,reason,expected_ship_date,amazon_comments,miss_type,miss_type_seller,subreason_seller,reason_seller, _version };
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id,reason_seller,subreason_seller,miss_type_seller, _version } of editedRows) {
        const input = {
          id : id,
          reason_seller: reason_seller,
          subreason_seller: subreason_seller,
          miss_type_seller: miss_type_seller,
          _version: _version,
          edited_by: username, 
        };
        console.log(input)
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updatePCPPreWBR, { input }));
      }
      setInitialData((prevData) => {
        const updatedData = prevData.map((row) => {
          const editedRow = editedRows.find((editedRow) => editedRow.id === row.id);
          console.log(editedRow)
          return editedRow ? { ...row, ...editedRow } : row;
        });
        return updatedData;
      });
      // Clear the edited rows
      setEditedRows([]);
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };


  


  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} 
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchDataFDPS();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  console.log("datafinal",data2)
    // 

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <div>
          <Grid container spacing={2} direction="row">
          <Grid item xs={3} md={3}>
            <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    fontWeight: 'bold',
                }}
                >
                PCP - Pre WBR
                </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshData}
                >
                Refresh Data
              </Button>
          </Grid>
          <Grid item xs={8} md={8} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              
          </Grid>
          </Grid>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  maxHeight: '90vh',
                  overflow: 'auto', 
              }}
              rows={sortedData}
              columns={tableColumns}
              processRowUpdate={handleEditRow}
              onCellEditCommit={handleEditRow}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              checkboxSelection
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              message="Edition saved successfully!"
              action={
                <Button color="primary" size="small" onClick={handleSnackbarClose}>
                  Close
                </Button>
              }
            />
        </div>
    </div>
  );
};


export default withAuthenticator(Summary);
