import { useState, useEffect, useMemo } from "react";
import { listPCPPreWBRS, listPCPDefects, listPCPOwnerPerformances, listPCPClassificationPerformances} from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';



Amplify.configure(awsconfig);

const groupTypes = ['day','week'];

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [datao, setDatao] = useState([]);
  const [datac, setDatac] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [defectData, setDefectsData] = useState([]); // Estado para armazenar dados de defects

  const fetchDataOwner = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPOwnerPerformances, {
            limit: 20000,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: twoMonthsAgo.toISOString().slice(0, 10),
              },
            },

          })
        );
        const fetchedItems = response.data.listPCPOwnerPerformances.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPOwnerPerformances.nextToken;
      } while (nextToken);
  
      setDatao(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataClassification = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPClassificationPerformances, {
            limit: 20000,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: twoMonthsAgo.toISOString().slice(0, 10),
              },
            },

          })
        );
        const fetchedItems = response.data.listPCPClassificationPerformances.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPClassificationPerformances.nextToken;
      } while (nextToken);
  
      setDatac(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPPreWBRS, {
            limit: 20000,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: twoMonthsAgo.toISOString().slice(0, 10),
              },
            },

          })
        );
        const fetchedItems = response.data.listPCPPreWBRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
      fetchDataOwner();
      fetchDataClassification();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);

//inicio defects
const fetchDefectsData = async () => {
  try {
    setLoading(true);
    const startDate = new Date('2024-09-01');
    let items = [];
    let nextToken = null;
    const today = new Date();
    const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    do {
      const response = await API.graphql(graphqlOperation(listPCPDefects, 
        { limit: 20000, 
          nextToken,
          filter: {
            date_value: {
              gt: twoMonthsAgo.toISOString().slice(0, 10),
            },
          },
        })
      );
      
      const fetchedItems = response.data.listPCPDefects.items;
      items = items.concat(fetchedItems);
      nextToken = response.data.listPCPDefects.nextToken;
    } while (nextToken);
    setDefectsData(items);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching defects data:', error);
  }
};

useEffect(() => {
  fetchDefectsData();
}, []);

  //fim defects

  useEffect(() => {
    // Calculate total confirmed packages when data changes
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]); 

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  const getWeekNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);

    // Set to nearest Saturday: current date - current day number + 6
    date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 6);

    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Saturday
    var weekNo = Math.ceil(((date - yearStart) / 86400000) / 7);

    // Return array of year and week number
    return [date.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);
  
    // Get the year
    const year = date.getUTCFullYear();
  
    // Get month number (0-11), then add 1 to get (1-12)
    const monthNo = date.getUTCMonth() + 1;
  
    // Return array of year and month number
    return [year, monthNo];
  };

  const getQuarterNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);
  
    // Get the year
    const year = date.getUTCFullYear();
  
    // Get month number (0-11), then calculate quarter (1-4)
    const month = date.getUTCMonth();
    const quarterNo = Math.floor(month / 3) + 1;
  
    // Return array of year and quarter number
    return [month, quarterNo];
  };
  

  

  

  const groupDefectData = useMemo(() => {
    const sortedData = [...defectData].sort((a, b) => new Date(b.date_value) - new Date(a.date_value));
    return groupBy(sortedData, (item) => {
      const date_value = new Date(item.date_value);
      switch (groupByType) {

        case 'week':
          const weekNumber = getWeekNumber(date_value)[1];
          return `(${date_value.getFullYear()}) Week ${weekNumber.toString().padStart(2, '0')}`;
        case 'month':
          const monthNumber = getMonthNumber(date_value)[1];
          return `(${date_value.getFullYear()}) Month ${monthNumber.toString().padStart(2, '0')}`;
          case 'quarter':
            const quarterNumber = getQuarterNumber(date_value)[1];
            return `(${date_value.getFullYear()}) Q${quarterNumber.toString().padStart(2, '0')}`;
        case 'year':
          return `(${date_value.getFullYear()})`;
        default:
          return date_value.toISOString().split('T')[0];
      }
    });
  }, [defectData, groupByType]);
  
  const groupData = useMemo(() => {
    // Ordena os dados por 'expected_ship_date' antes de agrupá-los
    const sortedData = [...data].sort((a, b) => new Date(b.expected_ship_date) - new Date(a.expected_ship_date));
  
    return groupBy(sortedData, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      switch (groupByType) {
        case 'day':
          return expected_ship_date.toISOString().split('T')[0];
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          return `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
        
      }
    });
  }, [data, groupByType]);
  
  const aggregatedData = useMemo(() => {
    const uniqueData = {};
    let id = 0;
  
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        uniqueData[key] = {
          id: id++,
          expected_ship_date: key,
          total: 0,
          fdps: 0,
          pickup: 0,
          fdps2: 0,
          seller_miss: 0,
          seller_miss_2: 0,
          seller_miss_1: 0, // Add this new field
          carrier_miss_1: 0,
          carrier_miss_2: 0,
          carrier_miss: 0,
          seller_miss_perc: 0,
          carrier_miss_perc: 0,
        };
  
        for (const item of items) {
          uniqueData[key].total += item.total || 0;
          uniqueData[key].fdps += item.fdps || 0;
          uniqueData[key].pickup += (item.total - item.fdps) || 0;
  
          // Check if the amazon_comments is "Incorrect Address" and add the value to the incorrect_address_sum
          if
          (item.reason.includes("No Show Seller")) {
            uniqueData[key].seller_miss_1 += item.total || 0;
            uniqueData[key].seller_miss_2 += item.fdps || 0;

           }

           if
          (item.reason === "No Show Carrier") {
            uniqueData[key].carrier_miss_1 += item.total || 0;
            uniqueData[key].carrier_miss_2 += item.fdps || 0;
           }

           if
          (item.reason === "No Show Tex") {
            uniqueData[key].carrier_miss_1 += item.total || 0;
            uniqueData[key].carrier_miss_2 += item.fdps || 0;
           }
        }

        uniqueData[key].carrier_miss = uniqueData[key].carrier_miss_1 - uniqueData[key].carrier_miss_2;
        uniqueData[key].seller_miss = uniqueData[key].seller_miss_1 - uniqueData[key].seller_miss_2;

        
  
        if (uniqueData[key].total && uniqueData[key].fdps) {
          uniqueData[key].fdps2 = ((uniqueData[key].total - uniqueData[key].pickup) / uniqueData[key].total);
        }

        if (uniqueData[key].total && uniqueData[key].seller_miss) {
          uniqueData[key].seller_miss_perc = ((uniqueData[key].seller_miss) / uniqueData[key].total);
        }

        if (uniqueData[key].total && uniqueData[key].carrier_miss) {
          uniqueData[key].carrier_miss_perc = ((uniqueData[key].carrier_miss) / uniqueData[key].total);
        }
      }
    }
  
    return Object.values(uniqueData);
  }, [groupData]);

  console.log('aggregatedData:', aggregatedData);

  const aggregatedDataByClassification = useMemo(() => {
    const aggregatedData = {};
    const groupTotals = {};
  
    // First pass: aggregate data and calculate group totals
    datac.forEach((item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
  
      const classification = item.classification || 'N/A';
      const key = `${groupKey}_${classification}`;
  
      if (!aggregatedData[key]) {
        aggregatedData[key] = { 
          id: key,
          groupKey, 
          classification, 
          total2: 0,
          fdps2: 0,
          miss2: 0,
          seller_miss2: 0,
          carrier_miss2: 0,
        };
      }
  
      aggregatedData[key].total2 += item.total || 0;
      aggregatedData[key].fdps2 += item.fdps || 0;
      aggregatedData[key].miss2 += item.miss || 0;
      aggregatedData[key].seller_miss2 += item.seller_miss || 0;
      aggregatedData[key].carrier_miss2 += item.carrier_miss || 0;
  
      // Calculate group totals
      if (!groupTotals[groupKey]) {
        groupTotals[groupKey] = 0;
      }
      groupTotals[groupKey] += item.total || 0;
    });
  
    // Second pass: calculate percentages and shares
    const result = Object.values(aggregatedData).map(item => {
      const groupTotal = groupTotals[item.groupKey];
      return {
        ...item,
        share: groupTotal > 0 ? item.total2 / groupTotal : 0,
        seller_miss_perc: item.total2 > 0 ? item.seller_miss2 / item.total2 : 0,
        carrier_miss_perc: item.total2 > 0 ? item.carrier_miss2 / item.total2 : 0,
        misses_perc: item.total2 > 0 ? (item.total2 - item.fdps2) / item.total2 : 0,
      };
    });
  
    // Sort the result array by groupKey in descending order, then by ops_support
    result.sort((a, b) => {
      const groupKeyComparison = b.groupKey.localeCompare(a.groupKey);
      if (groupKeyComparison !== 0) {
        return groupKeyComparison;
      }
      return a.classification.localeCompare(b.classification);
    });
  
    return result;
  }, [datac, groupByType]);

  const aggregatedDataByOwner = useMemo(() => {
    const aggregatedData = {};
    const groupTotals = {};
  
    // First pass: aggregate data and calculate group totals
    datao.forEach((item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
  
      const ops_support = item.ops_support || 'N/A';
      const key = `${groupKey}_${ops_support}`;
  
      if (!aggregatedData[key]) {
        aggregatedData[key] = { 
          id: key,
          groupKey, 
          ops_support, 
          total2: 0,
          fdps2: 0,
          miss2: 0,
          seller_miss2: 0,
          carrier_miss2: 0,
        };
      }
  
      aggregatedData[key].total2 += item.total || 0;
      aggregatedData[key].fdps2 += item.fdps || 0;
      aggregatedData[key].miss2 += item.miss || 0;
      aggregatedData[key].seller_miss2 += item.seller_miss || 0;
      aggregatedData[key].carrier_miss2 += item.carrier_miss || 0;
  
      // Calculate group totals
      if (!groupTotals[groupKey]) {
        groupTotals[groupKey] = 0;
      }
      groupTotals[groupKey] += item.total || 0;
    });
  
    // Second pass: calculate percentages and shares
    const result = Object.values(aggregatedData).map(item => {
      const groupTotal = groupTotals[item.groupKey];
      return {
        ...item,
        share: groupTotal > 0 ? item.total2 / groupTotal : 0,
        seller_miss_perc: item.total2 > 0 ? item.seller_miss2 / item.total2 : 0,
        carrier_miss_perc: item.total2 > 0 ? item.carrier_miss2 / item.total2 : 0,
        misses_perc: item.total2 > 0 ? (item.total2 - item.fdps2) / item.total2 : 0,
      };
    });
  
    // Sort the result array by groupKey in descending order, then by ops_support
    result.sort((a, b) => {
      const groupKeyComparison = b.groupKey.localeCompare(a.groupKey);
      if (groupKeyComparison !== 0) {
        return groupKeyComparison;
      }
      return a.ops_support.localeCompare(b.ops_support);
    });
  
    return result;
  }, [datao, groupByType]);

  const aggregatedDataById2AmazonComments2 = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
      return `${groupKey}_${item.amazon_comments || 'N/A'}`;
    });
    
    

    const aggregatedById2 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, amazon_comments] = key.split('_');
        const aggregatedItem = { groupKey, amazon_comments, id2: key, total2: items.length };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'expected_ship_date' && column !== 'amazon_comments' && column !== 'actual_pick_up_date' &&(typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }

        
        aggregatedById2.push(aggregatedItem);
      }
    }   

    // Sort the aggregatedById2 array by the groupKey in descending order
    aggregatedById2.sort((b, a) => a.groupKey.localeCompare(b.groupKey));
 
    // Filter out rows not related to Amazon comments
    const filteredAggregatedById2 = aggregatedById2.filter(item => (
      item.amazon_comments &&
      (item.amazon_comments.includes("Seller didn't provide the packages") ||
       item.amazon_comments.includes('Seller Unavailable') ||
       item.amazon_comments.includes('Incorrect Address') ||
       item.amazon_comments.includes('- Seller'))
    ));

    return filteredAggregatedById2;
  }, [data, groupByType]);

  const aggregatedDefectData = useMemo(() => {
    const aggregated = [];
    for (const key in groupDefectData) {
      if (groupDefectData.hasOwnProperty(key)) {
        const items = groupDefectData[key];
        const aggregatedItem = { id: key, date_value: key};
        for (const item of items) {
          for (const column in item) {
            if (column !== 'date_value' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        if (aggregatedItem.hdsc && aggregatedItem.shipped_units) {
          aggregatedItem.defhdsc = (aggregatedItem.hdsc / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defhdsc = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.rpi && aggregatedItem.shipped_units) {
          aggregatedItem.defrpi = (aggregatedItem.rpi / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defrpi = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.prep && aggregatedItem.shipped_units) {
          aggregatedItem.defprep = (aggregatedItem.prep / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defprep = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.iss_not_inbounded && aggregatedItem.shipped_units) {
          aggregatedItem.defiss_not_inbounded = (aggregatedItem.iss_not_inbounded / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defiss_not_inbounded = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.iss_inbounded && aggregatedItem.shipped_units) {
          aggregatedItem.defiss_inbounded = (aggregatedItem.iss_inbounded / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defiss_inbounded = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        
        aggregated.push(aggregatedItem);
      }
    }
    return aggregated;
  }, [groupDefectData]);

  const calculateCancelledPackages = (totalConfirmed, totalFdps) => {
    if (totalConfirmed - totalFdps === 0) return 0;
    return (totalConfirmed - totalFdps);
  };

  const aggregatedDataById2AmazonComments = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
      return `${groupKey}_${item.amazon_comments || 'N/A'}`;
    });
  
    const aggregatedById3 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, amazon_comments] = key.split('_');
        const aggregatedItem = { groupKey, amazon_comments, id2: key, total2: items.length };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'expected_ship_date' && column !== 'amazon_comments' && column!== 'actual_pick_up_date'&&(typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
       
        aggregatedById3.push(aggregatedItem);
      }
    }
    // Sort the aggregatedById2 array by the groupKey in descending order
    aggregatedById3.sort((b, a) => a.groupKey.localeCompare(b.groupKey));
  
    // Filter out rows not related to Amazon comments
    const filteredAggregatedById3 = aggregatedById3.filter(item => (
      item.amazon_comments &&
      (item.amazon_comments.includes("Bip at Carrier's Facility") ||
        item.amazon_comments.includes('Carrier No Show') ||
        item.amazon_comments === 'Others - Carrier')
    ));

    return filteredAggregatedById3;
  }, [data, groupByType]);


  const sellerMissColumns = [
    { field: 'groupKey', headerName: 'Group Key', width: 150,headerClassName: 'customHeader' },
    { field: 'amazon_comments', type: 'number', headerName: 'Reasons', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'cancelledPackages', type: 'number', headerName: 'Packages', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'cancelledPackagesPercentage', type: 'number', headerName: 'Packages %', flex: 1, align: 'center', headerAlign: 'center', headerClassName: 'customHeader',valueGetter: (params) => {
      const matchingRow = aggregatedData.find(item => item.expected_ship_date === params.row.groupKey);
      if (matchingRow) {
        console.log("foi");
        const totalConfirmed = matchingRow[MissColumns.find(column => column.field === 'total').field];
        const cancelledPackages = calculateCancelledPackages(
          params.row[MissColumns.find(column => column.field === 'total').field],
          params.row[MissColumns.find(column => column.field === 'fdps').field]
        );
        return ((cancelledPackages / totalConfirmed)*100).toFixed(2) + '%';
      } else {
        return 'N/A';
      }
    } },
  ];

  const owner = [
    { field: 'groupKey', headerName: 'Group Key', width: 150,headerClassName: 'customHeader' },
    { field: 'ops_support', type: 'Ops Owner', headerName: 'Ops Owner', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'total2', type: 'Total Packages', headerName: 'Total Packages', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'share', type: 'Share Total Packages', headerName: 'Share Total Packages', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`, },
    { field: 'fdps2', type: 'FDPS', headerName: 'FDPS', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'miss2', type: 'Misses', headerName: 'Pickup Misses', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'misses_perc', type: 'Misses', headerName: 'Pickup Misses %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 93;
        
        return (
          <div
            style={{
              color: isPositive ? 'green' : 'red',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: >93.00% 
            </div>
          </div>
        );
      },
    
     },
    { field: 'seller_miss2', type: 'Seller Miss', headerName: 'Seller Miss', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'seller_miss_perc', type: 'Misses', headerName: 'Seller Miss %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 6;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 6.00% 
            </div>
          </div>
        );
      },
    
     },
    { field: 'carrier_miss2', type: 'Carrier Miss', headerName: 'Carrier Miss', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'carrier_miss_perc', type: 'Misses', headerName: 'Carrier Miss %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.00% 
            </div>
          </div>
        );
      },
    
     },
  ];

  const classification = [
    { field: 'groupKey', headerName: 'Group Key', width: 150,headerClassName: 'customHeader' },
    { field: 'classification', type: 'Classification', headerName: 'Classification', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'total2', type: 'Total Packages', headerName: 'Total Packages', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'share', type: 'Share Total Packages', headerName: 'Share Total Packages', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`, },
    { field: 'fdps2', type: 'FDPS', headerName: 'FDPS', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'miss2', type: 'Misses', headerName: 'Pickup Misses', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'misses_perc', type: 'Misses', headerName: 'Pickup Misses %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 93;
        
        return (
          <div
            style={{
              color: isPositive ? 'green' : 'red',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: >93.00% 
            </div>
          </div>
        );
      },
    
     },
    { field: 'seller_miss2', type: 'Seller Miss', headerName: 'Seller Miss', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader' },
    { field: 'seller_miss_perc', type: 'Misses', headerName: 'Seller Miss %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 6;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 6.00% 
            </div>
          </div>
        );
      },
    
     },
    { field: 'carrier_miss2', type: 'Carrier Miss', headerName: 'Carrier Miss', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader', },
    { field: 'carrier_miss_perc', type: 'Misses', headerName: 'Carrier Miss %', flex: 1, align: 'center', headerAlign: 'center',headerClassName: 'customHeader',

      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.00% 
            </div>
          </div>
        );
      },
    
     },
    
    
  ];

  const carrierMissColumns = [
    { field: 'groupKey', headerName: 'Group Key', headerClassName: 'customHeader',width: 150 },
    { field: 'amazon_comments', type: 'number', headerName: 'Reasons', flex: 1, align: 'center', headerClassName: 'customHeader',headerAlign: 'center' },
    { field: 'cancelledPackages', type: 'number', headerName: 'Packages', flex: 1, align: 'center', headerClassName: 'customHeader',headerAlign: 'center' },
    { field: 'cancelledPackagesPercentage', type: 'number', headerName: 'Packages %', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center', valueGetter: (params) => {
      const matchingRow = aggregatedData.find(item => item.expected_ship_date === params.row.groupKey);
      if (matchingRow) {
        console.log("foi");
        const totalConfirmed = matchingRow[MissColumns.find(column => column.field === 'total').field];
        const cancelledPackages = calculateCancelledPackages(
          params.row[MissColumns.find(column => column.field === 'total').field],
          params.row[MissColumns.find(column => column.field === 'fdps').field]
        );
        return ((cancelledPackages / totalConfirmed) * 100).toFixed(2) + '%';
      } else {
        return 'N/A';
      }
    } },
  ];

  const MissColumns = Array.from(new Set([
    { field: 'expected_ship_date', headerName: 'Expected Date',headerClassName: 'customHeader', flex: 1, },
    { field: 'total', type: 'number' , headerName: 'Confirmed Packages', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center'},
    { field: 'fdps', type: 'number' , headerName: 'Picked Packages', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center'},
    { field: 'pickup', type: 'number' , headerName: 'Pickup Misses', flex: 1, align: 'center', headerClassName: 'customHeader', headerAlign: 'center', },
    { field: 'fdps2', type: 'number' , headerName: 'FDPS %', flex: 1, align: 'center', headerClassName: 'customHeader', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value <= 93;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: >93.00%
            </div>
          </div>
        );
      },
    },  


 
    { field: 'seller_miss', type: 'number' , headerName: 'Seller Miss', flex: 1, align: 'center', headerClassName: 'customHeader',headerAlign: 'center', },
    { field: 'seller_miss_perc', type: 'number' , headerName: 'Seller Miss %', flex: 1, headerClassName: 'customHeader',align: 'center', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 6;
      
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 6.00%
            </div>
          </div>
        );
      },
    },  
    { field: 'carrier_miss', type: 'number' , headerName: 'Carrier Miss', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center', },
    { field: 'carrier_miss_perc', type: 'number' , headerName: 'Carrier Miss %', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center', 
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.00% 
            </div>
          </div>
        );
      },
    },  


    
  ]));



  const defectstable = [
    { field: 'date_value', headerName: 'Date',headerClassName: 'customHeader',width: 150 },
    { field: 'hdsc', type: 'number', headerName: 'HDSC', align: 'center', headerAlign: 'center',headerClassName: 'customHeader', flex: 1 },
    { field: 'defhdsc', type: 'number', headerName: 'HDSC %', align: 'center', headerAlign: 'center',headerClassName: 'customHeader', flex: 1 },
    { field: 'rpi', type: 'number', headerName: 'RPI', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center' },
    { field: 'defrpi', type: 'number', headerName: 'RPI %', flex: 1, align: 'center',headerClassName: 'customHeader', headerAlign: 'center' },
    { field: 'iss_inbounded', type: 'number', headerName: 'ISS Inbounded', align: 'center',headerClassName: 'customHeader', headerAlign: 'center',width: 150 },
    { field: 'defiss_inbounded', type: 'number', headerName: 'ISS Inbounded %',  align: 'center',headerClassName: 'customHeader', headerAlign: 'center',width: 150 },
    { field: 'iss_not_inbounded', type: 'number', headerName: 'ISS not Inbounded',  align: 'center',headerClassName: 'customHeader', headerAlign: 'center',width: 150 },
    { field: 'defiss_not_inbounded', type: 'number', headerName: 'ISS not Inbounded %',  align: 'center',headerClassName: 'customHeader', headerAlign: 'center',  width: 150 },
    { field: 'prep', type: 'number', headerName: 'Defect Prep', flex: 1, align: 'center', headerClassName: 'customHeader',headerAlign: 'center' },
    { field: 'defprep', type: 'number', headerName: 'Prep %', flex: 1, align: 'center', headerClassName: 'customHeader',headerAlign: 'center' },
    { field: 'shipped_units', type: 'number', headerName: 'Inbounded Units', align: 'center', headerClassName: 'customHeader',headerAlign: 'center',width: 150 },
  ];
  
  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '400px'
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center' }}>
        {groupTypes.map((type) => (
          <Button key={type} variant={groupByType === type ? 'contained' : 'outlined'} onClick={() => handleGroupByChange(type)}>
            {type}
          </Button>
        ))}
      </div>
      <Grid container spacing={12} direction="row">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            FDPS
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '30vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedData || []}
            columns={MissColumns}
            disableColumnSelector
            disableDensitySelector
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Defects
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedDefectData || []}
            columns={defectstable}
            disableColumnSelector
            disableDensitySelector
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Carrier Miss
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedDataById2AmazonComments.map(row => ({
              ...row,
              cancelledPackages: calculateCancelledPackages(row[MissColumns.find(column => column.field === 'total').field], row[MissColumns.find(column => column.field === 'fdps').field]),
            }))}
            columns={carrierMissColumns}
            disableColumnSelector
            disableDensitySelector
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Seller Miss
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedDataById2AmazonComments2.map(row => ({
              ...row,
              cancelledPackages: calculateCancelledPackages(row[MissColumns.find(column => column.field === 'total').field], row[MissColumns.find(column => column.field === 'fdps').field]),
            }))}
            columns={sellerMissColumns}
            disableColumnSelector
            disableDensitySelector
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Classification
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
 
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedDataByClassification}
            columns={classification}
            disableColumnSelector
            disableDensitySelector

            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 7 } },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Performance by Owner
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
 
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={aggregatedDataByOwner}
            columns={owner}
            disableColumnSelector
            disableDensitySelector

            disableSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
  };
  
  export default withAuthenticator(Summary);