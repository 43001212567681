import { listVFShipTos } from '../../../graphql/queries';
import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterNode1, setFilterNode1] = useState('');
  const [filterNode2, setFilterNode2] = useState('');
  const [filterShipDate, setFilterShipDate] = useState('');
  const [uniqueNodes, setUniqueNodes] = useState([]);
  const [uniqueShipDates, setUniqueShipDates] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const today = new Date();
      const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

      let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date(today.getTime() - 10 * 24 * 60 * 60 * 1000); // 3 days ago
        endDate = new Date(today.getTime()); // 2 days ago
      } else {
        startDate = new Date(today.getTime() - 10 * 24 * 60 * 60 * 1000); // 10 days ago
        endDate = new Date(today.getTime()); // Tomorrow
      }

      const startDateStr = startDate.toISOString().slice(0, 10);
      const endDateStr = endDate.toISOString().slice(0, 10);

      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFShipTos, {
            limit,
            nextToken,
            filter: {
              ship_date: {
                gt: startDateStr, // Filter for dates greater than startDate
                lt: endDateStr // Filter for dates less than endDate
              }
            }
          })
        );
        const fetchedItems = response.data.listVFShipTos.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFShipTos.nextToken;
      } while (nextToken);

      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px'
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const filteredData = useMemo(() => {
    return data.filter(item => {
      if (filterNode1 && item.node !== filterNode1) {
        return false;
      }
      if (filterNode2 && item.node !== filterNode2) {
        return false;
      }
      if (filterShipDate && item.ship_date !== filterShipDate) {
        return false;
      }
      return true;
    });
  }, [data, filterNode1, filterNode2, filterShipDate]);

  const filteredChartData = useMemo(() => {
    return data.reduce((acc, item) => {
      if (filterNode1 && item.node !== filterNode1 && item.node !== filterNode2) {
        return acc;
      }
      if (filterShipDate && item.ship_date !== filterShipDate) {
        return acc;
      }
      const { destination_state, units } = item;
      if (!acc[destination_state]) {
        acc[destination_state] = {
          destination_state,
          [filterNode1 || 'other']: 0,
          [filterNode2 || 'other']: 0
        };
      }
      if (item.node === filterNode1) {
        acc[destination_state][filterNode1] += units;
      } else if (item.node === filterNode2) {
        acc[destination_state][filterNode2] += units;
      } else {
        acc[destination_state]['other'] += units;
      }
      return acc;
    }, {});
  }, [data, filterNode1, filterNode2, filterShipDate]);

  const chartData = Object.values(filteredChartData).map(item => ({
    name: item.destination_state,
    [filterNode1 || 'other']: item[filterNode1 || 'other'],
    [filterNode2 || 'other']: item[filterNode2 || 'other']
  }));

  useEffect(() => {
    const uniqueNodes = [...new Set(data.map(item => item.node))];
    setUniqueNodes(uniqueNodes);
  }, [data]);

  useEffect(() => {
    const uniqueShipDates = [...new Set(data.map(item => item.ship_date))];
    setUniqueShipDates(uniqueShipDates);
  }, [data]);

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12} md={12}>
        </Grid>
        <Grid item lg={9} xs={9} md={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <InputLabel>Node 1</InputLabel>
            <Select
              value={filterNode1}
              onChange={(event) => setFilterNode1(event.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueNodes.map((node) => (
                <MenuItem key={node} value={node}>
                  {node}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Node 2</InputLabel>
            <Select
              value={filterNode2}
              onChange={(event) => setFilterNode2(event.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueNodes.map((node) => (
                <MenuItem key={node} value={node}>
                  {node}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Ship Date</InputLabel>
            <Select
              value={filterShipDate}
              onChange={(event) => setFilterShipDate(event.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueShipDates.map((shipDate) => (
                <MenuItem key={shipDate} value={shipDate}>
                  {shipDate}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} xs={3} md={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={12} xs={12} md={12}>
          <Typography
            sx={{
              marginTop: '2vh',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold'
            }}
          >
            Destination State Units
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
        </Grid>
      </Grid>

      {error ? (
        <div>
          <Typography variant="h6">Error fetching data:</Typography>
          <Typography>{error.message}</Typography>
        </div>
      ) : (
        <div style={{ width: '100%', height: '500px' }}>
          <ResponsiveContainer>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {filterNode1 && <Bar dataKey={filterNode1} fill="#8884d8" />}
              {filterNode2 && <Bar dataKey={filterNode2} fill="#82ca9d" />}
              {(!filterNode1 || !filterNode2) && <Bar dataKey="other" fill="#ffc658" />}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {error ? (
        <div>
          <Typography variant="h6">Error fetching data:</Typography>
          <Typography>{error.message}</Typography>
        </div>
      ) : (
        <div>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto'
            }}
            rows={filteredData}
            columns={[
              { field: 'node', headerName: 'Node', flex: 1 },
              { field: 'ship_date', headerName: 'Ship Date', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'origin_state', headerName: 'Origin', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'destination_state', headerName: 'Destination', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'units', headerName: 'Units', flex: 1, align: 'center', headerAlign: 'center' }
            ]}
            components={{
              Toolbar: CustomToolbar
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { pageSize: 100 },
              sortModel: [{ field: 'deviation', sort: 'asc' }]
            }}
            onFilterModelChange={(model) => {
              setFilterNode1(model.items.find(item => item.columnField === 'node')?.value || '');
              setFilterNode2(model.items.find(item => item.columnField === 'node')?.value || '');
              setFilterShipDate(model.items.find(item => item.columnField === 'ship_date')?.value || '');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PostList;