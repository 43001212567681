import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar, Tooltip } from '@mui/material';
import {useGridApiContext } from '@mui/x-data-grid';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';


function SelectEditInputCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
      console.log(event.target.value)
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Reason Seller</InputLabel>
        <Select
          value={value}
          label='Reason Seller'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>NULL</MenuItem>
          <MenuItem value= 'SCHEDULING_ERROR' >Scheduling Error</MenuItem>
          <MenuItem value='INVENTORY_MISMATCH`'>Inventory Mismatch</MenuItem>
          <MenuItem value='LOGIN_ISSUES'>Login Issues</MenuItem>
          <MenuItem value='CAPACITY_BREACH'>Capacity Breach</MenuItem>
          <MenuItem value='CARRIER_ISSUE'>Carrier Issue</MenuItem>
          <MenuItem value='SELLER_OPERATIONAL_ISSUE'>Seller Operational Issue</MenuItem>
          <MenuItem value='SELLER_UNRESPONSIVE'>Seller Unresponsive</MenuItem>
          <MenuItem value='WRONG_ORIENTATION_AMAZON'>Wrong Orientation Amazon Side</MenuItem>
          <MenuItem value='AMAZON_TECHNICAL_ISSUES'>Amazon Technical Issue</MenuItem>
          <MenuItem value='FAILURES_EQUIPMENT'>Failures in other equipment</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  SelectEditInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

function SelectEditMissTypeCell(props) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
  
    const handleChange = async (event) => {
      await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
      apiRef.current.stopCellEditMode({ id, field });
    };
  
    return (
      <FormControl fullWidth>
        <InputLabel size='small'>Miss Type</InputLabel>
        <Select
          value={value}
          label='Miss Type'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>NULL</MenuItem>
          <MenuItem value='SELLER_MISS'>Seller Miss</MenuItem>
          <MenuItem value='LH_REPORT_ERROR'>LH Report Error</MenuItem>
          <MenuItem value='CARRIER_MISS'>Carrier Miss</MenuItem>
        </Select>
      </FormControl>
    );
    
  }
  const renderSelectEditMissTypeCell = (params) => {
    return <SelectEditMissTypeCell {...params} />;
  }
  SelectEditMissTypeCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };



function SelectEditSubInputCell(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    const newValue = event.target.value;
    console.log('Selected Reason Seller:', newValue);
    await apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
  };


  return (
    <FormControl fullWidth>
      <InputLabel size='small'>Sub Reason</InputLabel>
      <Select
          value={value}
          label='Reason Seller'
          onChange={handleChange}
          size="small"
          sx={{ height: 1 }}
        >
          <MenuItem value=''>NULL</MenuItem>
          <MenuItem value='SCHEDULED_FOR_PICKUP_ON_THE_WRONG_DATE'>Scheduled for pickup on the wrong date</MenuItem>
          <MenuItem value='INCORRECT_ADDRESS'>Incorrect Address</MenuItem>
          <MenuItem value='FORGOT_THE_APPOINTMENT_DATE'>Forgot the appointment date</MenuItem>
          <MenuItem value='OUT_OF_STOCK'>Out of stock</MenuItem>
          <MenuItem value='INVENTORY_DAMAGED'>Inventory Damaged</MenuItem>
          <MenuItem value='STOCK_IN_A_DIFFERENT_FACILITY'>Stock in a different facility</MenuItem>
          <MenuItem value='LABEL_PRINTING_DIDNT_WORK'>Label printing didn't work</MenuItem>
          <MenuItem value='PRINTER_NOT_CONFIGURED'>Printer not configured</MenuItem>
          <MenuItem value='LACK_OF_SUPPLIES_(LABELS)'>Lack of Supplies (Labels)</MenuItem>
          <MenuItem value='SELLER_DID_NOT_FINISH_SHIPMENT_PREPARATION'>Seller did not finish shipment preparation</MenuItem>
          <MenuItem value='SELLER_UNAVAILABLE'>Seller Unavailable</MenuItem>
          <MenuItem value='STOPPED_SHIPPING_THE_STOCK_(NOT_CANCELLED)'>Stopped shipping the stock (Not Cancelled)</MenuItem>
          <MenuItem value='LACK_OF_EMPLOYEES'>Lack of employees</MenuItem>
          <MenuItem value='REGISTERED_THE_DIMENSIONS_OF_THE_BOXES_INCORRECTLY'>Registered the dimensions of the boxes incorrectly</MenuItem>
          <MenuItem value='OPENING_HOURS_OUTSIDE_THE_PERIOD_FROM_9_A.M._TO_6_P.M.'>Opening hours outside the period from 9 a.m. to 6 p.m.</MenuItem>
          <MenuItem value='CARRIER_NO_SHOW'>Carrier No Show</MenuItem>
          <MenuItem value='FULL_VEHICLE'>Full Vehicle</MenuItem>
          <MenuItem value='PICK-UP_TOOK_PLACE_OUTSIDE_PROGRAM_HOURS_(9AM-6PM)'>Pick-up took place outside program hours (9am-6pm)</MenuItem>
          <MenuItem value='APP_FAILURE'>App Failure</MenuItem>
          <MenuItem value='BROKEN_VEHICLE'>Broken Vehicle</MenuItem>
          <MenuItem value='BY_SESU'>Wrong Orientation By SeSu</MenuItem>
          <MenuItem value='BY_OPS_OWNER'>Wrong Orientation By Ops Owner</MenuItem>
          <MenuItem value='BY_SELLER_CENTRAL'>Wrong Orientation By Seller Central</MenuItem>
          <MenuItem value='SYSTEM_ERROR'>System Error</MenuItem>
        </Select>
    </FormControl>
  );
}


const renderSelectEditSubInputCell = (params) => {
  return <SelectEditSubInputCell {...params} params={params} />;
};


  SelectEditSubInputCell.propTypes = {
    field: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.any,
  };

// ------------------------------------------ TABLE FOR ADMINS 
export const tableColumnsAdmin = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},

{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "missed_units",
      headerName: "Missed Units",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  renderEditCell: renderSelectEditMissTypeCell,
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];

// ------------------------------------------ TABLE FOR OPS 
export const tableColumnsOps = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},

{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "missed_units",
      headerName: "Missed Units",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  renderEditCell: renderSelectEditMissTypeCell,
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];


      // ------------------------------------------ TABLE ONLY FOR VIEW 
export const tableColumnsView = [
  {
    field: "expected_ship_date",
    headerName: "Date",
    headerClassName: 'customHeader',
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
},

{
  field: "ops_support",
  headerName: "Ops Support",
  headerClassName: 'customHeader',
  width: '100',
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const opsOwner = params?.value;

    if (opsOwner) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
        </div>
      );
    }

    return null; 
  },
},
  {
    field: "seller_name",
    headerName: "Seller Name",
    headerClassName: 'customHeader',
    width: '250',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    },

    {
      field: "merchant_id",
      headerName: "Merchant ID",
      headerClassName: 'customHeader',
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "total",
      headerName: "Boxes Shipped",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "fdps",
      headerName: "FDPS",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: "missed_units",
      headerName: "Missed Units",
      headerClassName: 'customHeader',
      type: 'number',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
    },
{
  field: "miss_type",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  },
{
  field: "reason",
  headerName: "Reason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "amazon_comments",
  headerName: "Subreason Carrier",
  headerClassName: 'customHeader',
  width: '250',
  editable: false,
  headerAlign: 'center',
},
{
  field: "miss_type_seller",
  headerName: "Miss Type",
  headerClassName: 'customHeader',
  width: '100',
  editable: true,
  headerAlign: 'center',
  align: 'center',
  renderEditCell: renderSelectEditMissTypeCell,
  },

{
  field: "reason_seller",
  headerName: "Reason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditInputCell,
},
{
  field: "subreason_seller",
  headerName: "Subreason Seller",
  headerClassName: 'customHeader',
  width: '250',
  editable: true,
  headerAlign: 'center',
  renderEditCell: renderSelectEditSubInputCell,
},
{
  field: "createdAt",
  headerName: "Last Update",
  width: '150',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  headerClassName: 'customHeader',
  valueFormatter: params => {
      const date = new Date(params?.value);
      const dateString = date.toLocaleDateString();
      const timeString = date.toLocaleTimeString();
      return `${dateString} ${timeString}`;
    },
},  
{
  field: "edited_by",
  headerName: "Edited By",
  headerClassName: 'customHeader',
  width: '150',
  headerAlign: 'center',
},
{
  field: "remove_from_pm",
  headerName: "Remove from PM",
  headerClassName: 'customHeader',
  type: 'boolean',
  width: '100',
  editable: false,
  headerAlign: 'center',
  align: 'center',
},
{
  field: "self_report_survey",
  headerName: "Self-Report",
  headerClassName: 'customHeader',
  width: '100',
  type: 'boolean',
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {params.value ? <CheckCircleIcon style={{ color: 'green' }} /> : null}
      </div>
    );
  },
},      
];