import React, { useState, useEffect, useCallback, useMemo } from "react";
import { listPCPPreWBRS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { Typography, Button, Grid, Box } from '@mui/material';
import awsconfig from '../../../aws-exports';
import './summary.css';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getWeekNumber = (date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
  };
  const getCurrentWeek = () => {
    const now = new Date();
    if (now.getDay() === 0 || now.getDay() === 1) { 
      return getWeekNumber(now) - 1;
    } else {
      return getWeekNumber(now);
    }
  };


  const fetchDataReasons = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-11-01');
      const today = new Date();
      const tenDaysAgo = new Date(today);
      tenDaysAgo.setDate(today.getDate() - 15);

      do {
        const response = await API.graphql(
          graphqlOperation(listPCPPreWBRS, {
            limit: 20000,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: tenDaysAgo.toISOString().slice(0, 10),
              },
            },
          })
        );
        const fetchedItems = response.data.listPCPPreWBRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);

      // Add 'week' column based on 'expected_ship_date'
      const itemsWithWeek = items.map(item => ({
        ...item,
        week: getWeekNumber(new Date(item.expected_ship_date))
      }));

      setData(itemsWithWeek);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataReasons();
  }, []);

  const aggregatedDataByWeekAndReason = useMemo(() => {
    const currentWeek = getCurrentWeek();
    const aggregated = {};
    const totalByWeekAndMissType = {};
  
    for (const item of data) {
      if (item.week !== currentWeek) continue;
      const week = item.week;
      const miss_type = item.miss_type || 'NULL';
      const reason = item.reason || 'Unknown';
      const sub_reason = item.amazon_comments || 'Unknown';
      const total = Number(item.total) || 0;
      const fdps = Number(item.fdps) || 0;
      const units = total - fdps;
  
      const totalId = `${week}_${miss_type}_${reason}_Total`;
      const sub_reasonId = `${week}_${miss_type}_${reason}_${sub_reason}`;
  
      if (!aggregated[totalId]) {
        aggregated[totalId] = {
          id: totalId,
          week,
          reason,
          sub_reason: 'Total',
          miss_type,
          units: 0,
          isTotal: true,
        };
      }
      aggregated[totalId].units += units;
  
      if (!aggregated[sub_reasonId]) {
        aggregated[sub_reasonId] = {
          id: sub_reasonId,
          week,
          reason,
          sub_reason,
          miss_type,
          units: 0,
          isTotal: false,
        };
      }
      aggregated[sub_reasonId].units += units;
  
      if (!totalByWeekAndMissType[week]) {
        totalByWeekAndMissType[week] = {};
      }
      if (!totalByWeekAndMissType[week][miss_type]) {
        totalByWeekAndMissType[week][miss_type] = 0;
      }
      totalByWeekAndMissType[week][miss_type] += units;
    }
  
    const result = Object.values(aggregated).map(item => {
      const totalForWeekAndMissType = totalByWeekAndMissType[item.week][item.miss_type];
      return {
        ...item,
        units_sum: totalForWeekAndMissType,
        percentage: totalForWeekAndMissType > 0 
          ? Number(((item.units / totalForWeekAndMissType) * 100).toFixed(2))
          : 0
      };
    });
  
    result.sort((a, b) => {
      if (a.reason !== b.reason) {
        return a.reason.localeCompare(b.reason);
      }
      if (a.isTotal !== b.isTotal) {
        return a.isTotal ? -1 : 1;
      }
      return b.units - a.units;
    });
  
    result.forEach((item, index) => {
      item.order = index + 1;
    });
  
    return result;
  }, [data]);

  const filterData = (data, missTypeFilter) => {
    return data.filter(item => item.miss_type === missTypeFilter);
  };

  const [filteredDataLH, setFilteredDataLH] = useState([]);
  const [filteredDataMXSD, setFilteredDataMXSD] = useState([]);

  useEffect(() => {
    setFilteredDataLH(filterData(aggregatedDataByWeekAndReason, 'Seller Miss'));
    setFilteredDataMXSD(filterData(aggregatedDataByWeekAndReason, 'Carrier Miss'));
  }, [aggregatedDataByWeekAndReason]);

  const handleRefreshData = async () => {
    await fetchDataReasons();
  };



  const reasons = [
    { field: 'week', headerName: 'Week',width: 50 },
    { field: 'reason', type: 'number', headerName: 'Reason', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'sub_reason', type: 'number', headerName: 'sub_reason', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'units', type: 'number', headerName: 'Units', align: 'center', headerAlign: 'center', width: 50 },
    { field: 'percentage', type: 'number', headerName: '%', align: 'center', headerAlign: 'center', width: 70,
      valueFormatter: (params) => {
        const value = params.value; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      },

    }
  ];
  const groupDataByReason = (data) => {
    return data.reduce((acc, curr) => {
      if (!acc[curr.reason]) {
        acc[curr.reason] = [];
      }
      acc[curr.reason].push(curr);
      return acc;
    }, {});
  };

  const FilteredDataGrid = ({ data, title, currentWeekNumber }) => {
    const filteredData = useMemo(() => {
      return data.filter(item => item.week.toString() === currentWeekNumber.toString());
    }, [data, currentWeekNumber]);
  
    const groupedAndSortedData = useMemo(() => {
      const grouped = groupDataByReason(data);
      
      // Calcular o total de units para cada reason
      const reasonTotals = Object.entries(grouped).reduce((acc, [reason, group]) => {
        acc[reason] = group.reduce((sum, item) => sum + (item.units || 0), 0);
        return acc;
      }, {});
  
      // Ordenar as reasons pelo total de units
      const sortedReasons = Object.entries(reasonTotals)
        .sort(([, a], [, b]) => b - a)
        .map(([reason]) => reason);
  
      // Criar um novo objeto com as reasons ordenadas
      const sortedGrouped = {};
      sortedReasons.forEach(reason => {
        sortedGrouped[reason] = grouped[reason].sort((a, b) => {
          if (a.sub_reason === 'Total') return -1;
          if (b.sub_reason === 'Total') return 1;
          return b.units - a.units;
        });
      });
  
      return sortedGrouped;
    }, [data]);
  
    return (
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <div style={{
          marginTop: '1vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          height: '40vh',
          overflow: 'auto',
        }}>
          <div style={{ display: 'flex', fontWeight: 'bold', borderBottom: '1px solid black' }}>
            {reasons.map((column) => (
              <div key={column.field} style={{
                flex: column.flex || 'auto',
                width: column.width,
                padding: '8px',
                textAlign: column.align || 'left'
              }}>
                {column.headerName}
              </div>
            ))}
          </div>
          {Object.entries(groupedAndSortedData).map(([reason, group]) => (
            <React.Fragment key={reason}>
              {group.map((row, index) => (
                <CustomRow
                  key={row.id}
                  row={row}
                  isLastInGroup={index === group.length - 1}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </Box>
    );
  };

  const CustomRow = React.memo(({ row, reasonGroup, isLastInGroup }) => {
    return (
      <div style={{
        borderBottom: isLastInGroup ? '2px solid black' : 'none',
        backgroundColor: row.isTotal ? '#f0f0f0' : 'white'
      }}>
        <div style={{ display: 'flex' }}>
          {reasons.map((column) => (
            <div key={column.field} style={{
              flex: column.flex || 'auto',
              width: column.width,
              padding: '8px',
              textAlign: column.align || 'left',
              fontWeight: row.isTotal ? 'bold' : 'normal'
            }}>
              {row[column.field]}
            </div>
          ))}
        </div>
      </div>
    );
  });

  return (
    <div className='datatable' style={{ height: '100%', width: '100%', padding: '20px' }}>
      <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between" marginBottom={4}>
        <Grid item lg={3} xs={3} md={3}>
          {/* You can add a title or other content here if needed */}
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefreshData}
          >
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

      <FilteredDataGrid 
          data={filteredDataLH} 
          title="Seller Miss" 
          currentWeekNumber={getCurrentWeek()}
        />
        <FilteredDataGrid 
          data={filteredDataMXSD} 
          title="Carrier Miss" 
          currentWeekNumber={getCurrentWeek()}
        />
  
        
      </Box>
    </div>
  );
};

export default Summary;