/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPCPOwnerPerformance = /* GraphQL */ `
  mutation CreatePCPOwnerPerformance(
    $input: CreatePCPOwnerPerformanceInput!
    $condition: ModelPCPOwnerPerformanceConditionInput
  ) {
    createPCPOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_support
      seller_miss
      carrier_miss
      miss
      total
      fdps
      total_day
      total_week
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePCPOwnerPerformance = /* GraphQL */ `
  mutation UpdatePCPOwnerPerformance(
    $input: UpdatePCPOwnerPerformanceInput!
    $condition: ModelPCPOwnerPerformanceConditionInput
  ) {
    updatePCPOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_support
      seller_miss
      carrier_miss
      miss
      total
      fdps
      total_day
      total_week
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePCPOwnerPerformance = /* GraphQL */ `
  mutation DeletePCPOwnerPerformance(
    $input: DeletePCPOwnerPerformanceInput!
    $condition: ModelPCPOwnerPerformanceConditionInput
  ) {
    deletePCPOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_support
      seller_miss
      carrier_miss
      miss
      total
      fdps
      total_day
      total_week
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPCPClassificationPerformance = /* GraphQL */ `
  mutation CreatePCPClassificationPerformance(
    $input: CreatePCPClassificationPerformanceInput!
    $condition: ModelPCPClassificationPerformanceConditionInput
  ) {
    createPCPClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      seller_miss
      carrier_miss
      miss
      total
      fdps
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePCPClassificationPerformance = /* GraphQL */ `
  mutation UpdatePCPClassificationPerformance(
    $input: UpdatePCPClassificationPerformanceInput!
    $condition: ModelPCPClassificationPerformanceConditionInput
  ) {
    updatePCPClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      seller_miss
      carrier_miss
      miss
      total
      fdps
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePCPClassificationPerformance = /* GraphQL */ `
  mutation DeletePCPClassificationPerformance(
    $input: DeletePCPClassificationPerformanceInput!
    $condition: ModelPCPClassificationPerformanceConditionInput
  ) {
    deletePCPClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      seller_miss
      carrier_miss
      miss
      total
      fdps
      expected_ship_date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFOwnerPerformance = /* GraphQL */ `
  mutation CreateSFOwnerPerformance(
    $input: CreateSFOwnerPerformanceInput!
    $condition: ModelSFOwnerPerformanceConditionInput
  ) {
    createSFOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_owner
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSFOwnerPerformance = /* GraphQL */ `
  mutation UpdateSFOwnerPerformance(
    $input: UpdateSFOwnerPerformanceInput!
    $condition: ModelSFOwnerPerformanceConditionInput
  ) {
    updateSFOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_owner
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSFOwnerPerformance = /* GraphQL */ `
  mutation DeleteSFOwnerPerformance(
    $input: DeleteSFOwnerPerformanceInput!
    $condition: ModelSFOwnerPerformanceConditionInput
  ) {
    deleteSFOwnerPerformance(input: $input, condition: $condition) {
      id
      ops_owner
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFClassificationPerformance = /* GraphQL */ `
  mutation CreateSFClassificationPerformance(
    $input: CreateSFClassificationPerformanceInput!
    $condition: ModelSFClassificationPerformanceConditionInput
  ) {
    createSFClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSFClassificationPerformance = /* GraphQL */ `
  mutation UpdateSFClassificationPerformance(
    $input: UpdateSFClassificationPerformanceInput!
    $condition: ModelSFClassificationPerformanceConditionInput
  ) {
    updateSFClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSFClassificationPerformance = /* GraphQL */ `
  mutation DeleteSFClassificationPerformance(
    $input: DeleteSFClassificationPerformanceInput!
    $condition: ModelSFClassificationPerformanceConditionInput
  ) {
    deleteSFClassificationPerformance(input: $input, condition: $condition) {
      id
      classification
      mxsd
      lh_by_seller
      week
      scr
      totalunits
      year
      mxsd_perc
      scr_perc
      lh_perc
      share_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFCapacity = /* GraphQL */ `
  mutation CreateSFCapacity(
    $input: CreateSFCapacityInput!
    $condition: ModelSFCapacityConditionInput
  ) {
    createSFCapacity(input: $input, condition: $condition) {
      id
      current_capacity
      comparison_capacity
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSFCapacity = /* GraphQL */ `
  mutation UpdateSFCapacity(
    $input: UpdateSFCapacityInput!
    $condition: ModelSFCapacityConditionInput
  ) {
    updateSFCapacity(input: $input, condition: $condition) {
      id
      current_capacity
      comparison_capacity
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSFCapacity = /* GraphQL */ `
  mutation DeleteSFCapacity(
    $input: DeleteSFCapacityInput!
    $condition: ModelSFCapacityConditionInput
  ) {
    deleteSFCapacity(input: $input, condition: $condition) {
      id
      current_capacity
      comparison_capacity
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEasyShipPendingOrders = /* GraphQL */ `
  mutation CreateEasyShipPendingOrders(
    $input: CreateEasyShipPendingOrdersInput!
    $condition: ModelEasyShipPendingOrdersConditionInput
  ) {
    createEasyShipPendingOrders(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEasyShipPendingOrders = /* GraphQL */ `
  mutation UpdateEasyShipPendingOrders(
    $input: UpdateEasyShipPendingOrdersInput!
    $condition: ModelEasyShipPendingOrdersConditionInput
  ) {
    updateEasyShipPendingOrders(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEasyShipPendingOrders = /* GraphQL */ `
  mutation DeleteEasyShipPendingOrders(
    $input: DeleteEasyShipPendingOrdersInput!
    $condition: ModelEasyShipPendingOrdersConditionInput
  ) {
    deleteEasyShipPendingOrders(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSummaryDropOff = /* GraphQL */ `
  mutation CreateSummaryDropOff(
    $input: CreateSummaryDropOffInput!
    $condition: ModelSummaryDropOffConditionInput
  ) {
    createSummaryDropOff(input: $input, condition: $condition) {
      id
      evaluation
      miss_week
      miss_year
      order_count_method
      order_count_evaluation
      general_lsr_f
      mexsd_f
      late_drop
      lsr_re_f
      mexsd_re_f
      late_drop_re
      cr
      new_classification
      owner
      cr_not_scheduled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryDropOff = /* GraphQL */ `
  mutation UpdateSummaryDropOff(
    $input: UpdateSummaryDropOffInput!
    $condition: ModelSummaryDropOffConditionInput
  ) {
    updateSummaryDropOff(input: $input, condition: $condition) {
      id
      evaluation
      miss_week
      miss_year
      order_count_method
      order_count_evaluation
      general_lsr_f
      mexsd_f
      late_drop
      lsr_re_f
      mexsd_re_f
      late_drop_re
      cr
      new_classification
      owner
      cr_not_scheduled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryDropOff = /* GraphQL */ `
  mutation DeleteSummaryDropOff(
    $input: DeleteSummaryDropOffInput!
    $condition: ModelSummaryDropOffConditionInput
  ) {
    deleteSummaryDropOff(input: $input, condition: $condition) {
      id
      evaluation
      miss_week
      miss_year
      order_count_method
      order_count_evaluation
      general_lsr_f
      mexsd_f
      late_drop
      lsr_re_f
      mexsd_re_f
      late_drop_re
      cr
      new_classification
      owner
      cr_not_scheduled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFBacklog = /* GraphQL */ `
  mutation CreateSFBacklog(
    $input: CreateSFBacklogInput!
    $condition: ModelSFBacklogConditionInput
  ) {
    createSFBacklog(input: $input, condition: $condition) {
      id
      node
      seller_name
      ops_owner
      backup_owner
      cpt_date
      pending_shipments
      capacity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSFBacklog = /* GraphQL */ `
  mutation UpdateSFBacklog(
    $input: UpdateSFBacklogInput!
    $condition: ModelSFBacklogConditionInput
  ) {
    updateSFBacklog(input: $input, condition: $condition) {
      id
      node
      seller_name
      ops_owner
      backup_owner
      cpt_date
      pending_shipments
      capacity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSFBacklog = /* GraphQL */ `
  mutation DeleteSFBacklog(
    $input: DeleteSFBacklogInput!
    $condition: ModelSFBacklogConditionInput
  ) {
    deleteSFBacklog(input: $input, condition: $condition) {
      id
      node
      seller_name
      ops_owner
      backup_owner
      cpt_date
      pending_shipments
      capacity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVFInventory = /* GraphQL */ `
  mutation CreateVFInventory(
    $input: CreateVFInventoryInput!
    $condition: ModelVFInventoryConditionInput
  ) {
    createVFInventory(input: $input, condition: $condition) {
      id
      snapshot_date
      warehouse_id
      gas_tank_vol
      cuft
      inventory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVFInventory = /* GraphQL */ `
  mutation UpdateVFInventory(
    $input: UpdateVFInventoryInput!
    $condition: ModelVFInventoryConditionInput
  ) {
    updateVFInventory(input: $input, condition: $condition) {
      id
      snapshot_date
      warehouse_id
      gas_tank_vol
      cuft
      inventory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVFInventory = /* GraphQL */ `
  mutation DeleteVFInventory(
    $input: DeleteVFInventoryInput!
    $condition: ModelVFInventoryConditionInput
  ) {
    deleteVFInventory(input: $input, condition: $condition) {
      id
      snapshot_date
      warehouse_id
      gas_tank_vol
      cuft
      inventory
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVFShipTo = /* GraphQL */ `
  mutation CreateVFShipTo(
    $input: CreateVFShipToInput!
    $condition: ModelVFShipToConditionInput
  ) {
    createVFShipTo(input: $input, condition: $condition) {
      id
      node
      destination_state
      origin_state
      ship_date
      units
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVFShipTo = /* GraphQL */ `
  mutation UpdateVFShipTo(
    $input: UpdateVFShipToInput!
    $condition: ModelVFShipToConditionInput
  ) {
    updateVFShipTo(input: $input, condition: $condition) {
      id
      node
      destination_state
      origin_state
      ship_date
      units
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVFShipTo = /* GraphQL */ `
  mutation DeleteVFShipTo(
    $input: DeleteVFShipToInput!
    $condition: ModelVFShipToConditionInput
  ) {
    deleteVFShipTo(input: $input, condition: $condition) {
      id
      node
      destination_state
      origin_state
      ship_date
      units
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBrLaunchQualtrics = /* GraphQL */ `
  mutation CreateBrLaunchQualtrics(
    $input: CreateBrLaunchQualtricsInput!
    $condition: ModelBrLaunchQualtricsConditionInput
  ) {
    createBrLaunchQualtrics(input: $input, condition: $condition) {
      id
      node
      name
      start_date
      end_date
      vendor_type
      finished
      program
      erp
      printer
      stock
      scanner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBrLaunchQualtrics = /* GraphQL */ `
  mutation UpdateBrLaunchQualtrics(
    $input: UpdateBrLaunchQualtricsInput!
    $condition: ModelBrLaunchQualtricsConditionInput
  ) {
    updateBrLaunchQualtrics(input: $input, condition: $condition) {
      id
      node
      name
      start_date
      end_date
      vendor_type
      finished
      program
      erp
      printer
      stock
      scanner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBrLaunchQualtrics = /* GraphQL */ `
  mutation DeleteBrLaunchQualtrics(
    $input: DeleteBrLaunchQualtricsInput!
    $condition: ModelBrLaunchQualtricsConditionInput
  ) {
    deleteBrLaunchQualtrics(input: $input, condition: $condition) {
      id
      node
      name
      start_date
      end_date
      vendor_type
      finished
      program
      erp
      printer
      stock
      scanner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSEStrackSRS = /* GraphQL */ `
  mutation CreateSEStrackSRS(
    $input: CreateSEStrackSRSInput!
    $condition: ModelSEStrackSRSConditionInput
  ) {
    createSEStrackSRS(input: $input, condition: $condition) {
      id
      owner
      seller_id
      units
      seller_name
      miss_date
      program
      recipient
      event_type
      miss_type
      srs_url
      send_datetime
      delivery_datetime
      open_datetime
      srs_click_datetime
      qna_click_datetime
      message_id
      delay_type
      recipient_delay
      recipient_bounce
      bounce_subtype
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSEStrackSRS = /* GraphQL */ `
  mutation UpdateSEStrackSRS(
    $input: UpdateSEStrackSRSInput!
    $condition: ModelSEStrackSRSConditionInput
  ) {
    updateSEStrackSRS(input: $input, condition: $condition) {
      id
      owner
      seller_id
      units
      seller_name
      miss_date
      program
      recipient
      event_type
      miss_type
      srs_url
      send_datetime
      delivery_datetime
      open_datetime
      srs_click_datetime
      qna_click_datetime
      message_id
      delay_type
      recipient_delay
      recipient_bounce
      bounce_subtype
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSEStrackSRS = /* GraphQL */ `
  mutation DeleteSEStrackSRS(
    $input: DeleteSEStrackSRSInput!
    $condition: ModelSEStrackSRSConditionInput
  ) {
    deleteSEStrackSRS(input: $input, condition: $condition) {
      id
      owner
      seller_id
      units
      seller_name
      miss_date
      program
      recipient
      event_type
      miss_type
      srs_url
      send_datetime
      delivery_datetime
      open_datetime
      srs_click_datetime
      qna_click_datetime
      message_id
      delay_type
      recipient_delay
      recipient_bounce
      bounce_subtype
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBrEsPickupFrequency = /* GraphQL */ `
  mutation CreateBrEsPickupFrequency(
    $input: CreateBrEsPickupFrequencyInput!
    $condition: ModelBrEsPickupFrequencyConditionInput
  ) {
    createBrEsPickupFrequency(input: $input, condition: $condition) {
      id
      city_name
      uf
      frequency
      base
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBrEsPickupFrequency = /* GraphQL */ `
  mutation UpdateBrEsPickupFrequency(
    $input: UpdateBrEsPickupFrequencyInput!
    $condition: ModelBrEsPickupFrequencyConditionInput
  ) {
    updateBrEsPickupFrequency(input: $input, condition: $condition) {
      id
      city_name
      uf
      frequency
      base
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBrEsPickupFrequency = /* GraphQL */ `
  mutation DeleteBrEsPickupFrequency(
    $input: DeleteBrEsPickupFrequencyInput!
    $condition: ModelBrEsPickupFrequencyConditionInput
  ) {
    deleteBrEsPickupFrequency(input: $input, condition: $condition) {
      id
      city_name
      uf
      frequency
      base
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBrEsPickupAddress = /* GraphQL */ `
  mutation CreateBrEsPickupAddress(
    $input: CreateBrEsPickupAddressInput!
    $condition: ModelBrEsPickupAddressConditionInput
  ) {
    createBrEsPickupAddress(input: $input, condition: $condition) {
      id
      mcid
      merchant_name
      default_shipping_zipcode
      city
      uf
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBrEsPickupAddress = /* GraphQL */ `
  mutation UpdateBrEsPickupAddress(
    $input: UpdateBrEsPickupAddressInput!
    $condition: ModelBrEsPickupAddressConditionInput
  ) {
    updateBrEsPickupAddress(input: $input, condition: $condition) {
      id
      mcid
      merchant_name
      default_shipping_zipcode
      city
      uf
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBrEsPickupAddress = /* GraphQL */ `
  mutation DeleteBrEsPickupAddress(
    $input: DeleteBrEsPickupAddressInput!
    $condition: ModelBrEsPickupAddressConditionInput
  ) {
    deleteBrEsPickupAddress(input: $input, condition: $condition) {
      id
      mcid
      merchant_name
      default_shipping_zipcode
      city
      uf
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVendorFlexTRB = /* GraphQL */ `
  mutation CreateVendorFlexTRB(
    $input: CreateVendorFlexTRBInput!
    $condition: ModelVendorFlexTRBConditionInput
  ) {
    createVendorFlexTRB(input: $input, condition: $condition) {
      id
      begin_instant
      warehouse_id
      capacity_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVendorFlexTRB = /* GraphQL */ `
  mutation UpdateVendorFlexTRB(
    $input: UpdateVendorFlexTRBInput!
    $condition: ModelVendorFlexTRBConditionInput
  ) {
    updateVendorFlexTRB(input: $input, condition: $condition) {
      id
      begin_instant
      warehouse_id
      capacity_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVendorFlexTRB = /* GraphQL */ `
  mutation DeleteVendorFlexTRB(
    $input: DeleteVendorFlexTRBInput!
    $condition: ModelVendorFlexTRBConditionInput
  ) {
    deleteVendorFlexTRB(input: $input, condition: $condition) {
      id
      begin_instant
      warehouse_id
      capacity_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createFbaSpoShipmentLevel = /* GraphQL */ `
  mutation CreateFbaSpoShipmentLevel(
    $input: CreateFbaSpoShipmentLevelInput!
    $condition: ModelFbaSpoShipmentLevelConditionInput
  ) {
    createFbaSpoShipmentLevel(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      seller_status
      is_fba_eligible
      fba_cohort
      program
      fba_launch_channel
      pf
      is_fba_managed
      alias_account_manager
      management_status
      arc_status
      po_id
      po_status
      shipment_id
      merchant_shipment_id
      merch_carrier_request_id
      shipment_create_date
      shipment_update_date
      ship_method
      has_invoice
      invoice_issued_date
      is_ship_valid_final
      carrier_received_manifest
      virtual_manifest_received_date
      manifest_status
      carrier
      ship_type
      expected_pickup_date
      was_ship_pickedup
      real_pickup_date
      was_ship_delivered_fc
      real_fc_receive_date_tex
      real_fc_receive_date_fc
      issue_with_pickup
      ship_undeliverable_date
      no_show_confirmed_date
      was_no_show
      packages_onhold
      fc_estimated_arrival_date
      fc_estimated_arrival_date_adjusted
      postal_code
      state
      city
      district
      commercial_locality
      address_status
      clear_address1_ft_flag
      destination_warehouse_id
      is_eligible_for_pcp
      expected_box_qty
      received_box_qty
      pickedup_box_qty
      uf
      coverage
      pcp_launched
      fba_launched
      tax_regime
      shipment_order
      delivery_date_adjusted
      edd_adjusted
      last_received_date_local
      last_event
      real_sla_transit_time
      arrived_fc_ontime
      expected_units
      received_units
      shipment_expected_gms
      shipment_received_gms
      untitledfield
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateFbaSpoShipmentLevel = /* GraphQL */ `
  mutation UpdateFbaSpoShipmentLevel(
    $input: UpdateFbaSpoShipmentLevelInput!
    $condition: ModelFbaSpoShipmentLevelConditionInput
  ) {
    updateFbaSpoShipmentLevel(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      seller_status
      is_fba_eligible
      fba_cohort
      program
      fba_launch_channel
      pf
      is_fba_managed
      alias_account_manager
      management_status
      arc_status
      po_id
      po_status
      shipment_id
      merchant_shipment_id
      merch_carrier_request_id
      shipment_create_date
      shipment_update_date
      ship_method
      has_invoice
      invoice_issued_date
      is_ship_valid_final
      carrier_received_manifest
      virtual_manifest_received_date
      manifest_status
      carrier
      ship_type
      expected_pickup_date
      was_ship_pickedup
      real_pickup_date
      was_ship_delivered_fc
      real_fc_receive_date_tex
      real_fc_receive_date_fc
      issue_with_pickup
      ship_undeliverable_date
      no_show_confirmed_date
      was_no_show
      packages_onhold
      fc_estimated_arrival_date
      fc_estimated_arrival_date_adjusted
      postal_code
      state
      city
      district
      commercial_locality
      address_status
      clear_address1_ft_flag
      destination_warehouse_id
      is_eligible_for_pcp
      expected_box_qty
      received_box_qty
      pickedup_box_qty
      uf
      coverage
      pcp_launched
      fba_launched
      tax_regime
      shipment_order
      delivery_date_adjusted
      edd_adjusted
      last_received_date_local
      last_event
      real_sla_transit_time
      arrived_fc_ontime
      expected_units
      received_units
      shipment_expected_gms
      shipment_received_gms
      untitledfield
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteFbaSpoShipmentLevel = /* GraphQL */ `
  mutation DeleteFbaSpoShipmentLevel(
    $input: DeleteFbaSpoShipmentLevelInput!
    $condition: ModelFbaSpoShipmentLevelConditionInput
  ) {
    deleteFbaSpoShipmentLevel(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      seller_status
      is_fba_eligible
      fba_cohort
      program
      fba_launch_channel
      pf
      is_fba_managed
      alias_account_manager
      management_status
      arc_status
      po_id
      po_status
      shipment_id
      merchant_shipment_id
      merch_carrier_request_id
      shipment_create_date
      shipment_update_date
      ship_method
      has_invoice
      invoice_issued_date
      is_ship_valid_final
      carrier_received_manifest
      virtual_manifest_received_date
      manifest_status
      carrier
      ship_type
      expected_pickup_date
      was_ship_pickedup
      real_pickup_date
      was_ship_delivered_fc
      real_fc_receive_date_tex
      real_fc_receive_date_fc
      issue_with_pickup
      ship_undeliverable_date
      no_show_confirmed_date
      was_no_show
      packages_onhold
      fc_estimated_arrival_date
      fc_estimated_arrival_date_adjusted
      postal_code
      state
      city
      district
      commercial_locality
      address_status
      clear_address1_ft_flag
      destination_warehouse_id
      is_eligible_for_pcp
      expected_box_qty
      received_box_qty
      pickedup_box_qty
      uf
      coverage
      pcp_launched
      fba_launched
      tax_regime
      shipment_order
      delivery_date_adjusted
      edd_adjusted
      last_received_date_local
      last_event
      real_sla_transit_time
      arrived_fc_ontime
      expected_units
      received_units
      shipment_expected_gms
      shipment_received_gms
      untitledfield
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVFSortability = /* GraphQL */ `
  mutation CreateVFSortability(
    $input: CreateVFSortabilityInput!
    $condition: ModelVFSortabilityConditionInput
  ) {
    createVFSortability(input: $input, condition: $condition) {
      id
      warehouse_id
      expected_ship_date
      order_shipped_date
      status
      br_asin_sortability
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVFSortability = /* GraphQL */ `
  mutation UpdateVFSortability(
    $input: UpdateVFSortabilityInput!
    $condition: ModelVFSortabilityConditionInput
  ) {
    updateVFSortability(input: $input, condition: $condition) {
      id
      warehouse_id
      expected_ship_date
      order_shipped_date
      status
      br_asin_sortability
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVFSortability = /* GraphQL */ `
  mutation DeleteVFSortability(
    $input: DeleteVFSortabilityInput!
    $condition: ModelVFSortabilityConditionInput
  ) {
    deleteVFSortability(input: $input, condition: $condition) {
      id
      warehouse_id
      expected_ship_date
      order_shipped_date
      status
      br_asin_sortability
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVFOrderStatus = /* GraphQL */ `
  mutation CreateVFOrderStatus(
    $input: CreateVFOrderStatusInput!
    $condition: ModelVFOrderStatusConditionInput
  ) {
    createVFOrderStatus(input: $input, condition: $condition) {
      id
      warehouse_code
      order_id
      customer_order_id
      status
      created_datetime
      expected_ship_date
      ship_method
      quantity
      invoiced_datetime
      confirmed_datetime
      packed_datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVFOrderStatus = /* GraphQL */ `
  mutation UpdateVFOrderStatus(
    $input: UpdateVFOrderStatusInput!
    $condition: ModelVFOrderStatusConditionInput
  ) {
    updateVFOrderStatus(input: $input, condition: $condition) {
      id
      warehouse_code
      order_id
      customer_order_id
      status
      created_datetime
      expected_ship_date
      ship_method
      quantity
      invoiced_datetime
      confirmed_datetime
      packed_datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVFOrderStatus = /* GraphQL */ `
  mutation DeleteVFOrderStatus(
    $input: DeleteVFOrderStatusInput!
    $condition: ModelVFOrderStatusConditionInput
  ) {
    deleteVFOrderStatus(input: $input, condition: $condition) {
      id
      warehouse_code
      order_id
      customer_order_id
      status
      created_datetime
      expected_ship_date
      ship_method
      quantity
      invoiced_datetime
      confirmed_datetime
      packed_datetime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPCPDefects = /* GraphQL */ `
  mutation CreatePCPDefects(
    $input: CreatePCPDefectsInput!
    $condition: ModelPCPDefectsConditionInput
  ) {
    createPCPDefects(input: $input, condition: $condition) {
      id
      date_value
      merchant_id
      hdsc
      iss_inbounded
      iss_not_inbounded
      rpi
      prep
      shipped_units
      root_cause
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePCPDefects = /* GraphQL */ `
  mutation UpdatePCPDefects(
    $input: UpdatePCPDefectsInput!
    $condition: ModelPCPDefectsConditionInput
  ) {
    updatePCPDefects(input: $input, condition: $condition) {
      id
      date_value
      merchant_id
      hdsc
      iss_inbounded
      iss_not_inbounded
      rpi
      prep
      shipped_units
      root_cause
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePCPDefects = /* GraphQL */ `
  mutation DeletePCPDefects(
    $input: DeletePCPDefectsInput!
    $condition: ModelPCPDefectsConditionInput
  ) {
    deletePCPDefects(input: $input, condition: $condition) {
      id
      date_value
      merchant_id
      hdsc
      iss_inbounded
      iss_not_inbounded
      rpi
      prep
      shipped_units
      root_cause
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createESDailyOrderStatus = /* GraphQL */ `
  mutation CreateESDailyOrderStatus(
    $input: CreateESDailyOrderStatusInput!
    $condition: ModelESDailyOrderStatusConditionInput
  ) {
    createESDailyOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      orders
      backup_owner
      new_classification
      pending_orders
      scheduled
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateESDailyOrderStatus = /* GraphQL */ `
  mutation UpdateESDailyOrderStatus(
    $input: UpdateESDailyOrderStatusInput!
    $condition: ModelESDailyOrderStatusConditionInput
  ) {
    updateESDailyOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      orders
      backup_owner
      new_classification
      pending_orders
      scheduled
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteESDailyOrderStatus = /* GraphQL */ `
  mutation DeleteESDailyOrderStatus(
    $input: DeleteESDailyOrderStatusInput!
    $condition: ModelESDailyOrderStatusConditionInput
  ) {
    deleteESDailyOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      orders
      backup_owner
      new_classification
      pending_orders
      scheduled
      phone
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEasyShipOrderStatus2 = /* GraphQL */ `
  mutation CreateEasyShipOrderStatus2(
    $input: CreateEasyShipOrderStatus2Input!
    $condition: ModelEasyShipOrderStatus2ConditionInput
  ) {
    createEasyShipOrderStatus2(input: $input, condition: $condition) {
      id
      order_status
      order_condition
      order_date
      event_execution_log
      actual_delivery_date
      estimated_shipping_start_date
      estimated_shipping_end_date
      promise_delivery_start_date
      promise_delivery_end_date
      package_damaged_event_date
      package_lost_event_date
      package_rejected_event_date
      package_returned_to_seller_event_date
      delivery_status
      pick_up_status
      actual_schedule_date
      estimated_schedule_end_date
      estimated_schedule_start_date
      actual_pick_up_date
      customer_id
      merchant_id
      ship_option
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEasyShipOrderStatus2 = /* GraphQL */ `
  mutation UpdateEasyShipOrderStatus2(
    $input: UpdateEasyShipOrderStatus2Input!
    $condition: ModelEasyShipOrderStatus2ConditionInput
  ) {
    updateEasyShipOrderStatus2(input: $input, condition: $condition) {
      id
      order_status
      order_condition
      order_date
      event_execution_log
      actual_delivery_date
      estimated_shipping_start_date
      estimated_shipping_end_date
      promise_delivery_start_date
      promise_delivery_end_date
      package_damaged_event_date
      package_lost_event_date
      package_rejected_event_date
      package_returned_to_seller_event_date
      delivery_status
      pick_up_status
      actual_schedule_date
      estimated_schedule_end_date
      estimated_schedule_start_date
      actual_pick_up_date
      customer_id
      merchant_id
      ship_option
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEasyShipOrderStatus2 = /* GraphQL */ `
  mutation DeleteEasyShipOrderStatus2(
    $input: DeleteEasyShipOrderStatus2Input!
    $condition: ModelEasyShipOrderStatus2ConditionInput
  ) {
    deleteEasyShipOrderStatus2(input: $input, condition: $condition) {
      id
      order_status
      order_condition
      order_date
      event_execution_log
      actual_delivery_date
      estimated_shipping_start_date
      estimated_shipping_end_date
      promise_delivery_start_date
      promise_delivery_end_date
      package_damaged_event_date
      package_lost_event_date
      package_rejected_event_date
      package_returned_to_seller_event_date
      delivery_status
      pick_up_status
      actual_schedule_date
      estimated_schedule_end_date
      estimated_schedule_start_date
      actual_pick_up_date
      customer_id
      merchant_id
      ship_option
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEasyShipSellerOrderStatusFromPickup = /* GraphQL */ `
  mutation CreateEasyShipSellerOrderStatusFromPickup(
    $input: CreateEasyShipSellerOrderStatusFromPickupInput!
    $condition: ModelEasyShipSellerOrderStatusFromPickupConditionInput
  ) {
    createEasyShipSellerOrderStatusFromPickup(
      input: $input
      condition: $condition
    ) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEasyShipSellerOrderStatusFromPickup = /* GraphQL */ `
  mutation UpdateEasyShipSellerOrderStatusFromPickup(
    $input: UpdateEasyShipSellerOrderStatusFromPickupInput!
    $condition: ModelEasyShipSellerOrderStatusFromPickupConditionInput
  ) {
    updateEasyShipSellerOrderStatusFromPickup(
      input: $input
      condition: $condition
    ) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEasyShipSellerOrderStatusFromPickup = /* GraphQL */ `
  mutation DeleteEasyShipSellerOrderStatusFromPickup(
    $input: DeleteEasyShipSellerOrderStatusFromPickupInput!
    $condition: ModelEasyShipSellerOrderStatusFromPickupConditionInput
  ) {
    deleteEasyShipSellerOrderStatusFromPickup(
      input: $input
      condition: $condition
    ) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEasyShipOrderStatusTest = /* GraphQL */ `
  mutation CreateEasyShipOrderStatusTest(
    $input: CreateEasyShipOrderStatusTestInput!
    $condition: ModelEasyShipOrderStatusTestConditionInput
  ) {
    createEasyShipOrderStatusTest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEasyShipOrderStatusTest = /* GraphQL */ `
  mutation UpdateEasyShipOrderStatusTest(
    $input: UpdateEasyShipOrderStatusTestInput!
    $condition: ModelEasyShipOrderStatusTestConditionInput
  ) {
    updateEasyShipOrderStatusTest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEasyShipOrderStatusTest = /* GraphQL */ `
  mutation DeleteEasyShipOrderStatusTest(
    $input: DeleteEasyShipOrderStatusTestInput!
    $condition: ModelEasyShipOrderStatusTestConditionInput
  ) {
    deleteEasyShipOrderStatusTest(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEasyShipOrderStatus = /* GraphQL */ `
  mutation CreateEasyShipOrderStatus(
    $input: CreateEasyShipOrderStatusInput!
    $condition: ModelEasyShipOrderStatusConditionInput
  ) {
    createEasyShipOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEasyShipOrderStatus = /* GraphQL */ `
  mutation UpdateEasyShipOrderStatus(
    $input: UpdateEasyShipOrderStatusInput!
    $condition: ModelEasyShipOrderStatusConditionInput
  ) {
    updateEasyShipOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEasyShipOrderStatus = /* GraphQL */ `
  mutation DeleteEasyShipOrderStatus(
    $input: DeleteEasyShipOrderStatusInput!
    $condition: ModelEasyShipOrderStatusConditionInput
  ) {
    deleteEasyShipOrderStatus(input: $input, condition: $condition) {
      id
      orderStatus
      orderCondition
      orderDate
      eventExecutionLog
      actualDeliveryDate
      estimatedShippingStartDate
      estimatedShippingEndDate
      promiseDeliveryStartDate
      promiseDeliveryEndDate
      packageDamagedEventDate
      packageLostEventDate
      packageRejectedEventDate
      packageReturnedToSellerEventDate
      deliveryStatus
      pickUpStatus
      actualScheduleDate
      estimatedScheduleEndDate
      estimatedScheduleStartDate
      actualPickUpDate
      customerId
      merchantId
      shipOption
      ops_owner
      seller_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createForecastSF = /* GraphQL */ `
  mutation CreateForecastSF(
    $input: CreateForecastSFInput!
    $condition: ModelForecastSFConditionInput
  ) {
    createForecastSF(input: $input, condition: $condition) {
      id
      seller_id
      seller_name
      ops_owner
      forecast
      date
      backlog
      capacity
      cpt_hour
      node
      forecast_pkgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateForecastSF = /* GraphQL */ `
  mutation UpdateForecastSF(
    $input: UpdateForecastSFInput!
    $condition: ModelForecastSFConditionInput
  ) {
    updateForecastSF(input: $input, condition: $condition) {
      id
      seller_id
      seller_name
      ops_owner
      forecast
      date
      backlog
      capacity
      cpt_hour
      node
      forecast_pkgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteForecastSF = /* GraphQL */ `
  mutation DeleteForecastSF(
    $input: DeleteForecastSFInput!
    $condition: ModelForecastSFConditionInput
  ) {
    deleteForecastSF(input: $input, condition: $condition) {
      id
      seller_id
      seller_name
      ops_owner
      forecast
      date
      backlog
      capacity
      cpt_hour
      node
      forecast_pkgs
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPCPPreWBR = /* GraphQL */ `
  mutation CreatePCPPreWBR(
    $input: CreatePCPPreWBRInput!
    $condition: ModelPCPPreWBRConditionInput
  ) {
    createPCPPreWBR(input: $input, condition: $condition) {
      id
      expected_ship_date
      actual_pick_up_date
      merchant_id
      total
      missed_units
      reason
      edited_by
      self_report_survey
      fdps
      amazon_comments
      miss_type
      miss_type_seller
      subreason_seller
      reason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePCPPreWBR = /* GraphQL */ `
  mutation UpdatePCPPreWBR(
    $input: UpdatePCPPreWBRInput!
    $condition: ModelPCPPreWBRConditionInput
  ) {
    updatePCPPreWBR(input: $input, condition: $condition) {
      id
      expected_ship_date
      actual_pick_up_date
      merchant_id
      total
      missed_units
      reason
      edited_by
      self_report_survey
      fdps
      amazon_comments
      miss_type
      miss_type_seller
      subreason_seller
      reason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePCPPreWBR = /* GraphQL */ `
  mutation DeletePCPPreWBR(
    $input: DeletePCPPreWBRInput!
    $condition: ModelPCPPreWBRConditionInput
  ) {
    deletePCPPreWBR(input: $input, condition: $condition) {
      id
      expected_ship_date
      actual_pick_up_date
      merchant_id
      total
      missed_units
      reason
      edited_by
      self_report_survey
      fdps
      amazon_comments
      miss_type
      miss_type_seller
      subreason_seller
      reason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataPCP2 = /* GraphQL */ `
  mutation CreateSellerDataPCP2(
    $input: CreateSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    createSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataPCP2 = /* GraphQL */ `
  mutation UpdateSellerDataPCP2(
    $input: UpdateSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    updateSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataPCP2 = /* GraphQL */ `
  mutation DeleteSellerDataPCP2(
    $input: DeleteSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    deleteSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSummaryPCP = /* GraphQL */ `
  mutation CreateSummaryPCP(
    $input: CreateSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    createSummaryPCP(input: $input, condition: $condition) {
      id
      merchant_id
      tracking_id
      shipment_id
      fdps
      total
      reason
      expected_ship_date
      actual_pick_up_date
      amazon_comments
      miss_type
      reason_seller
      subreason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryPCP = /* GraphQL */ `
  mutation UpdateSummaryPCP(
    $input: UpdateSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    updateSummaryPCP(input: $input, condition: $condition) {
      id
      merchant_id
      tracking_id
      shipment_id
      fdps
      total
      reason
      expected_ship_date
      actual_pick_up_date
      amazon_comments
      miss_type
      reason_seller
      subreason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryPCP = /* GraphQL */ `
  mutation DeleteSummaryPCP(
    $input: DeleteSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    deleteSummaryPCP(input: $input, condition: $condition) {
      id
      merchant_id
      tracking_id
      shipment_id
      fdps
      total
      reason
      expected_ship_date
      actual_pick_up_date
      amazon_comments
      miss_type
      reason_seller
      subreason_seller
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataFBA = /* GraphQL */ `
  mutation CreateSellerDataFBA(
    $input: CreateSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    createSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataFBA = /* GraphQL */ `
  mutation UpdateSellerDataFBA(
    $input: UpdateSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    updateSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataFBA = /* GraphQL */ `
  mutation DeleteSellerDataFBA(
    $input: DeleteSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    deleteSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFCPTManagement = /* GraphQL */ `
  mutation CreateSFCPTManagement(
    $input: CreateSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    createSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      ranking
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const updateSFCPTManagement = /* GraphQL */ `
  mutation UpdateSFCPTManagement(
    $input: UpdateSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    updateSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      ranking
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const deleteSFCPTManagement = /* GraphQL */ `
  mutation DeleteSFCPTManagement(
    $input: DeleteSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    deleteSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      ranking
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const createSummaryVF = /* GraphQL */ `
  mutation CreateSummaryVF(
    $input: CreateSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    createSummaryVF(input: $input, condition: $condition) {
      id
      month
      calendar_year
      warehouse_id
      units_amzl
      total_units
      total_packages
      total_shipments
      su_amzl_perc
      su_share_vs_network
      ups
      gas_tank_vol
      woc
      return_qtd
      ship_to
      vlt_net
      vlt_vb
      fr_vb
      fr_net
      units_net
      units_xcv9
      ship_day
      vlt
      received_units
      ce_miss
      cancel_qtd
      po_qty_submitted
      po_qty_received
      po_qty_open
      avg_vlt_sum
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryVF = /* GraphQL */ `
  mutation UpdateSummaryVF(
    $input: UpdateSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    updateSummaryVF(input: $input, condition: $condition) {
      id
      month
      calendar_year
      warehouse_id
      units_amzl
      total_units
      total_packages
      total_shipments
      su_amzl_perc
      su_share_vs_network
      ups
      gas_tank_vol
      woc
      return_qtd
      ship_to
      vlt_net
      vlt_vb
      fr_vb
      fr_net
      units_net
      units_xcv9
      ship_day
      vlt
      received_units
      ce_miss
      cancel_qtd
      po_qty_submitted
      po_qty_received
      po_qty_open
      avg_vlt_sum
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryVF = /* GraphQL */ `
  mutation DeleteSummaryVF(
    $input: DeleteSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    deleteSummaryVF(input: $input, condition: $condition) {
      id
      month
      calendar_year
      warehouse_id
      units_amzl
      total_units
      total_packages
      total_shipments
      su_amzl_perc
      su_share_vs_network
      ups
      gas_tank_vol
      woc
      return_qtd
      ship_to
      vlt_net
      vlt_vb
      fr_vb
      fr_net
      units_net
      units_xcv9
      ship_day
      vlt
      received_units
      ce_miss
      cancel_qtd
      po_qty_submitted
      po_qty_received
      po_qty_open
      avg_vlt_sum
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSummaryES = /* GraphQL */ `
  mutation CreateSummaryES(
    $input: CreateSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    createSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryES = /* GraphQL */ `
  mutation UpdateSummaryES(
    $input: UpdateSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    updateSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryES = /* GraphQL */ `
  mutation DeleteSummaryES(
    $input: DeleteSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    deleteSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataES = /* GraphQL */ `
  mutation CreateSellerDataES(
    $input: CreateSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    createSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataES = /* GraphQL */ `
  mutation UpdateSellerDataES(
    $input: UpdateSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    updateSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataES = /* GraphQL */ `
  mutation DeleteSellerDataES(
    $input: DeleteSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    deleteSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMissesEasyShip = /* GraphQL */ `
  mutation CreateMissesEasyShip(
    $input: CreateMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    createMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMissesEasyShip = /* GraphQL */ `
  mutation UpdateMissesEasyShip(
    $input: UpdateMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    updateMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMissesEasyShip = /* GraphQL */ `
  mutation DeleteMissesEasyShip(
    $input: DeleteMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    deleteMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMQBandQBR = /* GraphQL */ `
  mutation CreateMQBandQBR(
    $input: CreateMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    createMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMQBandQBR = /* GraphQL */ `
  mutation UpdateMQBandQBR(
    $input: UpdateMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    updateMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMQBandQBR = /* GraphQL */ `
  mutation DeleteMQBandQBR(
    $input: DeleteMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    deleteMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMisses = /* GraphQL */ `
  mutation CreateMisses(
    $input: CreateMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    createMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMisses = /* GraphQL */ `
  mutation UpdateMisses(
    $input: UpdateMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    updateMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMisses = /* GraphQL */ `
  mutation DeleteMisses(
    $input: DeleteMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    deleteMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataSF = /* GraphQL */ `
  mutation CreateSellerDataSF(
    $input: CreateSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    createSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataSF = /* GraphQL */ `
  mutation UpdateSellerDataSF(
    $input: UpdateSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    updateSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataSF = /* GraphQL */ `
  mutation DeleteSellerDataSF(
    $input: DeleteSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    deleteSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
