import React, { useState } from 'react';
import { uploadToS3 } from './s3-utils';
import awsExports from '../../../aws-exports';
import CircularProgressWithLabel from './CircularProgress';
import { Typography, Button, Snackbar, Alert, Chip, Grid, Box } from '@mui/material';

import AWS from 'aws-sdk';

AWS.config.update({
    region: awsExports.aws_project_region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsExports.aws_cognito_identity_pool_id
    })
  });

  const s3 = new AWS.S3();


const FileUploader = () => {
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [fileName, setFileName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
        // Reset other states to their initial values
        setProgress(0);
        setError(null);
        setSuccess(false);
      } else {
        // If no file is selected (e.g., user cancels the file selection)
        setFile(null);
        setFileName('');
        setProgress(0);
        setError(null);
        setSuccess(false);
      }
    };



    const handleUpload = () => {
      if (file) {
        uploadToS3(file, file.name, setProgress, setError, setSuccess);
      }
    };

    const buttonStyle = (isActive) => ({
        backgroundColor: isActive ? '#007bff' : '#cccccc', // Blue when active, gray when inactive
        color: isActive ? '#fff' : '#666666', // White text when active, dark gray when inactive
        border: 'none',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: isActive ? 'pointer' : 'default', // Change cursor style based on active state
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for color changes
      });

    const fileInputStyle = {
        display: 'none', // Hide the default input
      };

      const customFileInputStyle = {
        backgroundColor: '#4CAF50', // Green color
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginRight: '10px', // Add some space between buttons
      };

      const instructionStyle = {
        marginBottom: '20px',
        padding: '15px',
        backgroundColor: '#f8f9fa',
        borderRadius: '5px',
        border: '1px solid #e9ecef',
    };

    const linkStyle = {
        color: '#007bff',
        textDecoration: 'none',
        marginRight: '10px',
    };

    const dropdownStyle = {
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      marginRight: '10px',
  };

  const templates = [
      { name: '[Ops] ES - Add ES New Seller Template', path: 's3://osc-user-file-upload/templates/seller_data_add_new_ES.xlsx' },
      { name: '[Ops] ES - Update ES Seller Data Template', path: 's3://osc-user-file-upload/templates/update_in_bulk_seller_data_ES.xlsx' },
      { name: '[Ops] SF - Add SF New Seller Template', path: 's3://osc-user-file-upload/templates/SF-seller_data_add_new.xlsx' },
      { name: '[Ops] SF - Update SF Seller Data Template', path: 's3://osc-user-file-upload/templates/SF-update_in_bulk_seller_data.xlsx' },
      { name: '[Ops] PCP - Add PCP New Seller Template', path: 's3://osc-user-file-upload/templates/seller_data_add_new_PCP.xlsx' },
      { name: '[Ops] PCP - Update PCP Seller Data Template', path: 's3://osc-user-file-upload/templates/update_in_bulk_seller_data_PCP.xlsx' },
      { name: '[Ops] ES - Update Pre WBR Template', path: 's3://osc-user-file-upload/templates/ES - Update PreWBR.xlsx' },
      { name: '[Ops] SF - Update Pre WBR Template', path: 's3://osc-user-file-upload/templates/Update SF Pre WBR.xlsx' },
      { name: '[Transportation] Update Pickup Frequency', path: 's3://osc-user-file-upload/templates/Pickup Frequency.xlsx' },
      { name: '[SPO] PCP Zip Code Coverage Template', path: 's3://osc-user-file-upload/templates/PCP_zip_code_coverage_template.xlsx' }
  ];

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };
  const handleTemplateDownload = async (templatePath) => {
    const bucketName = 'osc-user-file-upload';
    const key = templatePath.replace('s3://' + bucketName + '/', '');

    const params = {
      Bucket: bucketName,
      Key: key,
      Expires: 60 // Signed URL expires in 60 seconds
    };

    try {
      const url = await s3.getSignedUrlPromise('getObject', params);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading template:', error);
      setError('Failed to download template. Please try again.');
    }
  };


  return (
    <Box>
        <Box sx={{
            marginBottom: '20px',
            padding: '15px',
            backgroundColor: '#f8f9fa',
            borderRadius: '5px',
            border: '1px solid #e9ecef',
        }}>
            <Typography variant="h5" align="center" gutterBottom sx={{ marginBottom: '40px' }}>
                File Upload Instructions:
            </Typography>
            <Typography component="div" align="center">
                <ol style={{
                    paddingLeft: '20px',
                    listStylePosition: 'inside',
                    textAlign: 'center',
                }}>
                    <li style={{ marginBottom: '10px' }}>Choose your template from the dropdown.</li>
                    <li style={{ marginBottom: '10px' }}>Click on "Choose File" to select your CSV or Excel file and then download the template.</li>
                    <li style={{ marginBottom: '10px' }}>Once a file is selected, its name will appear below the buttons.</li>
                    <li style={{ marginBottom: '10px' }}>Click "Upload to S3" to start the upload process.</li>
                    <li style={{ marginBottom: '10px' }}>Wait for the upload to complete. You'll see a progress indicator.</li>
                    <li style={{ marginBottom: '10px' }}>Once complete, you'll see a success message.</li>
                </ol>
            </Typography>
            <Box mt={4}>
                <select
                    style={dropdownStyle}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                >
                    <option value="">Select a template</option>
                    {templates.map((template, index) => (
                    <option key={index} value={template.path}>
                        {template.name}
                    </option>
                    ))}
                </select>
                {selectedTemplate && (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleTemplateDownload(selectedTemplate)}
                    sx={{ marginLeft: '10px' }}
                    >
                    Download Template
                    </Button>
                )}
                </Box>
        </Box>

        <input
            type="file"
            onChange={handleFileChange}
            style={fileInputStyle}
            id="fileInput"
        />
        <label htmlFor="fileInput">
            <Button
                variant="contained"
                color="success"
                component="span"
                sx={{ marginRight: '10px' }}
            >
                Choose File
            </Button>
        </label>
        <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={file === null}
        >
            Upload to S3
        </Button>
        {fileName && (
            <Typography variant="body1" sx={{ marginTop: '10px' }}>
                Selected file: <strong>{fileName}</strong>
            </Typography>
        )}
        {progress > 0 && <CircularProgressWithLabel value={progress} />}
        {error && (
            <Typography variant="body1" color="error">
                {error}
            </Typography>
        )}
        {success && (
            <Typography variant="body1" color="success">
                File uploaded successfully!
            </Typography>
        )}
    </Box>
);
};

export default FileUploader;