import React, { useState, useEffect, useMemo } from "react";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import { Send, LocalPostOffice, AdsClick, MarkEmailRead } from '@mui/icons-material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { listSFCapacities } from "../../../graphql/queries";
import awsconfig from '../../../aws-exports';
import './summary.css';

Amplify.configure(awsconfig);

const Summary = ({ loading: initialLoading, onRefresh }) => {
  const [isLoading, setLoading] = useState(initialLoading);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSFCapacities, {
            limit: 20000,
            nextToken,
          })
        );
        const fetchedItems = response.data.listSFCapacities.items;
        const filteredItems = fetchedItems.filter(item => 
          item.current_capacity > 0 && item.comparison_capacity > 0
        );
        items = items.concat(filteredItems);
        nextToken = response.data.listSFCapacities.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const handleRefresh = () => {
    fetchData();
  };

  const totals = useMemo(() => {
    return data.reduce(
      (acc, item) => {
        acc.currentCapacity += item.current_capacity || 0;
        acc.comparisonCapacity += item.comparison_capacity || 0;
        return acc;
      },
      { currentCapacity: 0, comparisonCapacity: 0 }
    );
  }, [data]);

  const difference = totals.currentCapacity - totals.comparisonCapacity;

  const calculatePercentage = (current, comparison) => {
    if (comparison === 0) return '0';
    return (((current - comparison) / comparison) * 100).toFixed(2);
  };

  const calculatePercentageChange = (current, comparison) => {
    if (comparison === 0) return 0;
    return ((current - comparison) / comparison) * 100;
  };
  
  const topIncreases = useMemo(() => {
    return data
      .filter(item => item.current_capacity > item.comparison_capacity)
      .map(item => ({
        ...item,
        percentageChange: calculatePercentageChange(item.current_capacity, item.comparison_capacity)
      }))
      .sort((a, b) => b.percentageChange - a.percentageChange)
      .slice(0, 10);
  }, [data]);

  const topDecreases = useMemo(() => {
    return data
      .filter(item => item.current_capacity < item.comparison_capacity)
      .map(item => ({
        ...item,
        percentageChange: calculatePercentageChange(item.current_capacity, item.comparison_capacity)
      }))
      .sort((a, b) => a.percentageChange - b.percentageChange)
      .slice(0, 10);
  }, [data]);

  const columns = [
    { field: 'id', headerName: 'Node', flex: 1 },
    { field: 'current_capacity', headerName: 'Current Capacity', flex: 1 },
    { field: 'comparison_capacity', headerName: 'Last Week Capacity', flex: 1 },
    { 
      field: 'percentageChange', 
      headerName: 'Change (%)', 
      flex: 1,
      valueFormatter: (params) => `${params.value.toFixed(2)}%`
    },
  ];

  
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid item lg={6} xs={6} md={6}>
      </Grid>
      <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleRefresh}>
          Refresh Data
        </Button>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid container spacing={2} direction="row" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={3} md={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                marginTop: '2vh',
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(20px + 0.5vh)',
                fontWeight: 'bold',
              }}
            >
              Seller Flex Capacity
            </Typography>
            <Typography
              sx={{
                marginTop: 0,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(10px + 0.5vh)',
                fontWeight: 'lighter',
              }}
            >
              Overview of Seller Flex Capacity: {}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '2vh' }}>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Send sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Current Capacity</Typography>
              <Typography variant="h4">{totals.currentCapacity}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <LocalPostOffice sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Last Week Capacity</Typography>
              <Typography variant="h4">{totals.comparisonCapacity}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              backgroundColor: "#FFFFFF",
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <MarkEmailRead sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Difference</Typography>
              <Typography variant="h4">{totals.currentCapacity - totals.comparisonCapacity}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <AdsClick sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Change Percentage</Typography>
              <Typography variant="h4">
                {calculatePercentage(totals.currentCapacity, totals.comparisonCapacity)}%
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          height: '2px',
          backgroundColor: '#E0E0E0',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      />
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2vh' }}>
        <Box sx={{ width: '48%' }}>
          <Typography variant="h6" gutterBottom>
            Top 10 Increases
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
            marginLeft: 2,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '0px',
            borderRadius: '30px',
            maxHeight: '90%',
            }}
            rows={topIncreases}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            checkboxSelection
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <Typography variant="h6" gutterBottom>
            Top 10 Decreases
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
            marginLeft: 2,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '0px',
            borderRadius: '30px',
            maxHeight: '90%',
            }}
            rows={topDecreases}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            checkboxSelection
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '2px',
          backgroundColor: '#E0E0E0',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      />

  
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 2,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '0px',
            borderRadius: '30px',
            maxHeight: '100%',
          }}
          rows={data}
          columns={[
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'current_capacity', headerName: 'Current Capacity', flex: 1 },
            { field: 'comparison_capacity', headerName: 'Last Week Capacity', flex: 1 },
          ]}
          editMode="row"
          checkboxSelection
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
      )}
    </div>
  );
  
};


export default withAuthenticator(Summary);