import { useState, useEffect, useMemo } from "react";
import { listPCPDefects } from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy } from 'lodash';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month'];

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const startDate = new Date('2024-09-15');
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPDefects, {
            limit: 20000,
            nextToken,
            filter: {
              date_value: {
                gt: startDate.toISOString().slice(0, 10),
              },
              or: [
                { hdsc: { gt: 0 } },
                { iss_inbounded: { gt: 0 } },
                { iss_not_inbounded: { gt: 0 } },
                { rpi: { gt: 0 } },
                { prep: { gt: 0 } },
                
              ],
  
            },
          })
        );
        const fetchedItems = response.data.listPCPDefects.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPDefects.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]);

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  const getWeekNumber = (date_value) => {
    date_value = new Date(date_value);
    date_value.setUTCDate(date_value.getUTCDate() - date_value.getUTCDay() + 6);
    const yearStart = new Date(Date.UTC(date_value.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date_value - yearStart) / 86400000) / 7);
    return [date_value.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    date = new Date(date);
    const year = date.getUTCFullYear();
    const monthNo = date.getUTCMonth() + 1;
    return [year, monthNo];
  };


  const aggregatedDataById2AmazonComments2 = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.date_value);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
        case 'month':
          const monthNumber = getMonthNumber(expected_ship_date)[1];
          groupKey = `(${expected_ship_date.getFullYear()}) Month ${monthNumber.toString().padStart(2, '0')}`;
          break;
        
        default:
          return expected_ship_date.toISOString().split('T')[0];
      }
      return `${groupKey}_${item.merchant_id || 'N/A'}_${item.root_cause || 'N/A'}`;
    });

    const aggregatedById2 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, merchant_id, root_cause] = key.split('_');
        const aggregatedItem = { groupKey, merchant_id, root_cause, id: key };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'date_value' && column !== 'merchant_id' && column !== 'root_cause' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        aggregatedById2.push(aggregatedItem);
      }
    }
    return aggregatedById2;
  }, [data, groupByType]);

  const MissColumns = [
    { field: 'groupKey', headerName: 'Group Key', flex: 1 },
    { field: 'merchant_id', headerName: 'Merchant ID', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'hdsc', headerName: 'HDSC', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'iss_inbounded', headerName: 'ISS Inbounded', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'iss_not_inbounded', headerName: 'ISS not Inbounded', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'rpi', headerName: 'RPI', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'prep', headerName: 'PREP Total', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'shipped_units', headerName: 'Inbounded', type: 'number', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'root_cause', headerName: 'Root Cause', flex: 1, align: 'center', headerAlign: 'center' },
  ];

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
        {groupTypes.map((type) => (
          <Button key={type} variant={groupByType === type ? 'contained' : 'outlined'} onClick={() => handleGroupByChange(type)}>
            {type}
          </Button>
        ))}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ ...tableContainerStyle, marginBottom: '10px' }}>
          <div style={tableStyle}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>General</Typography>
            <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 2,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '0px',
              borderRadius: '10px',
              maxHeight: '90vh',
              overflow: 'auto', 
          }}
              rows={aggregatedDataById2AmazonComments2}
              columns={MissColumns}
              pageSize={50}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
              components={{
                Toolbar: () => (
                  <GridToolbarContainer>
                    <GridToolbarExport />
                    <GridToolbarQuickFilter />
                  </GridToolbarContainer>
                )
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticator(Summary);

