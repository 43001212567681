import { listVFInventories } from '../../../graphql/queries';
import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Grid, InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

      let startDate, endDate;
      if (dayOfWeek === 1) { // Monday
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 10);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      } else {
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 10);
        endDate = new Date();
        endDate.setDate(endDate.getDate());
      }

      const startDateStr = startDate.toISOString().slice(0, 10);
      const endDateStr = endDate.toISOString().slice(0, 10);

      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFInventories, {
            limit,
            nextToken,
            filter: {
              snapshot_date: {
                gt: startDateStr, // Filter for dates greater than yesterday's date
                lt: endDateStr, // Filter for dates less than tomorrow's date
              },
            },
          })
        );
        const fetchedItems = response.data.listVFInventories.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFInventories.nextToken;
      } while (nextToken);

      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
      <FormControl>
        <InputLabel id="warehouse-filter-label"></InputLabel>
        <Select
          labelId="warehouse-filter-label"
          id="warehouse-filter"
          value={selectedWarehouse}
          onChange={(e) => setSelectedWarehouse(e.target.value)}
          displayEmpty
          style={{ minWidth: '150px' }}
        >
          <MenuItem value="">All Warehouses</MenuItem>
          {[...new Set(data.map((item) => item.warehouse_id))].map((warehouse) => (
            <MenuItem key={warehouse} value={warehouse}>
              {warehouse}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </GridToolbarContainer>
  );

  const sortedData_table = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    let startDate, endDate;
    if (dayOfWeek === 1) { // Monday
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 10);
      endDate = new Date();
      endDate.setDate(endDate.getDate());
    } else {
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 10);
      endDate = new Date();
      endDate.setDate(endDate.getDate());
    }

    const filteredItems = data.filter(
      (item) =>
        new Date(item.snapshot_date) >= startDate &&
        new Date(item.snapshot_date) <= endDate &&
        (selectedWarehouse === '' || item.warehouse_id === selectedWarehouse)
    );
  
    return filteredItems.sort((a, b) => new Date(a.snapshot_date) - new Date(b.snapshot_date));
  }, [data, selectedWarehouse])

  const chartData = {
    labels: sortedData_table.map((item) => item.snapshot_date),
    datasets: [
      {
        label: 'Inventory',
        data: sortedData_table.map((item) => item.inventory),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Inventory by Snapshot Date',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Snapshot Date',
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Inventory',
        },
      },
    },
  };

  return (
    <div className="datatable" style={{ height: '100%', width: '100%' }}>
      <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={fetchData}>
          Refresh Data
        </Button>
      </Grid>
      <Grid item lg={8} xs={12} md={8} style={{ height: '50vh' }}>
        {loading ? (
          <Loader size="large" sx={{ marginLeft: '400px' }} />
        ) : (
          <div style={{ height: '100%' }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}></Grid>
      </Grid>
      <Grid item lg={8} xs={12} md={8}>
        <Typography
          sx={{
            marginTop: '2vh',
            //marginLeft: center,
            align: 'center',
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          Inventory by snapshot date
          {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
        </Typography>
        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            minHeight: '40vh',
            maxHeight: '90vh',
            overflow: 'auto', // Enable vertical scrolling if needed
          }}
          rows={sortedData_table}
          columns={[
            { field: 'warehouse_id', headerName: 'Node', flex: 1 },
            { field: 'snapshot_date', headerName: 'Snapshot Date', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'inventory', headerName: 'Inventory', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'gas_tank_vol', headerName: 'Gas Tank (Volume) %', flex: 1, align: 'center', headerAlign: 'center' },

            { field: 'cuft', headerName: 'Cubic Feet', flex: 1, align: 'center', headerAlign: 'center' },
          ]}
          components={{
            Toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { pageSize: 100 },
            sortModel: [{ field: 'snapshot_date', sort: 'desc' }],
          }}
        />
      </Grid>
    </div>
  );
};

export default PostList;