import { listVFOrderStatuses } from '../../../graphql/queries';
import { listVendorFlexTRBS } from '../../../graphql/queries';

import React, { useState, useEffect, useMemo, } from 'react';
import { API, graphqlOperation  } from 'aws-amplify';
import { DataGrid,GridToolbarContainer, GridToolbarExport,  GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Grid } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';
import moment from 'moment-timezone';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [capacityData, setCapacityData] = useState([]);
  const [mergedData, setMergedData] = useState({});
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date();
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate() + 10);
      today.setDate(today.getDate());
      console.log("Data before merge:", today);

      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const startOfTomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());

      let items = [];
      let nextToken = null;
      const limit = 40000000;
      do {
        const response = await API.graphql(
          graphqlOperation(listVFOrderStatuses, {
            limit,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: today.toISOString().slice(0, 10),
                lt: startOfTomorrow.toISOString().slice(0, 10),
              },
            },
          })
        );
        const fetchedItems = response.data.listVFOrderStatuses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFOrderStatuses.nextToken;
      } while (nextToken);

      // Define the "id" for the fetchData table
      items.forEach((item) => {
        item.id = `${item.expected_ship_date.slice(0, 10)}-${item.warehouse_code}`;
      });
      const newData = items.map((item) => ({
        ...item,
        id: `${item.expected_ship_date.slice(0, 10)}-${item.warehouse_code}`,
      }));

      const capacityDataItem = await fetchData2();
      const mergedData = newData.map((item) => {
        const capacityItem = capacityDataItem.find((capacity) => capacity.id === item.id);
        return {
          ...item,
          ...capacityItem,
        };
      });

      console.log("Data before merge:", newData.length);
      console.log("Capacity data before merge:", capacityDataItem);
      console.log("Merged data:", mergedData);

      setData(mergedData);
      setMergedData(mergedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchData2 = async () => {
    try {
      setLoading(true);
      const today = new Date(); // Get the current date and time
      const tenDaysFromNow = new Date(today.getTime() + 10 * 24 * 60 * 60 * 1000); // 10 days from now

      const startOfToday = new Date(new Date().getTime() - (3 * 60 * 60 * 1000));
      const startOfTodayStr = new Date(startOfToday.getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 16).replace('T', ' ');

      console.log("Data before merge:", startOfTodayStr);

      const startOfTomorrow = new Date(tenDaysFromNow.getFullYear(), tenDaysFromNow.getMonth(), tenDaysFromNow.getDate(), tenDaysFromNow.getHours(), tenDaysFromNow.getMinutes(), 0);

      let items = [];
      let nextToken = null;
      const limit = 40000000;
      do {
        const response = await API.graphql(
          graphqlOperation(listVendorFlexTRBS, {
            limit,
            nextToken,
            filter: {
              begin_instant: {
                gt: startOfTodayStr,
                lt: startOfTomorrow.toISOString(),
              },
            },
          })
        );
        const fetchedItems = response.data.listVendorFlexTRBS.items;

        // Only push the data to the items array if the begin_instant is higher than the current datetime
        const currentDateTime = new Date().toISOString();
        items = items.concat(
          fetchedItems.filter(item => item.begin_instant > startOfTodayStr)
        );

        // Add the new "date2" column to the fetchdata2 table
        items.forEach(item => {
          item.date2 = new Date(item.begin_instant).setDate(new Date(item.begin_instant).getDate());
          item.id = `${item.date2}-${item.warehouse_id}`;
        });


        nextToken = response.data.listVendorFlexTRBS.nextToken;
      } while (nextToken);

      // Group the data by begin_instant (day) and warehouse_id, and sum the capacity_value
      const capacityData = items.reduce((acc, item) => {
        const dateKey = moment(item.begin_instant).format('YYYY-MM-DD');
        const data2 = moment(item.date2).format('YYYY-MM-DD');
        const key = `${dateKey}-${item.warehouse_id}`;
        if (!acc[key]) {
          acc[key] = {
            id: key,
            begin_instant: dateKey,
            warehouse_id: item.warehouse_id,
            capacity_value: 0,
            date2: data2,
          };
        }
        acc[key].capacity_value += item.capacity_value;
        return acc;
      }, {});

      return Object.values(capacityData);
    } catch (error) {
      console.error('Error fetching data: 2', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);





  const sortedData = useMemo(() => {
    const currentDate = new Date();
  
    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= yesterday
    );
  
    // Group the data by warehouse_code, expected_ship_date, and status
    const groupedData = filteredItems.reduce((acc, item) => {
      const key = `${item.warehouse_code}-${item.expected_ship_date}-${item.status}-${item.capacity_value}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          warehouse_code: item.warehouse_code,
          expected_ship_date: item.expected_ship_date,
          status: item.status,
          capacity: item.capacity_value,
          totalQuantity: 0,
        };
      }
      acc[key].totalQuantity += item.quantity;
      return acc;
    }, {});

    
  
    // Convert the grouped data to an array and sort it
    const sortedItems = Object.values(groupedData).sort((a, b) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.totalQuantity - a.totalQuantity;
    });
  
    // Calculate the total quantity
    const totalQuantity = sortedItems.reduce((sum, item) => sum + item.totalQuantity, 0);
  
    return sortedItems;
  }, [data]);

  const sortedData_table = useMemo(() => {
  const currentDate = new Date();

  // Calculate yesterday's date
  const yesterday = new Date();
  yesterday.setDate(currentDate.getDate() - 1);

  const filteredItems = data.filter(item =>
    new Date(item.expected_ship_date) >= yesterday
  );

  // Group the data by expected_ship_date and warehouse_code
  const groupedData_2 = filteredItems.reduce((acc, item) => {
    const key = `${item.expected_ship_date}-${item.warehouse_code}-${item.capacity_value}`;
    if (!acc[key]) {
      acc[key] = {
        id: key,
        expected_ship_date: item.expected_ship_date,
        warehouse_code: item.warehouse_code,
        capacity_value: item.capacity_value,
        confirmed: 0,
        new: 0,
        packed: 0,
        'invoice_submitted': 0,
        processed: 0,
        trb: 0,
        totalOrders: 0,
        totalOrders_processed: 0,
        totalOrders_nprocessed: 0,
      };
    }

    // Determine the most recent status and update the corresponding column
    if (item.status === 'INVOICE_SUBMITTED') {
      acc[key]['invoice_submitted'] += item.quantity;
      acc[key].totalOrders_processed += item.quantity;
    } else if (item.status === 'PACKED') {
      acc[key].packed += item.quantity;
      acc[key].totalOrders_processed += item.quantity;
    } else if (item.status === 'CONFIRMED') {
      acc[key].confirmed += item.quantity;
      acc[key].totalOrders_nprocessed += item.quantity;
    } else if (item.status === 'NEW') {
      acc[key].new += item.quantity;
      acc[key].totalOrders_nprocessed += item.quantity;
    }

    acc[key].totalOrders += item.quantity;

    return acc;
  }, {});

  // Convert the grouped data to an array and sort it
  const sortedItems2 = Object.values(groupedData_2).sort((b, a) => {
    const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return b.totalOrders_processed - a.totalOrders_processed;
  });

  // Calculate the processed percentage for each group
  sortedItems2.forEach(item => {
    item.processed = item.totalOrders > 0 ? item.totalOrders_processed / item.totalOrders : 0;
    item.trb = item.capacity_value > 0 ? (item.totalOrders - item.totalOrders_processed) / item.capacity_value : 1;
  });

  return sortedItems2;
}, [data]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter debounceMs={500} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  // Create the bar chart data
  const barChartData = sortedData.reduce((acc, item) => {
    const statusIndex = acc.findIndex(bar => bar.status === item.status);
    if (statusIndex === -1) {
      acc.push({
        status: item.status,
        quantity: item.totalQuantity,
      });
    } else {
      acc[statusIndex].quantity += item.totalQuantity;
    }
    return acc;
  }, []);

  // Calculate the processed and not processed orders
  const processedOrders = sortedData.filter(item => item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED').reduce((sum, item) => sum + item.totalQuantity, 0);
  const notProcessedOrders = sortedData.filter(item => item.status === 'CONFIRMED' || item.status === 'NEW').reduce((sum, item) => sum + item.totalQuantity, 0);
  const totalOrders = processedOrders + notProcessedOrders;

  const pieChartData = [
    { name: 'Processed', value: processedOrders },
    { name: 'Not Processed', value: notProcessedOrders },
  ];

  const COLORS = ['#0859A0', '#FF9900'];
  

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>


      <Grid item lg={8} xs={12} md={8}>
      <Typography
            sx={{
              marginTop: '2vh',
              //marginLeft: center,
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Capacity Used Until CPT per node
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto', // Enable vertical scrolling if needed
            }}
            rows={sortedData_table}
            columns={[
              { field: 'warehouse_code', headerName: 'Node', flex: 1 },
              { field: 'expected_ship_date', headerName: 'Expected Ship Date', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'totalOrders', headerName: 'Total Expected', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'totalOrders_nprocessed', headerName: 'Not processed Orders', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'totalOrders_processed', headerName: 'Processed Orders', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'capacity_value', headerName: 'Capacity', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'processed', headerName: 'Processed %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
              { field: 'trb', headerName: 'Capacity Used', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { pageSize: 100 },
              sortModel: [{ field: 'processed', sort: 'asc' }],
            }}
          />



          
        </Grid>

      

      <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
      </Typography>

    </div>
  );
}

export default PostList;
