import { useState, useEffect, useMemo } from "react";
import { listSEStrackSRS } from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import {Send, LocalPostOffice, AdsClick, MarkEmailRead} from '@mui/icons-material';
import awsconfig from '../../../aws-exports';
import { groupBy } from 'lodash';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month'];

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const startDate = new Date('2024-06-01');
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSEStrackSRS, {
            limit: 20000,
            nextToken,
            filter: {
              send_datetime: {
                gt: startDate.toISOString().slice(0, 10),
             },
              program: {
                eq: 'PCP',
              },
            },
          })
        );
        const fetchedItems = response.data.listSEStrackSRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSEStrackSRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const handleRefresh = () => {
    fetchData();
  };


  const getWeekNumber = (date_value) => {
    date_value = new Date(date_value);
    date_value.setUTCDate(date_value.getUTCDate() - date_value.getUTCDay() + 6);
    const yearStart = new Date(Date.UTC(date_value.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date_value - yearStart) / 86400000) / 7);
    return [date_value.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    date = new Date(date);
    const year = date.getUTCFullYear();
    const monthNo = date.getUTCMonth() + 1;
    return [year, monthNo];
  };


  const aggregated = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.send_datetime);
      const [year, weekNo] = getWeekNumber(expected_ship_date);
      const groupKey = `${year} Week ${weekNo.toString().padStart(2, '0')}`;
      return groupKey;
    });
  
    const aggregatedById2 = [];
    const recipientCounts = {};
    const unopenedRecipientCounts = {};
  
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [year, weekNo] = key.split(" ");
        const aggregatedItem = {
          groupKey: key,
          id: key,
          week_number: weekNo,
          send_count: items.filter(item => item.send_datetime).length,
          delivery_count: items.filter(item => item.delivery_datetime).length,
          open_count: items.filter(item => item.open_datetime).length,
          open_to_delivery_ratio: (items.filter(item => item.open_datetime).length / (items.filter(item => item.delivery_datetime).length || 1)) * 100,
          open_to_delivery_ratio_formatted: (
            (items.filter(item => item.open_datetime).length / (items.filter(item => item.delivery_datetime).length || 1)) * 100
          ).toFixed(2) + "%",
          click_to_delivery_ratio: (items.filter(item => item.srs_click_datetime).length / (items.filter(item => item.delivery_datetime).length || 1)) * 100,
          click_to_delivery_ratio_formatted: (
            (items.filter(item => item.srs_click_datetime).length / (items.filter(item => item.delivery_datetime).length || 1)) * 100
          ).toFixed(2) + "%",
          srs_click_count: items.filter(item => item.srs_click_datetime).length,
          qna_click_count: items.filter(item => item.qna_click_datetime).length,
          bounce_count: items.filter(item => item.bounce_subtype).length,
          recipient_count: 0,
          unopened_recipient_count: 0,
          recipients: [], // Initialize the recipients array
          expected_ship_date: new Date(items[0].send_datetime), // Store the expected ship date
        };
  
        const recipients = new Set(items.map(item => item.seller_name));
      aggregatedItem.recipient_count = recipients.size;
      aggregatedItem.recipients = Array.from(recipients);
      recipientCounts[key] = recipients.size;

      // Count recipients that never opened
      const unopenedRecipients = new Set(items.filter(item => !item.open_datetime).map(item => item.seller_name));
      aggregatedItem.unopened_recipient_count = unopenedRecipients.size;
      unopenedRecipientCounts[key] = unopenedRecipients.size;

      // Get the distinct recipients that are in the first data but not in the second data
      const recipientsWithoutOpen = new Set(
        aggregatedItem.recipients.filter(seller_name => !data.some(dataItem => dataItem.seller_name === seller_name && dataItem.open_datetime))
      );
      aggregatedItem.recipients_without_open = Array.from(recipientsWithoutOpen);
      aggregatedItem.recipients_without_open_count = recipientsWithoutOpen.size;

      aggregatedById2.push(aggregatedItem);
    }
  }

  aggregatedById2.sort((a, b) => b.expected_ship_date.getTime() - a.expected_ship_date.getTime());


  console.log('Distinct recipients without open:', aggregatedById2.reduce((total, item) => total + item.recipients_without_open_count, 0));

  return aggregatedById2;
}, [data, groupByType]);




const aggregatedByDeliveryDatetime = useMemo(() => {
  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 30 * 60 * 60 * 1000);

  const last24HoursData = data.filter(item => {
    const deliveryDateTime = new Date(item.delivery_datetime);
    return deliveryDateTime >= twentyFourHoursAgo && deliveryDateTime <= now;
  });

  const groupedByDayHour = groupBy(last24HoursData, (item) => {
    const expectedShipDate = new Date(item.delivery_datetime);
    const day = expectedShipDate.getUTCDate().toString().padStart(2, '0');
    const month = (expectedShipDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const hour = expectedShipDate.getUTCHours().toString().padStart(2, '0');
    const groupKey = `${day}-${month} ${hour}:00`;
    return groupKey;
  });

  const aggregatedByDayHour = [];
  for (const key in groupedByDayHour) {
    if (groupedByDayHour.hasOwnProperty(key)) {
      const items = groupedByDayHour[key];
      const [dayMonth, hourMinute] = key.split(" ");
      const [day, month] = dayMonth.split("-");
      const hour = parseInt(hourMinute.slice(0, 2), 10);
      const aggregatedItem = {
        groupKey: key,
        day,
        month,
        hour,
        delivery_count: items.filter(item => item.delivery_datetime).length,
      };
      aggregatedByDayHour.push(aggregatedItem);
    }
  }
  aggregatedByDayHour.sort((a, b) => a.groupKey.localeCompare(b.groupKey));

  console.log(aggregatedByDayHour);
  return aggregatedByDayHour;
}, [data]);


  const aggregatedByClickSRSDatetime = useMemo(() => {
  const groupedByDayHour = groupBy(data, (item) => {
    const expectedShipDate = new Date(item.srs_click_datetime);
    const day = expectedShipDate.getUTCDate().toString().padStart(2, '0');
    const month = (expectedShipDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const hour = expectedShipDate.getUTCHours().toString().padStart(2, '0');
    const groupKey = `${day}-${month} ${hour}:00`;
    return groupKey;
  });

  const aggregatedByDayHour = [];
  for (const key in groupedByDayHour) {
    if (groupedByDayHour.hasOwnProperty(key)) {
      const items = groupedByDayHour[key];
      const [dayMonth, hourMinute] = key.split(" ");
      const [day, month] = dayMonth.split("-");
      const hour = parseInt(hourMinute.slice(0, 2), 10);
      const aggregatedItem = {
        groupKey: key,
        day,
        month,
        hour,
        srs_click_count: items.filter(item => item.srs_click_datetime).length,
      };
      aggregatedByDayHour.push(aggregatedItem);
    }
  }

  // Sort the aggregatedByDayHour array by groupKey
  aggregatedByDayHour.sort((a, b) => a.groupKey.localeCompare(b.groupKey));

  console.log(aggregatedByDayHour);
  return aggregatedByDayHour;
}, [data]);

  const aggregatedByClickQNADatetime = useMemo(() => {
    const groupedByDayHour = groupBy(data, (item) => {
      const expectedShipDate = new Date(item.qna_click_datetime);
      const day = expectedShipDate.getUTCDate().toString().padStart(2, '0');
      const month = (expectedShipDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const hour = expectedShipDate.getUTCHours().toString().padStart(2, '0');
      const groupKey = `${day}-${month} ${hour}:00`;
      return groupKey;
    });
  
    const aggregatedByDayHour = [];
    for (const key in groupedByDayHour) {
      if (groupedByDayHour.hasOwnProperty(key)) {
        const items = groupedByDayHour[key];
        const [dayMonth, hourMinute] = key.split(" ");
        const [day, month] = dayMonth.split("-");
        const hour = parseInt(hourMinute.slice(0, 2), 10);
        const aggregatedItem = {
          groupKey: key,
          day,
          month,
          hour,
          qna_click_count: items.filter(item => item.qna_click_datetime).length,
        };
        aggregatedByDayHour.push(aggregatedItem);
      }
    }
    aggregatedByDayHour.sort((a, b) => a.groupKey.localeCompare(b.groupKey));

    console.log(aggregatedByDayHour);
    return aggregatedByDayHour;
  }, [data]);


  const aggregatedByOpenDatetime = useMemo(() => {
    const groupedByDayHour = groupBy(data, (item) => {
      const expectedShipDate = new Date(item.open_datetime);
      const day = expectedShipDate.getUTCDate().toString().padStart(2, '0');
      const month = (expectedShipDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const hour = expectedShipDate.getUTCHours().toString().padStart(2, '0');
      const groupKey = `${day}-${month} ${hour}:00`;
      return groupKey;
    });
  
    const aggregatedByDayHour = [];
    for (const key in groupedByDayHour) {
      if (groupedByDayHour.hasOwnProperty(key)) {
        const items = groupedByDayHour[key];
        const [dayMonth, hourMinute] = key.split(" ");
        const [day, month] = dayMonth.split("-");
        const hour = parseInt(hourMinute.slice(0, 2), 10);
        const aggregatedItem = {
          groupKey: key,
          day,
          month,
          hour,
          open_count: items.filter(item => item.open_datetime).length,
        };
        aggregatedByDayHour.push(aggregatedItem);
      }
    }
    aggregatedByDayHour.sort((b, a) => a.groupKey.localeCompare(b.groupKey));

    console.log(aggregatedByDayHour);
    return aggregatedByDayHour;
  }, [data]);


  const DeliveryLineChart = ({ deliveryData, openData, clickSRSData, clickQNAData }) => {
    // Sort the data by groupKey
    const sortedDeliveryData = [...deliveryData].sort((a, b) => {
      const dateA = new Date(a.groupKey);
      const dateB = new Date(b.groupKey);
      return dateA - dateB;
    });
  
    const sortedOpenData = [...openData].sort((a, b) => {
      const dateA = new Date(a.groupKey);
      const dateB = new Date(b.groupKey);
      return dateA - dateB;
    });

    const sortedClickqnaData = [...clickQNAData].sort((a, b) => {
      const dateA = new Date(a.groupKey);
      const dateB = new Date(b.groupKey);
      return dateA - dateB;
    });

    const sortedClicksrsData = [...clickSRSData].sort((a, b) => {
      const dateA = new Date(a.groupKey);
      const dateB = new Date(b.groupKey);
      return dateA - dateB;
    });
    
  
    const now = new Date();
  const thirtyHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  // Create an array of hourly labels for the x-axis
  // Create an array of hourly labels for the x-axis
  const labels = [];
  const deliveryDataMap = new Map();
  const openDataMap = new Map();
  const clickSRSDataMap = new Map();
  const clickQNADataMap = new Map();

  for (let i = 0; i < 24; i++) {
    const date = new Date(thirtyHoursAgo.getTime() + i * 60 * 60 * 1000);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const hour = date.getUTCHours().toString().padStart(2, '0');
    const groupKey = `${day}-${month} ${hour}:00`;

    const deliveryItem = sortedDeliveryData.find(item => item.groupKey === groupKey);
    deliveryDataMap.set(groupKey, deliveryItem ? deliveryItem.delivery_count : 0);

    const openItem = sortedOpenData.find(item => item.groupKey === groupKey);
    openDataMap.set(groupKey, openItem ? openItem.open_count : 0);

    const clickSRSItem = sortedClicksrsData.find(item => item.groupKey === groupKey);
    clickSRSDataMap.set(groupKey, clickSRSItem ? clickSRSItem.srs_click_count : 0);

    const clickQNAItem = sortedClickqnaData.find(item => item.groupKey === groupKey);
    clickQNADataMap.set(groupKey, clickQNAItem ? clickQNAItem.qna_click_count : 0);

    if (
      deliveryDataMap.get(groupKey) > 0 ||
      openDataMap.get(groupKey) > 0 ||
      clickSRSDataMap.get(groupKey) > 0 ||
      clickQNADataMap.get(groupKey) > 0
    ) {
      labels.push(groupKey);
    }
  }

  const datasets = [
    {
      label: 'Delivery Count',
      data: labels.map(label => deliveryDataMap.get(label)),
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    },
    {
      label: 'Open Count',
      data: labels.map(label => openDataMap.get(label)),
      borderColor: 'rgb(255, 99, 132)',
      tension: 0.1,
    },
    {
      label: 'Click SRS Count',
      data: labels.map(label => clickSRSDataMap.get(label)),
      borderColor: 'rgb(10, 99, 132)',
      tension: 0.1,
    },
    {
      label: 'Click QNA Count',
      data: labels.map(label => clickQNADataMap.get(label)),
      borderColor: 'rgb(100, 200, 132)',
      tension: 0.1,
    },
  ];

  const chartData = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Interactions Over Time (last 24 hours)',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Datetime',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
    maintainAspectRatio: false,
    height: '300px',
  };

  return (
    <div
      style={{
        height: '500px',
        width: '95%',
        justifyContent: 'center',
        margin: '0 auto',
        borderRadius: '25px',
      }}
    >
      <Line options={options} data={chartData} />
    </div>
  );
};


      
  const getHigherGroupKey = (aggregatedData) => {
    let higherGroupKey = '';
    let higherYear = 0;
    let higherWeekNumber = 0;
  
    for (const item of aggregatedData) {
      const [year, weekNo] = item.groupKey.split(' ');
      const currentWeekNumber = parseInt(weekNo.replace('Week ', ''), 10);
  
      if (year > higherYear || (year === higherYear && currentWeekNumber > higherWeekNumber)) {
        higherYear = parseInt(year, 10);
        higherWeekNumber = currentWeekNumber;
        higherGroupKey = item.groupKey;
      }
    }
  
    return higherGroupKey;
  };

  const today = new Date();
  const isMonday = today.getDay() === 1;


  const currentDate = new Date();
  const currentWeekNumber = getWeekNumber2(currentDate);
  const year = currentDate.getFullYear();
  
  let higherGroupKey;
  if (currentDate.getDay() === 1) { // Monday
    higherGroupKey = `${year} Week ${(currentWeekNumber - 1).toString().padStart(2, '0')}`;
  } else {
    higherGroupKey = `${year} Week ${(currentWeekNumber).toString().padStart(2, '0')}`;
  }
  
  console.log(`The higher group key is: ${higherGroupKey}`);
  
  // Assuming getWeekNumber() is defined elsewhere in your code
  function getWeekNumber2(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDays = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDays + firstDayOfYear.getDay() + 1) / 7);
  }
  
  console.log(`The higher group key is: ${higherGroupKey}`);
  
  console.log("higher", higherGroupKey);

  

  const MissColumns = [
    {
      field: "groupKey",
      headerName: "Week Delivery",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
      sortComparator: (v1, v2) => {
        const [y1, w1] = v1.split(" ");
        const [y2, w2] = v2.split(" ");
        if (y1 !== y2) {
          return y1 < y2 ? -1 : 1;
        }
        return w1 < w2 ? -1 : 1;
      },
    },
    {
      field: "send_count",
      headerName: "Send Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "delivery_count",
      headerName: "Delivery Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "open_count",
      headerName: "Open Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "open_to_delivery_ratio_formatted",
      headerName: "Open Ratio",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "srs_click_count",
      headerName: "SRS Click Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "click_to_delivery_ratio_formatted",
      headerName: "SRS Click Ratio",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "qna_click_count",
      headerName: "QNA Click Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "bounce_count",
      headerName: "Rejected Count",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "recipient_count",
      headerName: "Recipients Distincts",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
    {
      field: "recipients_without_open_count",
      headerName: "Inactive Sellers",
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "customHeader",
    },
  ];
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  const totals = useMemo(() => {
    return aggregated
      .filter(item => item.groupKey === higherGroupKey)
      .reduce(
        (acc, item) => {
          acc.sendCount += item.send_count;
          acc.deliveryCount += item.delivery_count;
          acc.srsClickCount += item.srs_click_count;
          acc.qnaClickCount += item.qna_click_count;
          acc.openCount += item.open_count;
          return acc;
        },
        { sendCount: 0, deliveryCount: 0, srsClickCount: 0, qnaClickCount: 0, openCount: 0 }
      );
  }, [aggregated, higherGroupKey]);

  const calculatePercentage = (count, totalCount) => {
    if (totalCount === 0) {
      return 0;
    }
    return ((count / totalCount) * 100).toFixed(2);
  };



  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <Grid item lg={6} xs={6} md={6}>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Refresh Data
          </Button>
        </Grid>
        <Grid container spacing={2} direction="row">
        <Grid container spacing={2} direction="row" sx={{ display: 'flex', justifyContent: 'center' }}>
  <Grid item xs={3} md={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Typography
      sx={{
        marginTop: '2vh',
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(20px + 0.5vh)',
        fontWeight: 'bold',
      }}
    >
      Virtual Deliverability Manager
    </Typography>
    <Typography
      sx={{
        marginTop: 0,
        fontFamily: 'Source Sans Pro',
        fontSize: 'calc(10px + 0.5vh)',
        fontWeight: 'lighter',
      }}
    >
      Overview of Self Report Survey Email Interactions: {higherGroupKey}
    </Typography>
  </Grid>
</Grid>
</Grid>
      <Grid container spacing={2} sx={{ marginTop: '2vh' }}>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Send sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Send</Typography>
              <Typography variant="h4">{totals.sendCount}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <LocalPostOffice sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Delivery</Typography>
              <Typography variant="h4">{totals.deliveryCount}</Typography>
              <Typography variant="body2" color="textSecondary">
                {calculatePercentage(totals.deliveryCount, totals.sendCount)}% of Send
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              backgroundColor: "#FFFFFF",
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <MarkEmailRead sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">Open</Typography>
              <Typography variant="h4">{totals.openCount}</Typography>
              <Typography variant="body2" color="textSecondary">
                {calculatePercentage(totals.openCount, totals.deliveryCount)}% of Delivery
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#F5F5F5',
              borderRadius: '25px',
              height: '120px',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: "#FFFFFF",
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <AdsClick sx={{ fontSize: '80px' }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
              <Typography variant="h6">SRS Click</Typography>
              <Typography variant="h4">{totals.srsClickCount}</Typography>
              <Typography variant="body2" color="textSecondary">
                {calculatePercentage(totals.srsClickCount, totals.deliveryCount)}% of Delivery
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
      sx={{
        width: '100%',
        height: '2px',
        backgroundColor: '#E0E0E0',
        marginTop: '2vh',
        marginBottom: '2vh',
      }}
    />
      <DeliveryLineChart deliveryData={aggregatedByDeliveryDatetime} openData={aggregatedByOpenDatetime} clickSRSData={aggregatedByClickSRSDatetime} clickQNAData={aggregatedByClickQNADatetime} />
      <Box
      sx={{
        width: '100%',
        height: '2px',
        backgroundColor: '#E0E0E0',
        marginTop: '2vh',
        marginBottom: '2vh',
      }}
    />

      {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (

      
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '30px',
          maxHeight: '100%',
        }}
        rows={aggregated}
        columns={MissColumns}
        editMode="row"
        checkboxSelection
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
      />
      )}
    </div>
    );
};


export default withAuthenticator(Summary);