import { useState, useEffect } from "react";
import { listMissesEasyShips } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { Button} from '@mui/material';
import awsconfig from '../../../aws-exports';
import './PreWBR.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import SummaryContent from './summary_d';
import PreWBRContent from './Heat_Map';
import Reasons from './reasons';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './table-styles.css';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabsEasyShip = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [value, setValue] = React.useState(0);

  
  // -------------- PRE WBR DATA --------------
  function getLastWeekNumber() {
    const now = new Date();
    const thisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfYear = new Date(now.getFullYear(), 0, 1);
  
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillThisWeek = thisWeek - startOfYear;
    let weekNumberThisWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek);
    let weekNumberLastWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek) -6 ;
  
    // Check if the week number is negative
    if (weekNumberLastWeek <= 0) {
      weekNumberLastWeek =0;
    }
  
    return weekNumberLastWeek;
  }
  const lastWeekNumber = getLastWeekNumber();
  console.log(lastWeekNumber);

  async function fetchData() {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      const currentYear = new Date().getFullYear(); 
      do {
        const response = await API.graphql(graphqlOperation(listMissesEasyShips, {
          filter: {
            and: [
              { week: { ge: lastWeekNumber -3 } }, 
              {
                date: {
                  beginsWith: `${currentYear}-`, 
                },
              },
            ],
          },
          limit: 200000,
          nextToken: nextToken
        }));
  
        const fetchedItems = response.data.listMissesEasyShips.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listMissesEasyShips.nextToken;
      } while (nextToken);
      
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  

    useEffect(() => {
        try {
          fetchData();
        } catch (error) {
          console.error('Error fetching user group information:', error);
        }
    }, []);

    const handleRefreshData = async () => {
      try {
        setLoading(true);
        // Trigger your data fetching logic here
        await fetchData();
        setLoading(false);
      } catch (error) {
        console.error('Error refreshing data:', error);
      }
    };
    const tableContainerStyle = {
      height: '80vh', 
      overflow: 'auto', 
      display: 'flex',
      flexDirection: 'column',
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    //
    //

    return (
      <div className='datatable' style={{ height: '100%', width: '100%', marginTop: '5vh' }}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
        >
            EASY SHIP - DAILY TASKS (Drop Off)
        </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', padding: '0 24px', marginTop: '1vh' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Reasons" {...a11yProps(1)} />
            <Tab label="Heat Map" {...a11yProps(2)} />



          </Tabs>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5vh', }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRefreshData}
            >
              Refresh Data
            </Button>
          </div>
        </Box>        
        <CustomTabPanel value={value} index={0}>
            <SummaryContent username={username} userGroup={userGroup} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PreWBRContent username={username} userGroup={userGroup} data={data} loading={loading} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Reasons username={username} userGroup={userGroup} data={data} loading={loading} />
        </CustomTabPanel>
        
        
      </Box>
      </div>
    );
    
};


export default withAuthenticator(TabsEasyShip);