import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [
    {
      field: "week",
      headerName: "Week",
      type: 'number',
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "owner",
      headerName: "Owner",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
    }, 
    {
      field: "orders",
      headerName: "Orders",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_units",
      headerName: "MXSD",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_percent",
      headerName: "MXSD (%)",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.40;
      
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 2.70%     ST: 1.83%
            </div>
          </div>
        );
      },
    }, 
    {
      field: "cr_units",
      headerName: "CR",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "cr_percent",
      headerName: "SCR (%)",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1.40;
       
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 1.40%     ST: 1.22%
            </div>
          </div>
        );
      },
    },    
    {
      field: "late_handover_units",
      headerName: "CR",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "late_handover_percent",
      headerName: "LH (%)",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 0.40;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 2.80%     ST: 1.96%
            </div>
          </div>
        );
      },
    },
    ];


export default tableColumns;