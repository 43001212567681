import { useState, useEffect, useCallback, useMemo } from "react";
import { listSFCPTManagements } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Snackbar, Alert, Chip, Grid, NativeSelect, FormControl, InputLabel, Avatar } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { withAuthenticator, Loader, } from '@aws-amplify/ui-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Select, MenuItem } from '@mui/material';
import './forms.css'; 
import Popover from '@mui/material/Popover';
import Backlog from './backlog';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CptManagement = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [selectedOpsOwner, setSelectedOpsOwner] = useState('All');
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  
  const handleOpsOwnerChange = (event) => {
    setSelectedOpsOwner(event.target.value);
  };
  const opsOwners = useMemo(() => {
    const uniqueOpsOwners = [...new Set(data.map((item) => item.ops_owner))];
    return uniqueOpsOwners;
  }, [data]);


  
  async function fetchData() {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      do {
        const response = await API.graphql(graphqlOperation(listSFCPTManagements, {
          limit: 10000,
          nextToken: nextToken
          
        }));
  
        const fetchedItems = response.data.listSFCPTManagements.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listSFCPTManagements.nextToken;
      } while (nextToken);
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const dataDoDiaAnterior = new Date();
  dataDoDiaAnterior.setDate(dataDoDiaAnterior.getDate() - 1);
  
  const dia = dataDoDiaAnterior.getDate().toString().padStart(2, '0');
  const mes = (dataDoDiaAnterior.getMonth() + 1).toString().padStart(2, '0');
  const ano = dataDoDiaAnterior.getFullYear();
  
  const today = `${dia}/${mes}/${ano}`;

  const CustomCellRenderer = ({ value }) => (
    <div style={{ fontSize: '10px + 0.5vh'}}>{value}</div>
  );
  const tableColumns = [
    { field: 'node', headerName: 'Node', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',},
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, 
    headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',
    renderCell: (params) => <CustomCellRenderer value={params.value} />,
    },
    {
      field: "ops_owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
      field: "backup_owner",
      headerName: "Backup Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      },
    },
    {
      field: "nam",
      headerName: "Nam",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      },
    },
    { field: 'cpt_hour', headerName: 'CPT hour', flex: 1, editable: true,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'pending_shipments', headerName: 'Pending Shipments', flex: 1, headerClassName: 'customHeader', headerAlign: 'center', align: 'center', },
    {
      field: 'pendingInvoice',
      headerName: 'Pending Invoice',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const pendingInvoice = parseInt(params.row.pending_invoice);
        const pendingShipments = parseInt(params.row.pending_shipments);
        if (pendingShipments === 0) {
          return 'N/A'; // To handle division by zero
        }
        
        const percent =  (pendingInvoice) /(pendingShipments) 
        return ( (percent*100).toFixed(2) + '%' );
      },
      renderCell: (params) => (
        <Tooltip title={`Miss Invoice: ${params.row.pending_invoice} packages`}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <CustomCellRenderer value={params.value} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'processed',
      headerName: 'Processed',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const packageShipments = parseInt(params.row.packed_shipments);
        const manifestedShipments = parseInt(params.row.manifested_shipments);
        const pendingShipments = parseInt(params.row.pending_shipments);
        if (pendingShipments === 0) {
          return 'N/A'; // To handle division by zero
        }
        
        const percent =  (packageShipments + manifestedShipments) /(pendingShipments + packageShipments + manifestedShipments) 
        return ( (percent*100).toFixed(2) + '%' );
      },
      renderCell: (params) => (
        <Tooltip title={`Processed: ${params.row.packed_shipments + params.row.manifested_shipments} packages`}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <CustomCellRenderer value={params.value} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'time_since_last_update',
      headerName: 'Time Since Last Update',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const lastUpdated = new Date(params.row.last_updated);
        const currentTime = new Date();
    
        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - lastUpdated;
    
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(timeDifference / 3600000);
        const minutes = Math.floor((timeDifference % 3600000) / 60000);
        const seconds = Math.floor((timeDifference % 60000) / 1000);
    
        return `${hours}h:${minutes}m:${seconds}s`;
      },
    },
    { field: 'phone1', headerName: 'Phone 1', flex: 1,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'phone2', headerName: 'Phone 2', flex: 1,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},  
    { field: 'ranking', headerName: 'Ranking', flex: 1,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',}  
  ];
  const shipmentID = [
    { field: 'node', headerName: 'Node', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',},
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, 
    headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',
    renderCell: (params) => <CustomCellRenderer value={params.value} />,
    },
    {
      field: "ops_owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
      field: "backup_owner",
      headerName: "Backup Owner",
      headerClassName: 'customHeader',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      },
    },
    { field: 'cpt_hour', headerName: 'CPT hour', flex: 1, editable: true,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'ranking', headerName: 'Ranking', flex: 1, editable: true,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'pending_shipments', headerName: 'Pending Shipments', flex: 1, headerClassName: 'customHeader', headerAlign: 'center', align: 'center', },
    {
      field: 'pendingInvoice',
      headerName: 'Pending Invoice',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const pendingInvoice = parseInt(params.row.pending_invoice);
        const pendingShipments = parseInt(params.row.pending_shipments);
        if (pendingShipments === 0) {
          return 'N/A'; // To handle division by zero
        }
        
        const percent =  (pendingInvoice) /(pendingShipments) 
        return ( pendingInvoice + ' package(s) - ' + (percent*100).toFixed(2) + '%' );
      },
      renderCell: (params) => (
        <Tooltip title={`Miss Invoice: ${params.row.pending_invoice} packages`}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <CustomCellRenderer value={params.value} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'shipment_id_missing_invoice',
      headerName: 'Shipment ID (missing Invoice)',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const shipment_id_missing_invoice = params.value;
    
        const maxLength = 30;

        if (shipment_id_missing_invoice && shipment_id_missing_invoice.length > maxLength) {

          return (
            <Tooltip title={shipment_id_missing_invoice}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {shipment_id_missing_invoice.substring(0, maxLength) + '...'}
              </div>
            </Tooltip>
          );
        }
    
        return shipment_id_missing_invoice;
      }
    },
    {
      field: 'order_id_missing_invoice',
      headerName: 'Order ID (missing Invoice)',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const order_id_missing_invoice = params.value;
    
        const maxLength = 30;

        if (order_id_missing_invoice && order_id_missing_invoice.length > maxLength) {

          return (
            <Tooltip title={order_id_missing_invoice}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {order_id_missing_invoice.substring(0, maxLength) + '...'}
              </div>
            </Tooltip>
          );
        }
    
        return order_id_missing_invoice;
      }
    },
    {
      field: 'shipment_id_invoice_ok',
      headerName: 'Shipment ID invoice OK',
      flex: 1,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const shipment_id_invoice_ok = params.value;
    
        const maxLength = 30;

        if (shipment_id_invoice_ok && shipment_id_invoice_ok.length > maxLength) {

          return (
            <Tooltip title={shipment_id_invoice_ok}>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {shipment_id_invoice_ok.substring(0, maxLength) + '...'}
              </div>
            </Tooltip>
          );
        }
    
        return shipment_id_invoice_ok;
      }
    }
  ];

  const tableColumnsTop = [
    { field: 'node', headerName: 'Node', width: 50, 
    headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',
    renderCell: (params) => <CustomCellRenderer value={params.value} />,
    },
    { field: 'seller_name', headerName: 'Seller Name', width: 300, 
    headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center',
    renderCell: (params) => <CustomCellRenderer value={params.value} />,
    },
    {
      field: "ops_owner",
      headerName: "Ops Owner",
      headerClassName: 'customHeader',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
    },
    {
      field: "backup_owner",
      headerName: "Backup Owner",
      headerClassName: 'customHeader',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      },
    },
    {
      field: "nam",
      headerName: "Nam",
      headerClassName: 'customHeader',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
        return null; 
      },
    },
    //{ field: 'cpt_date', headerName: 'ExSD', flex: 1,  type: 'date', valueFormatter: params => new Date(params?.value).toLocaleDateString(), headerClassName: 'customHeader', headerAlign: 'center', align: 'center', },
    { field: 'cpt_hour', headerName: 'CPT hour', width: 80,  
    headerClassName: 'customHeader', headerAlign: 'center', align: 'center',
    renderCell: (params) => <CustomCellRenderer value={params.value} />,
    },
    {
      field: 'pending_shipments',
      headerName: 'Pending Shipments',
      width: 100,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },
    {
      field: 'pendingInv',
      headerName: 'Pending Invoice',
      width: 100,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const pendingInvoice = parseInt(params.row.pending_invoice);
        const pendingShipments = parseInt(params.row.pending_shipments);
        if (pendingShipments === 0) {
          return 'N/A'; // To handle division by zero
        }
        
        const percent =  (pendingInvoice) /(pendingShipments) 
        return ( (percent*100).toFixed(2) + '%' );
      },
      renderCell: (params) => (
        <Tooltip title={`Miss Invoice: ${params.row.pending_invoice} packages`}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <CustomCellRenderer value={params.value} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'processed',
      headerName: 'Processed',
      width: 100,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const packageShipments = parseInt(params.row.packed_shipments);
        const manifestedShipments = parseInt(params.row.manifested_shipments);
        const pendingShipments = parseInt(params.row.pending_shipments);
        if (pendingShipments === 0) {
          return 'N/A'; // To handle division by zero
        }
        
        const percent =  (packageShipments + manifestedShipments) /(pendingShipments + packageShipments + manifestedShipments) 
        return ( (percent*100).toFixed(2) + '%' );
      },
      renderCell: (params) => (
        <Tooltip title={`Processed: ${params.row.packed_shipments + params.row.manifested_shipments} packages`}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <CustomCellRenderer value={params.value} />
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'time_since_last_update',
      headerName: 'Time Since Last Update',
      width: 200,
      headerClassName: 'customHeader',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const lastUpdated = new Date(params.row.last_updated);
        const currentTime = new Date();
    
        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - lastUpdated;
    
        // Calculate hours, minutes, and seconds
        const days = Math.floor(timeDifference / 86400000); 
        const hours = Math.floor((timeDifference % 86400000) / 3600000); 
        const minutes = Math.floor((timeDifference % 3600000) / 60000);
        const seconds = Math.floor((timeDifference % 60000) / 1000);
        
        if (days > 0) {
          return `${days}d:${hours}h`;
        } else {
          return `${hours}h:${minutes}m:${seconds}s`;
        }
      },
    }, 
    { field: 'phone1', headerName: 'Phone 1', width: 150,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'phone2', headerName: 'Phone 2', width: 150,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',},
    { field: 'ranking', headerName: 'Ranking', width: 100,  headerClassName: 'customHeader', headerAlign: 'center', align: 'center',}
  ];
  const sortedData = useMemo(() => {
    let sortedItems = [...data]; 

    if (selectedOpsOwner !== 'All') {
      sortedItems = sortedItems.filter((item) => item.ops_owner === selectedOpsOwner);
    }
    sortedItems.sort((a, b) => {
      
      const cptHourComparison = a.cpt_hour - b.cpt_hour;
    
      if (cptHourComparison !== 0) {
        return cptHourComparison;
      }
    
      // If 'cpt_hour' is the same, compare 'pending_shipments' in descending order
      const unitsComparison = b.pending_shipments - a.pending_shipments;
    
      return unitsComparison;
    });
  
    return sortedItems;
  }, [data, selectedOpsOwner]);
  
  const filterOpsOwner = useMemo(() => {
    let sortedItems = [...data]; // Change from const to let

    // Add this filter to check the selectedOpsOwner
    if (selectedOpsOwner !== 'All') {
      sortedItems = sortedItems.filter((item) => item.ops_owner === selectedOpsOwner);
    }

    sortedItems.sort((a, b) => {
      // First, compare by 'cpt_hour' in ascending order
      const cptHourComparison = a.cpt_hour - b.cpt_hour;

      if (cptHourComparison !== 0) {
        return cptHourComparison;
      }

      // If 'cpt_hour' is the same, compare 'pending_shipments' in descending order
      const unitsComparison = b.pending_shipments - a.pending_shipments;

      return unitsComparison;
    });

    return sortedItems;
  }, [data, selectedOpsOwner]);


  const sortedDataAfterCpt = useMemo(() => {
    const currentHour = new Date().getHours();
  
    const filteredData = data.filter(item => parseInt(item.cpt_hour) <= currentHour);
    console.log('test before cpt ', filteredData);
    
    const sortedItems = [...filteredData].sort((a, b) => {
      const cptHourComparison = a.cpt_hour - b.cpt_hour;
      if (cptHourComparison !== 0) {
        return cptHourComparison;
      }
  
      const unitsComparison = b.pending_shipments - a.pending_shipments;
  
      return unitsComparison;
    });
  
    return sortedItems;
  }, [data]);
  
  
  const sortedDataWithPendingGreaterThan10 = useMemo(() => {
    return sortedData.filter((item) => item.pending_shipments > 10);
  }, [sortedData]);

  const sortedDataWithPendingGreaterThan10AndPercentage = useMemo(() => {
    return sortedData.filter((item) => {
      return item.pending_shipments > 10 && (item.pending_invoice / item.pending_shipments) > 0.5;
    });
  }, [sortedData]);
  
  const sortedDatShipments = useMemo(() => {
    return sortedData.filter((item) => item.pending_invoice > 0);
  }, [sortedData]);

  const maxUpdatedAt = useMemo(() => {
    if (sortedData.length === 0) return null;
  
    // Find the maximum updatedAt item
    const maxUpdatedAtItem = sortedData.reduce((prev, current) =>
      new Date(prev.updatedAt) > new Date(current.updatedAt) ? prev : current
    );
  
    // Get the date and time parts
    const updatedAtDate = new Date(maxUpdatedAtItem.updatedAt);
    const hours = updatedAtDate.getHours().toString().padStart(2, '0');
    const minutes = updatedAtDate.getMinutes().toString().padStart(2, '0');
    const seconds = updatedAtDate.getSeconds().toString().padStart(2, '0');
  
    // Format in HH:MM:SS
    return `${hours}:${minutes}:${seconds}`;
  }, [sortedData]);
  const handleRefreshData = async () => {
    try {
      setLoading(true);
      // Trigger your data fetching logic here
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  const tableContainerStyle = {
    height: '80vh', 
    overflow: 'auto', 
    display: 'flex',
    flexDirection: 'column',
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <div className='datatable' style={{ height: '100%', width: '100%', marginTop: '2vh' }}>
      <Typography
        sx={{
          marginTop: '1vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        SELLER FLEX - CPT MANAGEMENT
    </Typography>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', padding: '0 24px', marginTop: '2vh' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Top Backlog" {...a11yProps(0)} />
          <Tab label="General Backlog" {...a11yProps(1)} />
          <Tab label="Top Risk" {...a11yProps(2)} />
          <Tab label="Pending Shipment after CPT" {...a11yProps(3)} />
          <Tab label="IDs for missing Invoice (Integration)" {...a11yProps(4)} />
          <Tab label="Backlog 15 days" {...a11yProps(5)} />
        </Tabs>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5vh', }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefreshData}
            style={{
              fontSize: 'calc(6px + 0.5vh)', // Adjust the font size as needed
              padding: '4px 10px', // Adjust the padding to control the button size
            }}
          >
            Refresh 
          </Button>
            {maxUpdatedAt && (
              <Typography
                sx={{
                  fontFamily: 'Source Sans Pro',
                  marginLeft: 2,
                  fontSize: 'calc(6px + 0.5vh)',
                  fontWeight: 'bold',
                }}
              >
                Updated at {maxUpdatedAt}
              </Typography>
            )}

            <FormControl style={{ width: '100%', marginLeft: '5vh',  }}>
              <InputLabel>Ops Owner</InputLabel>
              <Select
                value={selectedOpsOwner}
                onChange={handleOpsOwnerChange}
              >
                <MenuItem value="All">All</MenuItem>
                {/* Map through ops owners and create MenuItems for each */}
                {opsOwners.map((opsOwner) => (
                  <MenuItem key={opsOwner} value={opsOwner}>
                    {opsOwner}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        </div>
        
      </Box>    
      <CustomTabPanel value={value} index={0}>
        <div className='datatable' style={{ height: '100%', width: '100%' }}>
          <div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    marginTop: 0,
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(7px + 0.5vh)',
                    fontWeight: 'lighter',
                  }}
                >
                  Sellers with Penging Shipment greater than 10 packages 
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', // Adjust the percentage as needed
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={sortedDataWithPendingGreaterThan10}
              columns={tableColumnsTop}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
          </div>
          </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className='datatable' style={{ height: '100%', width: '100%' }}>
          <div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    marginTop: 0,
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(7px + 0.5vh)',
                    fontWeight: 'lighter',
                  }}
                >
                  Table detailing Seller Flex's backlog
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', // Adjust the percentage as needed
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={filterOpsOwner}
              columns={tableColumns}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
          </div>
          </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div className='datatable' style={{ height: '100%', width: '100%' }}>
          <div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    marginTop: 0,
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(7px + 0.5vh)',
                    fontWeight: 'lighter',
                  }}
                >
                  Sellers with Penging Shipment greater than 10 packages and Missing Inovice greater than 50%
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', // Adjust the percentage as needed
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={sortedDataWithPendingGreaterThan10AndPercentage}
              columns={tableColumnsTop}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div className='datatable' style={{ height: '100%', width: '100%' }}>
          <div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    marginTop: 0,
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(7px + 0.5vh)',
                    fontWeight: 'lighter',
                  }}
                >
                  Sellers that didn't process the orders until the CPT yet 
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', // Adjust the percentage as needed
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={sortedDataAfterCpt}
              columns={tableColumnsTop}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <div className='datatable' style={{ height: '100%', width: '100%' }}>
          <div>
            <Grid container spacing={2} direction="row">
              <Grid item xs={3} md={3}>
                <Typography
                  sx={{
                    marginTop: 0,
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(7px + 0.5vh)',
                    fontWeight: 'lighter',
                  }}
                >
                  Shipment ID for missing Invoice 
                </Typography>
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                maxHeight: '80vh', // Adjust the percentage as needed
                overflow: 'auto', // Enable vertical scrolling if needed
              }}
              rows={sortedDatShipments}
              columns={shipmentID}
              components={{
                Toolbar: CustomToolbar,
              }}
              
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}   
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
          <Backlog username={username} userGroup={userGroup}          
          />
        </CustomTabPanel>
    </Box>
    </div>
  );
};


export default withAuthenticator(CptManagement);