import React, { useState, useEffect, useMemo } from 'react';
import { Avatar, Button, Grid, Select, MenuItem, Typography } from '@mui/material';
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { listSellerDataES } from "../../../graphql/queries";
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Snackbar, Alert, Chip } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';

const TopOffender = ({ data, loading: initialLoading }) => {
  const [mxsdData, setMxsdData] = useState([]);
  const [datasd, setDatasd] = useState([]);
  const [cancellationData, setCancellationData] = useState([]);
  const [lhBySellerData, setLhBySellerData] = useState([]);
  const [displayInterval, setDisplayInterval] = useState('day');
  const [shipMethodFilter, setShipMethodFilter] = useState('all');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSellerDataES, { limit: 20000, nextToken })
        );
        const fetchedItems = response.data.listSellerDataES.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSellerDataES.nextToken;
      } while (nextToken);
  
      setDatasd(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const mergedData = useMemo(() => {
    if (!data || data.length === 0 || !datasd || datasd.length === 0) return [];

    return data.map(item => {
      const matchingDatasd = datasd.find(d => d.id === item.mid);
      return { ...item, ...matchingDatasd };
    });
  }, [data, datasd]);

  const sortedData = useMemo(() => {
    if (!mergedData || mergedData.length === 0) return [];

    const filteredAndSortedData = mergedData
      .filter(item => 
        ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'].includes(item.miss_type) &&
        (item.classification !== 'FST' || (item.classification === 'FST' && item.units > 5))
      );

    return filteredAndSortedData.sort((a, b) => {
      const dateComparison = displayInterval === 'day' ?
        new Date(b.date) - new Date(a.date) :
        new Date(b.week) - new Date(a.week);

      if (dateComparison !== 0) {
        return dateComparison;
      }

      const ordersComparison = b.orders - a.orders;
      if (ordersComparison !== 0) {
        return ordersComparison;
      }

      const classificationComparison = (a.classification || '').localeCompare(b.classification || '');
      if (classificationComparison !== 0) {
        return classificationComparison;
      }

      const opsComparison = b.owner - a.owner;
      if (opsComparison !== 0) {
        return opsComparison;
      }

      return a.miss_type.localeCompare(b.miss_type);
    });
  }, [mergedData, displayInterval]);

  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      const groupedData = sortedData.reduce((acc, item) => {
        const key = `${item.date}_${item.miss_type}_${item.classification}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {});
  
      const top3Data = Object.values(groupedData)
        .map(items => items.slice(0, 3))
        .flat()
        .map(item => ({ ...item, displayClassification: item.classification }));
  
      // Remove duplicates based on mid and date
      const uniqueData = top3Data.reduce((acc, current) => {
        const x = acc.find(item => item.mid === current.mid && item.date === current.date);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
  
      // Sort the unique data by date (descending) and then by orders (descending)
      const sortedUniqueData = uniqueData.sort((a, b) => {
        const dateComparison = new Date(b.date) - new Date(a.date);
        if (dateComparison !== 0) return dateComparison;
        return b.orders - a.orders;
      });
      
  
      setMxsdData(sortedUniqueData.filter(item => item.miss_type === 'MXSD'));
      setCancellationData(sortedUniqueData.filter(item => item.miss_type === 'CANCELLATION'));
      setLhBySellerData(sortedUniqueData.filter(item => item.miss_type === 'LH_BY_SELLER'));
    }
  }, [sortedData]);

  const filteredData = useMemo(() => {
    const filterData = (dataSet) => {
      if (shipMethodFilter === 'all') {
        return dataSet;
      }
      return dataSet.filter(item => item.ship_method === shipMethodFilter);
    };

    return {
      mxsd: filterData(mxsdData),
      cancellation: filterData(cancellationData),
      lhBySeller: filterData(lhBySellerData),
    };
  }, [mxsdData, cancellationData, lhBySellerData, shipMethodFilter]);

  const tableColumns = [
    { field: 'mid', headerName: 'MCID', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    { field: 'seller_name', headerName: 'Seller Name', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    { 
      field: 'owner', 
      headerName: 'Ops Owner',
      flex: 1,
      headerClassName: 'customHeader boldHeader',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            </div>
          );
        }
        return null;
      },
    },
    { 
      field: 'date', 
      headerName: 'Date', 
      flex: 1, 
      valueFormatter: params => new Date(params?.value).toLocaleDateString(),
      headerClassName: 'customHeader boldHeader', 
      headerAlign: 'center', 
      align: 'center' 
    },
    { field: 'orders', headerName: 'Orders', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    { field: 'displayClassification', headerName: 'Classification', flex: 1, headerClassName: 'customHeader boldHeader', headerAlign: 'center', align: 'center' },
    
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Select
        value={shipMethodFilter}
        onChange={(e) => setShipMethodFilter(e.target.value)}
        sx={{ minWidth: 120 }}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Pickup">Pickup</MenuItem>
        <MenuItem value="Dropoff">Dropoff</MenuItem>
      </Select>
    </GridToolbarContainer>
  );

  return (
    <div className="datatable" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Select
        value={displayInterval}
        onChange={(e) => setDisplayInterval(e.target.value)}
        sx={{ margin: '10px' }}
      >
        <MenuItem value="day">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
      </Select>
      <Grid container spacing={8} direction="row">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Mxsd Top Offenders
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
          ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '30vh',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
              rows={filteredData.mxsd}
              columns={tableColumns}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15 } },
                sorting: {
                  sortModel: [{ field: 'date', sort: 'desc' }, { field: 'orders', sort: 'desc' }],
                },
              }}
            />
           

          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Cancellation Top Offenders
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
          ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
              rows={filteredData.cancellation}
              columns={tableColumns}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15 } },
                sorting: {
                  sortModel: [{ field: 'date', sort: 'desc' }, { field: 'orders', sort: 'desc' }],
                },
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            LH BY Seller Top Offenders
          </Typography>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
          ) : (
            <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto',
              }}
              rows={filteredData.lhBySeller}
              columns={tableColumns}
              components={{
                Toolbar: CustomToolbar,
              }}
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 15 } },
                sorting: {
                  sortModel: [{ field: 'date', sort: 'desc' }, { field: 'orders', sort: 'desc' }],
                },
              }}
            />   
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TopOffender;
