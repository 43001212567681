import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { Typography } from '@mui/material';

  const tableColumns = [
    {
      field: "week",
      headerName: "Week",
      type: 'number',
      headerClassName: 'customHeader',
      width: '110',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
    {
      field: "orders",
      headerName: "Orders",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    },   
    {
      field: "general_lsr_units",
      headerName: "LSR",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "general_lsr_percent",
      headerName: "LSR (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 9.20;
        
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                color: isPositive ? 'red' : 'green',
                fontWeight: 'bold',
              }}
            >
              {formattedValue}
            </div>
            
          </div>
        );
      },
    },
    /*
    {
      field: "general_lsr_wow_percent",
      headerName: "LSR WoW(%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
            const value = params.value * 100;
            if (isNaN(value)) {
                  return null; // Hide the cell if the value is NaN
            }
            
            const formattedValue = `${Math.abs(value).toFixed(2)}%`;
            const sign = value > 0 ? '+' : '-';
            
            return (
                  <div>
                  {sign}{formattedValue}
                  </div>
            );
            },
    },    */
    {
      field: "lsr_re_f_units",
      headerName: "LSR RE",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "lsr_re_f_percent",
      headerName: "LSR RE (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 5.50;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 5.50%     ST: 3.75%
            </div>
          </div>
          
        );
      },
    }, /*
    {
      field: "lsr_re_f_wow_percent",
      headerName: "LSR RE WoW(%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
            const value = params.value * 100;
            if (isNaN(value)) {
              return null; // Hide the cell if the value is NaN
            }
          
            const formattedValue = `${Math.abs(value).toFixed(2)}%`;
            const sign = value > 0 ? '+' : '-';
          
            return (
              <div>
                {sign}{formattedValue}
              </div>
            );
          },
    }, */
    {
      field: "mexsd_re_f_units",
      headerName: "MXSD RE",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "mexsd_re_f_percent",
      headerName: "MXSD RE(%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 2.68;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 2.70%     ST: 1.83%
            </div>
          </div>
        );
      },
    }, /*
    {
      field: "mexsd_re_f_wow_percent",
      headerName: "MXSD WoW(%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
            const value = params.value * 100;
            if (isNaN(value)) {
              return null; // Hide the cell if the value is NaN
            }
          
            const formattedValue = `${Math.abs(value).toFixed(2)}%`;
            const sign = value > 0 ? '+' : '-';
          
            return (
              <div>
                {sign}{formattedValue}
              </div>
            );
          },
    }, */
    {
      field: "late_handover_units",
      headerName: "LH",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "late_handover_percent",
      headerName: "LH (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 2.82;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 2.80%     ST: 1.96%
            </div>
          </div>
        );
      },
    },
    {
      field: "cr_units",
      headerName: "CR",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    }, 
    {
      field: "cr_percent",
      headerName: "SCR (%)",
      headerClassName: 'customHeader',
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 1.40;
        
        return (
          <div
            style={{
              color: isPositive ? 'red' : 'green',
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
            <div style={{ fontSize: '0.7em', color: 'gray' }}>
              G: 5.50%     ST: 3.75%
            </div>
          </div>
        );
      },
    },    
    {
      field: "dropoff_issur_re",
      headerName: "Drop off issues",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "dropoff_issur_re_percent",
      headerName: "Drop off issues (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 8.50;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    },
    {
      field: "pickup_issue_re",
      headerName: "Pickup Issues",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => params.value.toLocaleString(),
    },
    {
      field: "pickup_issue_re_percent",
      headerName: "Pickup Issues (%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value * 100;
        const formattedValue = `${value.toFixed(2)}%`;
        const isPositive = value >= 8.50;
        
        return (
          <div
            style={{
              fontWeight: 'bold',
            }}
          >
            {formattedValue}
          </div>
        );
      },
    }, /*
    {
      field: "late_handover_wow_percent",
      headerName: "LH WoW(%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
            const value = params.value * 100;
            if (isNaN(value)) {
              return null; // Hide the cell if the value is NaN
            }
          
            const formattedValue = `${Math.abs(value).toFixed(2)}%`;
            const sign = value > 0 ? '+' : '-';
          
            return (
              <div>
                {sign}{formattedValue}
              </div>
            );
          },
    }, /*/
 /*
    {
      field: "cr_wow_percent",
      headerName: "CR WoW(%)",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
            const value = params.value * 100;
            if (isNaN(value)) {
              return null; // Hide the cell if the value is NaN
            }
        
            const formattedValue = `${Math.abs(value).toFixed(2)}%`;
            const isPositive = value > -1;
        
            return (
              <div>
                {isPositive && '+'}{formattedValue}
              </div>
            );
      },
    }, /*/

    ];


export default tableColumns;