import './App.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Oval } from 'react-loader-spinner';
import CircularProgress from "@mui/material/CircularProgress";
function App() {
  const dashboardRef = useRef(null);
  const [dashboardId, setDashboardId] = useState('32c13e8d-8b73-47fb-aa39-be2318c308c6');
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [websocket, setWebSocket] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const timeout = setTimeout(() => {
      fetch("https://vdh7lb9q5k.execute-api.us-west-2.amazonaws.com/embed/anonymous-embed")
        .then((response) => response.json())
        .then((response) => {
          console.log("API Response: ", response);
          setDashboardUrl(response.EmbedUrl);
        })
        .catch((error) => console.error("Error fetching the embed URL:", error));
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const createContext = async () => {
    try {
      const context = await createEmbeddingContext();
      setEmbeddingContext(context);
      console.log("Embedding context created:", context);
    } catch (error) {
      console.error("Error creating embedding context:", error);
    }
  };

  useEffect(() => {
    if (dashboardUrl) {
      createContext();
    }
  }, [dashboardUrl]);

  useEffect(() => {
    if (embeddingContext) {
      embed();
    }
  }, [embeddingContext]);

const embed = async () => {
  if (!dashboardRef.current) {
    console.error("Dashboard container is not available.");
    return;
  }

  const options = {
    url: dashboardUrl,
    container: dashboardRef.current,
    height: `${window.innerHeight}px`,
    width: `${window.innerWidth * 0.9}px`, // Adjust the width to 90% of the window width
    onLoad: () => {
      console.log("Dashboard loaded successfully.");
      if (embeddedDashboard) {
        // Navigate to the desired dashboard ID here
        embeddedDashboard.navigateToDashboard('e0596d08-600b-4993-b917-5d6025a1aa20', {});
      }
      setLoading(false);
    },
    onWebSocketEvent: (event) => {
      if (event.type === 'onclose') {
        console.error(`WebSocket closed with code: ${event.code}, reason: ${event.reason}`);
        if (event.code === 1006) {
          console.warn("Attempting to reconnect WebSocket...");
          reconnectWebSocket();
        }
      }
    }
  };

  try {
    const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
    setEmbeddedDashboard(newEmbeddedDashboard);
    setWebSocket(newEmbeddedDashboard.websocket);
    console.log("Dashboard embedded:", newEmbeddedDashboard);
  } catch (error) {
    console.error("Error embedding the dashboard:", error);
    setLoading(false);
  }
};

  const reconnectWebSocket = () => {
    if (websocket) {
      websocket.close();
      setWebSocket(null);
    }
    if (embeddedDashboard) {
      embeddedDashboard.refresh().then(() => {
        console.log("WebSocket reconnected.");
      }).catch((error) => {
        console.error("Error reconnecting WebSocket:", error);
      });
    }
  };

  useEffect(() => {
    if (embeddedDashboard) {
      embeddedDashboard.navigateToDashboard("e0596d08-600b-4993-b917-5d6025a1aa20", {});
    }
  }, [dashboardId]);

  const changeDashboard = (e) => {
    const dashboardId = e.target.value;
    setDashboardId(dashboardId);
  };

return (
    <>
      <main>
        <div ref={dashboardRef} className="dashboard-container"/>
      </main>
    </>
);
}

export default App;
