
import {Avatar } from '@mui/material';


  const tableColumns = [
    {
      field: "seller_id",
      headerName: "Seller ID",
      headerAlign: 'center',
        width: 150,
  
      },
      {
        field: "seller_name",
        headerName: "Seller Name",
        headerAlign: 'center',
        width: 200,
        

      },
      {
        field: "rank",
        headerName: "rank",
        headerAlign: 'center',
        width: 100,

      },
      {
        field: "ops_support",
        headerName: "Ops Support",
        headerAlign: 'center',
        width: 150,
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
                <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
              </div>
            );
          }
          return null; 
        },
      },
      {
        field: "on_time",
        headerName: "On Time",
        headerAlign: 'center',
        width: 100,
      },
      {
        field: "second_attempt",
        headerName: "2nd Attempt",
        headerAlign: 'center',
        width: 100,
      },
      {
        field: "no_show",
        headerName: "No Show",
        headerAlign: 'center',
        width: 100,
      },
      {
        field: "cancelled",
        headerName: "Cancelled",
        headerAlign: 'center',
        width: 100,
      },
      {
        field: "seller_miss",
        headerName: "Seller Miss",
        headerAlign: 'center',
        width: 100,
      },
      {
        field: "seller_miss_perc",
        headerName: "Seller Miss (%)",
        headerAlign: 'center',
        width: 100,
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "metrics_impact_perc",
        headerName: "Metrics Impact (%)",
        headerAlign: 'center',
        width: 100,
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "business_relevance",
        headerName: "Business Relevance",
        headerAlign: 'center',
        width: 100,
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "classification",
        headerName: "Classification",
        headerAlign: 'center',
        width: 150,
      },
    ];


export default tableColumns;