import { listVFOrderStatuses } from '../../../graphql/queries';
import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation  } from 'aws-amplify';

import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

import { DataGrid, GridToolbarContainer, GridToolbarExport,  GridToolbarQuickFilter, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Typography, Button,Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWarehouseCode, setSelectedWarehouseCode] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date(); // Get today's date in YYYY-MM-DD format
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate()+1); // Add 1 day to the current date
      today.setDate(today.getDate()-1);
      
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const startOfTomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());

      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFOrderStatuses, {
            limit,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: today, // Filter for dates greater than yesterday's date
                lt: tomorrow, // Filter for dates less than tomorrow's date
              },
            },
          })
        );
        const fetchedItems = response.data.listVFOrderStatuses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFOrderStatuses.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const sortedData = useMemo(() => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);

    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= yesterday
    );

    const groupedData = filteredItems.reduce((acc, item) => {
      const key = `${item.warehouse_code}-${item.expected_ship_date}-${item.status}-${item.ship_method}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          warehouse_code: item.warehouse_code,
          expected_ship_date: item.expected_ship_date,
          status: item.status,
          ship_method: item.ship_method,
          totalQuantity: 0,
        };
      }
      acc[key].totalQuantity += item.quantity;
      return acc;
    }, {});

    const sortedItems = Object.values(groupedData).sort((a, b) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.totalQuantity - a.totalQuantity;
    });

    const totalQuantity = sortedItems.reduce((sum, item) => sum + item.totalQuantity, 0);

    return sortedItems;
  }, [data]);

  const sortedData_table = useMemo(() => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);

    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= yesterday
    );

    const groupedData_2 = filteredItems.reduce((acc, item) => {
      const key = `${item.expected_ship_date}-${item.warehouse_code}-${item.ship_method}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          expected_ship_date: item.expected_ship_date,
          warehouse_code: item.warehouse_code,
          ship_method: item.ship_method,
          confirmed: 0,
          new: 0,
          packed: 0,
          'invoice_submitted': 0,
          processed: 0,
          totalOrders: 0,
          totalOrders_processed: 0,
        };
      }

      if (item.status === 'INVOICE_SUBMITTED') {
        acc[key]['invoice_submitted'] += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'PACKED') {
        acc[key].packed += item.quantity;
        acc[key].totalOrders_processed += item.quantity;
      } else if (item.status === 'CONFIRMED') {
        acc[key].confirmed += item.quantity;
      } else if (item.status === 'NEW') {
        acc[key].new += item.quantity;
      }

      acc[key].totalOrders += item.quantity;

      return acc;
    }, {});

    const sortedItems2 = Object.values(groupedData_2).sort((a, b) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.totalOrders_processed - a.totalOrders_processed;
    });

    sortedItems2.forEach(item => {
      item.processed = item.totalOrders > 0 ? item.totalOrders_processed / item.totalOrders : 0;
    });

    return sortedItems2.filter(item => selectedWarehouseCode === '' || item.warehouse_code === selectedWarehouseCode);
  }, [data, selectedWarehouseCode]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <div>
        <GridToolbarQuickFilter debounceMs={500} />
        <GridToolbarFilterButton />
      </div>
      <div>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="warehouse-code-select-label">Warehouse</InputLabel>
          <Select
            labelId="warehouse-code-select-label"
            id="warehouse-code-select"
            value={selectedWarehouseCode}
            onChange={(event) => setSelectedWarehouseCode(event.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {Array.from(new Set(sortedData_table.map(item => item.warehouse_code))).map((code) => (
              <MenuItem key={code} value={code}>{code}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );

  const processedOrders = sortedData.filter(item => item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED').reduce((sum, item) => sum + item.totalQuantity, 0);
  const notProcessedOrders = sortedData.filter(item => item.status === 'CONFIRMED' || item.status === 'NEW').reduce((sum, item) => sum + item.totalQuantity, 0);
  const totalOrders = processedOrders + notProcessedOrders;

  const lineChartData = useMemo(() => {
    const filteredItems = data.filter(item =>
      item.ship_method === 'AMZL_BR_NEXT'
    );

    const lineChartData = {
      'AMZL_BR_NEXT': [],
    };

    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.packed_datetime);
      lineChartData['AMZL_BR_NEXT'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });

    lineChartData['AMZL_BR_NEXT'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);

    const summedData = lineChartData['AMZL_BR_NEXT'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);

    return [
      {
        name: 'AMZL_BR_NEXT',
        data: summedData,
      },
    ];
  }, [data]);

  const lineChartData_log = useMemo(() => {
    const filteredItems = data.filter(item =>
      item.ship_method === 'LOGBR_STD'
    );

    const lineChartData = {
      'LOGBR_STD': [],
    };

    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.packed_datetime);
      lineChartData['LOGBR_STD'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });

    lineChartData['LOGBR_STD'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);

    const summedData = lineChartData['LOGBR_STD'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);

    return [
      {
        name: 'LOGBR_STD',
        data: summedData,
      },
    ];
  }, [data]);

  const lineChartData_tex = useMemo(() => {
    const filteredItems = data.filter(item =>
      item.ship_method === 'TEXBR_EXPRESS'
    );

    const lineChartData = {
      'TEXBR_EXPRESS': [],
    };

    filteredItems.forEach((item) => {
      const orderShippedDate = new Date(item.packed_datetime);
      lineChartData['TEXBR_EXPRESS'].push({
        order_shipped_hour: orderShippedDate.getHours(),
        quantity: item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED' ? item.quantity : 0,
      });
    });

    lineChartData['TEXBR_EXPRESS'].sort((a, b) => a.order_shipped_hour - b.order_shipped_hour);

    const summedData = lineChartData['TEXBR_EXPRESS'].reduce((acc, curr) => {
      const existingItem = acc.find(
        (item) => item.order_shipped_hour === curr.order_shipped_hour
      );
      if (existingItem) {
        existingItem.quantity += curr.quantity;
      } else {
        acc.push({
          order_shipped_hour: curr.order_shipped_hour,
          quantity: curr.quantity,
        });
      }
      return acc;
    }, []);

    return [
      {
        name: 'TEXBR_EXPRESS',
        data: summedData,
      },
    ];
  }, [data]);

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}></Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={8} xs={12} md={8}>
        <Typography
          sx={{
            marginTop: '2vh',
            align: 'center',
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          CPT Order Status (Ship Method Level)
          {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
        </Typography>
        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            minHeight: '40vh',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
          rows={sortedData_table}
          columns={[
            { field: 'warehouse_code', headerName: 'Node', flex: 1 },
            { field: 'ship_method', headerName: 'Ship Method', flex: 1 },
            { field: 'expected_ship_date', headerName: 'Expected Ship Date', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'totalOrders', headerName: 'Total Expected', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'new', headerName: 'New', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'confirmed', headerName: 'Confirmed', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'packed', headerName: 'Packed', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'invoice_submitted', headerName: 'Invoice Submitted', flex: 1, align: 'center', headerAlign: 'center' },
            { field: 'processed', headerName: 'Processed %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
          ]}
          components={{
            Toolbar: CustomToolbar,
          }}
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { pageSize: 100 },
            sortModel: [{ field: 'deviation', sort: 'asc' }],
          }}
        />
      </Grid>

      <Typography
        sx={{
          marginTop: '2vh',
          align: 'center',
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >
        Processed orders per hour (ship method level)
        {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12} md={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            AMZL BR NEXT
            {loading && <Loader size="large" />}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={lineChartData} background="#FFFFFF">
              <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {lineChartData.map((line, index) => (
                <Line
                  key={`line-${index}`}
                  dataKey="quantity"
                  data={line.data}
                  name={line.name}
                  stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
                  strokeWidth={3}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item lg={6} xs={12} md={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            LOG BR STD
            {loading && <Loader size="large" />}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={lineChartData_log}>
              <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {lineChartData_log.map((line, index) => (
                <Line
                  key={`line-${index}`}
                  dataKey="quantity"
                  data={line.data}
                  name={line.name}
                  stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
                  strokeWidth={3}
                  connectNulls
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item lg={6} xs={12} md={6}>
          <Typography
            sx={{
              marginTop: '2vh',
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            TEX BR EXPRESS
            {loading && <Loader size="large" />}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={lineChartData_tex} background="#FFFFFF">
              <XAxis dataKey="order_shipped_hour" type="number" domain={[0, 24]} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {lineChartData_tex.map((line, index) => (
                <Line
                  key={`line-${index}`}
                  dataKey="quantity"
                  data={line.data}
                  name={line.name}
                  stroke={`#${((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')}`}
                  strokeWidth={3}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </div>
  );
}

export default PostList;