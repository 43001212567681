import { useState, useEffect, useMemo } from "react";
import { listSEStrackSRS } from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Grid, Avatar } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import awsconfig from '../../../aws-exports';

import * as XLSX from 'xlsx';

Amplify.configure(awsconfig);

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const startDate = new Date('2024-06-01');
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSEStrackSRS, {
            limit: 20000,
            nextToken,
            filter: {
              send_datetime: {
                gt: startDate.toISOString().slice(0, 10),
             },
              program: {
                eq: 'ES',
              },
            },
          })
        );
        const fetchedItems = response.data.listSEStrackSRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSEStrackSRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData();
  };
  const distinctSellersWithSRSClick = useMemo(() => {
    const sellersWithSRSClick = new Set(data.filter(item => item.open_datetime).map(item => item.seller_id));
    return Array.from(sellersWithSRSClick);
  }, [data]);

  const minSendDatetimeBySellerID = useMemo(() => {
    const minValues = {};
    data.forEach((item) => {
      if (!minValues[item.seller_id] || item.send_datetime < minValues[item.seller_id]) {
        minValues[item.seller_id] = item.send_datetime;
      }
    });
    return minValues;
  }, [data]);

  const table = data.map(item => ({
    seller_id: item.seller_id,
    units: item.units,
    miss_type: item.miss_type,
    miss_date: item.miss_date,
    seller_name: item.seller_name,
    owner: item.owner,
    recipient: item.recipient,
    min_send_datetime: minSendDatetimeBySellerID[item.seller_id],
  }));

  const uniqueTable = Array.from(new Set(table.map(JSON.stringify))).map(JSON.parse);

  const distinctSellersWithoutSRSClick = useMemo(() => {
    const sellersWithoutSRSClick = {};

    uniqueTable.forEach(item => {
      if (!distinctSellersWithSRSClick.includes(item.seller_id)) {
        const key = `${item.seller_id}`;
        if (!sellersWithoutSRSClick[key]) {
          sellersWithoutSRSClick[key] = {
            id: key,
            seller_name: item.seller_name,
            seller_id: item.seller_id,
            owner: item.owner,
            recipient: item.recipient,
            min_send_datetime: item.min_send_datetime,
            units_2: 0,
          };
        }
        sellersWithoutSRSClick[key].units_2 += item.units;
      }
    });

    return Object.values(sellersWithoutSRSClick).sort((a, b) => b.units_2 - a.units_2);
  }, [uniqueTable, distinctSellersWithSRSClick]);

  const MissColumns = [
    {
      field: "seller_id",
      headerName: "Merchant ID",
      width: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
    
    {
        field: "seller_name",
        headerName: "Seller Name",
        width: 400,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "owner",
      headerName: "Ops Owner",
      width: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
  },

    {
      field: "units_2",
      headerName: "Units",
      width: 100,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },

  {
    field: "min_send_datetime",
    headerName: "First Send Datetime",
    width: 250,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
},
    {
        field: "recipient",
        headerName: "Recipient",
        width: 500,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },

    

  ];

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  const [downloadUrl, setDownloadUrl] = useState(null);
  const handleExportClick = () => {
    const excelData = distinctSellersWithoutSRSClick.map((row) => row);
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelContent = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadUrl = URL.createObjectURL(excelContent);
    setDownloadUrl(downloadUrl);
  };

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid item lg={6} xs={6} md={6}>
      </Grid>
      <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleRefresh}>
          Refresh Data
        </Button>
      </Grid>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGrid
          sx={{
            marginTop: '2vh',
            marginLeft: 2,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '0px',
            borderRadius: '30px',
            maxHeight: '100%',
          }}
          rows={distinctSellersWithoutSRSClick}
          columns={MissColumns}
          getRowId={(row) => row.id}
          editMode="row"
          checkboxSelection
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      )}

      {downloadUrl ? (
                  <a href={downloadUrl} download="offenders_es.xlsx" style={{ marginRight: "16px" }}>
                    <Button variant="contained" color="primary">
                      Export to XLSX
                    </Button>
                  </a>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleExportClick}>
                    Export to XLSX
                  </Button>
                )}
    </div>
  );
};

export default withAuthenticator(Summary);