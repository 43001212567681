/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://h7axt6mydnfshbxhsne7rb4mwa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:08b135d0-946f-4f04-b3bf-62b7ca1dcbdf",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_cuSVtTOAA",
    "aws_user_pools_web_client_id": "2vrvd9jen5rdsr4ejc0jfpjspm",
    "oauth": {
        "domain": "midway-auth-production-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://osc.ops.amazon.dev/",
        "redirectSignOut": "https://w.amazon.com/bin/view/FBA_Onsite_Brazil/Operations/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "user-upload-file-bucket16304-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
