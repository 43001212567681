import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import AWS from 'aws-sdk';
import { Amplify } from "aws-amplify";
import awsmobile from "../../../aws-exports";

Amplify.configure(awsmobile);

const CloudWatchDashboard = () => {
  const [dashboard, setDashboard] = useState(null);
  const [dashboardWidgets, setDashboardWidgets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDashboard();
  }, []);

  const fetchDashboard = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const credentials = await Auth.currentCredentials();
      const cloudWatch = new AWS.CloudWatch({
        credentials: Auth.essentialCredentials(credentials),
        region: awsmobile.aws_project_region
      });

      const params = {
        DashboardName: 'BR-EF-email-comm-central' // Specify the dashboard name here
      };

      const dashboardData = await cloudWatch.getDashboard(params).promise();
      setDashboard(dashboardData);

      // Parse the dashboard body
      const dashboardBody = JSON.parse(dashboardData.DashboardBody);
      setDashboardWidgets(dashboardBody.widgets || []);
    } catch (error) {
      console.error('Error fetching CloudWatch dashboard:', error);
      setError('Failed to fetch CloudWatch dashboard. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderWidget = (widget) => {
    switch (widget.type) {
      case 'metric':
        return renderMetricWidget(widget);
      case 'text':
        return renderTextWidget(widget);
      // Add cases for other widget types as needed
      default:
        return <div>Unsupported widget type: {widget.type}</div>;
    }
  };

  const renderMetricWidget = (widget) => {
    return (
      <div className="metric-widget">
        <h3>{widget.properties.title}</h3>
        <p>Metrics: {widget.properties.metrics.map(m => m.join(':')).join(', ')}</p>
        {/* You would typically use a charting library here to visualize the metric data */}
      </div>
    );
  };

  const renderTextWidget = (widget) => {
    return (
      <div className="text-widget">
        <div dangerouslySetInnerHTML={{ __html: widget.properties.markdown }} />
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading CloudWatch dashboard...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="cloudwatch-dashboard">
      <h2>CloudWatch Dashboard: {dashboard?.DashboardName}</h2>
      <p>Last modified: {new Date(dashboard?.LastModified).toLocaleString()}</p>
      <div className="dashboard-grid">
        {dashboardWidgets.map((widget, index) => (
          <div key={index} className="dashboard-widget">
            {renderWidget(widget)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CloudWatchDashboard;
