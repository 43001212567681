import React, { useState, useEffect, useCallback, useMemo } from "react";
import { listMQBandQBRS } from "../../../graphql/queries";
import { listMisses } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid, Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { Loader } from '@aws-amplify/ui-react';
import { Android } from "@mui/icons-material";


Amplify.configure(awsconfig);

const Summary = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [datar, setDatar] = useState([]);
  const [loading, setLoading] = useState(true);
  
  

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();


  async function fetchData() {
    try {
      setLoading(true);
      const response = await API.graphql(graphqlOperation(listMQBandQBRS, { limit: 100 }));
      const items = response.data.listMQBandQBRS.items;
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchDataReasons = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-10-01');
      const today = new Date();
      const tenDaysAgo = new Date(today);
      tenDaysAgo.setDate(today.getDate() - 15);
      
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
 
      do {
        const response = await API.graphql(
          graphqlOperation(listMisses, {
            limit: 20000,
            nextToken,
            filter: {
                  week: {
                    eq: currentWeekNumber,
                },

            },
          })
        );
        const fetchedItems = response.data.listMisses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listMisses.nextToken;
      } while (nextToken);
  
      setDatar(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  


  const sortedData1 = useMemo(() => {
    const sortedItems = [...data].sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
    });
  
    return sortedItems;
  }, [data]);

  const filterData = (data, missTypeFilter) => {
    return data.filter(item => 
      item.miss_type === missTypeFilter
    );
  };

  const aggregatedDataByWeekAndReason = useMemo(() => {
    const aggregated = {};
    const totalByWeekAndMissType = {};
  
    // Aggregate units by week_miss_type_reason_subreason
    for (const item of datar) {
      const week = item.week || 'Unknown';
      const miss_type = item.miss_type || 'NULL';
      const reason = item.reason || 'Unknown';
      const subreason = item.subreason || 'Unknown';
      const units = Number(item.units) || 0;
  
      const totalId = `${week}_${miss_type}_${reason}_Total`;
      const subReasonId = `${week}_${miss_type}_${reason}_${subreason}`;
  
      // Aggregate total for reason
      if (!aggregated[totalId]) {
        aggregated[totalId] = {
          id: totalId,
          week,
          reason,
          subreason: 'Total',
          miss_type,
          units: 0,
          isTotal: true,
        };
      }
      aggregated[totalId].units += units;
  
      // Aggregate by subreason
      if (!aggregated[subReasonId]) {
        aggregated[subReasonId] = {
          id: subReasonId,
          week,
          reason,
          subreason,
          miss_type,
          units: 0,
          isTotal: false,
        };
      }
      aggregated[subReasonId].units += units;
  
      // Calculate total by week and miss_type
      if (!totalByWeekAndMissType[week]) {
        totalByWeekAndMissType[week] = {};
      }
      if (!totalByWeekAndMissType[week][miss_type]) {
        totalByWeekAndMissType[week][miss_type] = 0;
      }
      totalByWeekAndMissType[week][miss_type] += units;
    }
  
    // Convert the object to an array and calculate percentage
    const result = Object.values(aggregated).map(item => {
      const totalForWeekAndMissType = totalByWeekAndMissType[item.week][item.miss_type];
      const totalForReason = aggregated[`${item.week}_${item.miss_type}_${item.reason}_Total`].units;

      return {
        ...item,
        units_sum: totalForWeekAndMissType,
        percentage: totalForWeekAndMissType > 0 
          ? Number(((item.units / totalForWeekAndMissType) * 100).toFixed(2))
          : 0
      };
      
    });
  
    // Sort by reason, then isTotal (Total rows first), then by units in descending order
    result.sort((a, b) => {
      if (a.reason !== b.reason) {
        return a.reason.localeCompare(b.reason);
      }
      if (a.isTotal !== b.isTotal) {
        return a.isTotal ? -1 : 1;
      }
      return b.units - a.units;
    });
  
    // Add the order column
    result.forEach((item, index) => {
      item.order = index + 1;
    });
  
    console.log('Dados de entrada:', datar);
    console.log('Dados agregados:', result);
  
    return result;
  }, [datar]);
  



  useEffect(() => {
      try {
        fetchData();
        fetchDataReasons();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

  const handleRefreshData = async () => {
    try {
      setLoading(true);
      await fetchData();
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

const [filteredDataLH, setFilteredDataLH] = useState([]);
const [filteredDataMXSD, setFilteredDataMXSD] = useState([]);
const [filteredDataCancellation, setFilteredDataCancellation] = useState([]);

useEffect(() => {
  setFilteredDataLH(filterData(aggregatedDataByWeekAndReason, 'LH_BY_SELLER'));
  setFilteredDataMXSD(filterData(aggregatedDataByWeekAndReason, 'MXSD'));
  setFilteredDataCancellation(filterData(aggregatedDataByWeekAndReason, 'CANCELLATION'));
}, [aggregatedDataByWeekAndReason]);


  const reasons = [
    { field: 'week', headerName: 'Week',width: 10 },
    { field: 'reason', type: 'number', headerName: 'Reason', width: 250, align: 'left', headerAlign: 'left' },
    { field: 'subreason', type: 'number', headerName: 'Subreason', width: 300, align: 'left', headerAlign: 'left' },
    { field: 'units', type: 'number', headerName: 'Units', align: 'left', headerAlign: 'left', width: 10 },
    { field: 'percentage', type: 'number', headerName: '%', align: 'left', headerAlign: 'left', width: 10,
      valueFormatter: (params) => {
        const value = params.value; // Multiply by 100 to convert to percentage
        const formattedValue = `${value.toFixed(2)}%`; // Format the value with two decimal places and append the percentage sign
        return formattedValue;
      },

    }
  ];
  const groupDataByReason = (data) => {
    return data.reduce((acc, curr) => {
      if (!acc[curr.reason]) {
        acc[curr.reason] = [];
      }
      acc[curr.reason].push(curr);
      return acc;
    }, {});
  };

  const FilteredDataGrid = ({ data, title, currentWeekNumber }) => {
    const filteredData = useMemo(() => {
      return data.filter(item => item.week.toString() === currentWeekNumber.toString());
    }, [data, currentWeekNumber]);
  
    const groupedAndSortedData = useMemo(() => {
      const grouped = groupDataByReason(filteredData);
      
      // Calcular o total de units para cada reason
      const reasonTotals = Object.entries(grouped).reduce((acc, [reason, group]) => {
        acc[reason] = group.reduce((sum, item) => sum + (item.units || 0), 0);
        return acc;
      }, {});
  
      // Ordenar as reasons pelo total de units
      const sortedReasons = Object.entries(reasonTotals)
        .sort(([, a], [, b]) => b - a)
        .map(([reason]) => reason);
  
      // Criar um novo objeto com as reasons ordenadas
      const sortedGrouped = {};
      sortedReasons.forEach(reason => {
        sortedGrouped[reason] = grouped[reason].sort((a, b) => {
          if (a.subreason === 'Total') return -1;
          if (b.subreason === 'Total') return 1;
          return b.units - a.units;
        });
      });
  
      return sortedGrouped;
    }, [filteredData]);
  
    return (
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(15px + 0.5vh)',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <div style={{
          marginTop: '1vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          height: '40vh',
          overflow: 'auto',
        }}>
          <div style={{ display: 'flex', fontWeight: 'bold', borderBottom: '1px solid black' }}>
            {reasons.map((column) => (
              <div key={column.field} style={{
                flex: column.flex || 'auto',
                width: column.width,
                padding: '8px',
                textAlign: column.align || 'left'
              }}>
                {column.headerName}
              </div>
            ))}
          </div>
          {Object.entries(groupedAndSortedData).map(([reason, group]) => (
            <React.Fragment key={reason}>
              {group.map((row, index) => (
                <CustomRow
                  key={row.id}
                  row={row}
                  isLastInGroup={index === group.length - 1}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </Box>
    );
  };

  const CustomRow = React.memo(({ row, reasonGroup, isLastInGroup }) => {
    return (
      <div style={{
        borderBottom: isLastInGroup ? '2px solid black' : 'none',
        backgroundColor: row.isTotal ? '#f0f0f0' : 'white'
      }}>
        <div style={{ display: 'flex' }}>
          {reasons.map((column) => (
            <div key={column.field} style={{
              flex: column.flex || 'auto',
              width: column.width,
              padding: '8px',
              textAlign: column.align || 'left',
              fontWeight: row.isTotal ? 'bold' : 'normal'
            }}>
              {row[column.field]}
            </div>
          ))}
        </div>
      </div>
    );
  });

  return (
    <div className='datatable' style={{ height: '100%', width: '100%', padding: '20px' }}>
      <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between" marginBottom={4}>
        <Grid item lg={3} xs={3} md={3}>
          {/* You can add a title or other content here if needed */}
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            onClick={handleRefreshData}
          >
            Refresh Data
          </Button>
        </Grid>
      </Grid>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    textAlign: 'center', // Adicionando alinhamento central
                    fontWeight: 'bold'
                }}
                >
                MxSD
              </Typography>
      <Box sx={{ border: '1px solid white', borderRadius: '1px', overflow: 'hidden', backgroundColor: 'white' }}>
        <FilteredDataGrid 
          data={filteredDataMXSD} 
 
          currentWeekNumber={currentWeekNumber}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '2px',
          backgroundColor: '#E0E0E0',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      />
      <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    textAlign: 'center', // Adicionando alinhamento central
                    fontWeight: 'bold'
                }}
                >
                Cancellation
              </Typography>
      <Box sx={{ border: '1px solid white', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'white' }}>
        <FilteredDataGrid 
          data={filteredDataCancellation} 
 
          currentWeekNumber={currentWeekNumber}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '2px',
          backgroundColor: '#E0E0E0',
          marginTop: '2vh',
          marginBottom: '2vh',
        }}
      /> 
      <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    textAlign: 'center', // Adicionando alinhamento central
                    fontWeight: 'bold'
                }}
                >
                FDPS Seller Miss
              </Typography>
      <Box sx={{ border: '1px solid white', borderRadius: '4px', overflow: 'hidden', backgroundColor: 'white' }}>
        <FilteredDataGrid 
          data={filteredDataLH} 
  
          currentWeekNumber={currentWeekNumber}
        />
      </Box>
    </Box>
  </div>
);
    
    
};

export default (Summary);